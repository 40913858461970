import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DetailContainer = styled.div`
  background: white;
  padding: 32px;
  border-radius: 12px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  color: #1d1d1f;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #86868b;
  
  &:hover {
    color: #1d1d1f;
  }
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h4`
  margin: 0 0 16px 0;
  color: #1d1d1f;
  font-size: 16px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const Field = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-size: 14px;
  color: #86868b;
  margin-bottom: 4px;
`;

const Value = styled.div`
  font-size: 16px;
  color: #1d1d1f;
`;

const MedicalRecordDetail = ({ record, onClose }) => {
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecordDetail = async () => {
      try {
        const response = await makeApiRequest(`/api/medical/records/${record.id}`);
        if (response.data.success) {
          setDetailData(response.data.data);
        } else {
          throw new Error(response.data.message || '상세 정보를 불러오는데 실패했습니다.');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRecordDetail();
  }, [record.id]);

  if (loading) return <Modal><DetailContainer>로딩 중...</DetailContainer></Modal>;
  if (error) return <Modal><DetailContainer>에러: {error}</DetailContainer></Modal>;
  if (!detailData) return null;

  return (
    <Modal>
      <DetailContainer>
        <Header>
          <Title>진료 기록 상세</Title>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </Header>

        <Section>
          <SectionTitle>환자 정보</SectionTitle>
          <Grid>
            <Field>
              <Label>환자명</Label>
              <Value>{detailData.patientName}</Value>
            </Field>
            <Field>
              <Label>환자 ID</Label>
              <Value>{detailData.patientId}</Value>
            </Field>
            <Field>
              <Label>국적</Label>
              <Value>{detailData.nationality}</Value>
            </Field>
            <Field>
              <Label>사용 언어</Label>
              <Value>{detailData.language}</Value>
            </Field>
          </Grid>
        </Section>

        <Section>
          <SectionTitle>진료 정보</SectionTitle>
          <Grid>
            <Field>
              <Label>진료일</Label>
              <Value>{detailData.date}</Value>
            </Field>
            <Field>
              <Label>진료과</Label>
              <Value>{detailData.department}</Value>
            </Field>
            <Field>
              <Label>담당의</Label>
              <Value>{detailData.doctor}</Value>
            </Field>
            <Field>
              <Label>진단명</Label>
              <Value>{detailData.diagnosis}</Value>
            </Field>
          </Grid>
        </Section>

        <Section>
          <SectionTitle>진료 내용</SectionTitle>
          <Field>
            <Label>증상</Label>
            <Value>High blood pressure, headache, dizziness</Value>
          </Field>
          <Field>
            <Label>처방</Label>
            <Value>
              - Amlodipine 5mg qd<br />
              - Aspirin 100mg qd<br />
              - Blood pressure monitoring
            </Value>
          </Field>
          <Field>
            <Label>특이사항</Label>
            <Value>Need interpreter for detailed communication</Value>
          </Field>
        </Section>
      </DetailContainer>
    </Modal>
  );
};

export default MedicalRecordDetail; 