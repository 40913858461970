import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import OnboardingModal from '../components/onboarding/OnboardingModal';
import LoginModal from '../components/auth/LoginModal';
import LogoutButton from '../components/auth/LogoutButton';
import { checkAuth } from '../services/authService';

// 대시보드 컴포넌트 import
import StatCard from './components/overview/StatCard';
import ActivityFeed from './components/overview/ActivityFeed';
import ChartWidget from './components/overview/ChartWidget';
import PatientList from './components/patients/PatientList';
import AppointmentList from './components/appointments/AppointmentList';
import MedicalRecordList from './components/medical/MedicalRecordList';
import Calendar from './components/schedule/Calendar';
import Charts from './components/statistics/Charts';
import Reports from './components/statistics/Reports';
import GeneralSettings from './components/settings/GeneralSettings';
import UserSettings from './components/settings/UserSettings';
import NotificationSettings from './components/settings/NotificationSettings';
import ServerDashboard from './components/server/ServerDashboard';
import TodayAppointments from './components/overview/TodayAppointments';
import InterpreterStatus from './components/overview/InterpreterStatus';
import SystemAlerts from './components/overview/SystemAlerts';
import ServerStats from './components/server/ServerStats';
import NetworkStatus from './components/server/NetworkStatus';
import DashboardStructure from './components/docs/DashboardStructure';
import DoctorList from './components/doctors/DoctorList';
import DoctorForm from './components/doctors/DoctorForm';
import TreatmentList from './components/treatments/TreatmentList';
import ConsultationList from './components/consultations/ConsultationList';
import DepartmentList from './components/departments/DepartmentList';

// 헤더 높이를 고려한 컨테이너
const DashboardContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 44px); // 헤더 높이를 뺀 전체 높이
  margin-top: 44px; // 헤더 높이만큼 마진
`;

const Sidebar = styled.div`
  width: 240px;
  background: #f5f5f7;
  padding: 20px;
  border-right: 1px solid #e1e1e1;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 40px 24px;
  background: #f5f5f7;
  overflow-y: auto;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  margin-bottom: 8px;
`;

const MenuButton = styled.button`
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  background: ${props => props.active ? '#007AFF' : 'transparent'};
  color: ${props => props.active ? 'white' : '#1d1d1f'};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.active ? '#007AFF' : '#e1e1e1'};
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
`;

const StatsCardContainer = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const StatTitle = styled.div`
  color: #86868b;
  font-size: 14px;
  margin-bottom: 8px;
`;

const StatValue = styled.div`
  color: #1d1d1f;
  font-size: 24px;
  font-weight: 600;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
`;

const DashboardCard = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: #1d1d1f;
  margin-bottom: 16px;
`;

const ActivityFeedContainer = styled(DashboardCard)``;

const ChartTitle = styled.h2`
  font-size: 18px;
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActivityItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e1e1e1;
  
  &:last-child {
    border-bottom: none;
  }
`;

const ActivityText = styled.div`
  color: #1d1d1f;
`;
const ActivityTime = styled.div`
  color: #86868b;
  font-size: 14px;
`;

const LogoutButtonWrapper = styled.div`
  button {
    position: static; // position: absolute 제거
    top: auto;
    right: auto;
    margin: 0;
  }
`;

const QuickStats = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 24px;
`;

const QuickStatCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  
  .label {
    color: #86868b;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .value {
    font-size: 24px;
    font-weight: 600;
    color: ${props => props.status === 'warning' ? '#ff9500' : '#1d1d1f'};
  }
  
  .change {
    font-size: 14px;
    color: ${props => props.trend > 0 ? '#34c759' : '#ff3b30'};
    margin-top: 4px;
  }
`;

const MainDashboard = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
`;

const DashboardSection = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const ServerOverview = styled(DashboardSection)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const PatientDashboard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [activeMenu, setActiveMenu] = useState('overview');
  
  const menuItems = [
    { id: 'overview', label: '대시보드', icon: '📊' },
    { id: 'patients', label: '환자 관리', icon: '👥' },
    { id: 'appointments', label: '예약 관리', icon: '📅' },
    { id: 'treatments', label: '진료 관리', icon: '🏥' },
    { id: 'consultations', label: '1:1 상담', icon: '💬' },
    { id: 'medical-records', label: '진료 기록', icon: '📋' },
    { id: 'schedule', label: '일정 관리', icon: '⏰' },
    { id: 'doctors', label: '의사 관리', icon: '👨‍⚕️' },
    { id: 'departments', label: '진료실 관리', icon: '🏥' },
    { id: 'statistics', label: '통계', icon: '📈' },
    { id: 'server', label: '서버 모니터링', icon: '🖥️' },
    { id: 'settings', label: '설정', icon: '⚙️' },
    { id: 'docs', label: '문서', icon: '📚' }
  ];

  // 서버 상태 데이터
  const [servers, setServers] = useState({
    web: {
      name: 'Web Server (AlmaLinux)',
      status: 'running',
      uptime: '45 days',
      cpu: 32,
      memory: 68,
      disk: 45,
      network: {
        in: '150 Mbps',
        out: '80 Mbps'
      }
    },
    llama: {
      name: 'LLaMA Server (Windows)',
      status: 'running',
      uptime: '30 days',
      cpu: 75,
      memory: 82,
      disk: 60,
      network: {
        in: '20 Mbps',
        out: '35 Mbps'
      }
    }
  });

  // 활동 데이터
  const [activities] = useState([
    { text: '새로운 환자 예약이 등록되었습니다.', time: '5분 전' },
    { text: '통역 서비스가 요청되었습니다.', time: '15분 전' },
    { text: '진료 기록이 업데이트되었습니다.', time: '30분 전' },
    { text: '새로운 문의가 접수되었습니다.', time: '1시간 전' },
    { text: '예약 일정이 변경되었습니다.', time: '2시간 전' }
  ]);

  useEffect(() => {
    // 온보딩 체크 로직만 유지
    const hasVisited = localStorage.getItem('hasVisitedBefore');
    if (!hasVisited) {
      setShowOnboarding(true);
      localStorage.setItem('hasVisitedBefore', 'true');
    }
  }, []);

  // 서버 상태 모니터링
  useEffect(() => {
    const monitorServers = setInterval(() => {
      // 서버 상태 업데이트 로직
      // API 호출 등
    }, 30000);

    return () => clearInterval(monitorServers);
  }, []);

  const handleLoginSuccess = (userData) => {
    setIsAuthenticated(true);
    // 필요한 경우 추가적인 사용자 데이터 처리
  };

  const handleCloseOnboarding = () => {
    setShowOnboarding(false);
  };

  const quickStats = [
    {
      label: '전체 외국인 환자',
      value: '1,234',
      change: '+12%',
      trend: 1
    },
    {
      label: '금일 예약',
      value: '28',
      change: '+3건',
      trend: 1
    },
    {
      label: '통역 대기',
      value: '15',
      status: 'warning'
    },
    {
      label: '서버 상태',
      value: '정상',
      subtext: '2대 모두 운영중'
    }
  ];

  const renderQuickStats = () => (
    <QuickStats>
      {quickStats.map((stat, index) => (
        <QuickStatCard key={index} status={stat.status} trend={stat.trend}>
          <div className="label">{stat.label}</div>
          <div className="value">{stat.value}</div>
          {stat.change && <div className="change">{stat.change}</div>}
          {stat.subtext && <div className="subtext">{stat.subtext}</div>}
        </QuickStatCard>
      ))}
    </QuickStats>
  );

  const networkStatusMockData = {
    status: {
      internalNetwork: 'connected',
      externalNetwork: 'connected',
      latency: '2ms',
      bandwidth: '1Gbps'
    }
  };

  const renderOverview = () => (
    <>
      <PageHeader>
        <PageTitle>통합 대시보드</PageTitle>
        <LogoutButtonWrapper>
          <LogoutButton />
        </LogoutButtonWrapper>
      </PageHeader>

      {renderQuickStats()}

      <MainDashboard>
        <div>
          <DashboardSection>
            <ChartTitle>환자 현황</ChartTitle>
            <ChartWidget />
          </DashboardSection>
          
          <ServerOverview>
            <DashboardSection>
              <ChartTitle>Web Server</ChartTitle>
              <ServerStats server={servers.web} />
            </DashboardSection>
            <DashboardSection>
              <ChartTitle>LLaMA Server</ChartTitle>
              <ServerStats server={servers.llama} />
            </DashboardSection>
          </ServerOverview>
        </div>

        <div>
          <DashboardSection>
            <ChartTitle>실시간 활동</ChartTitle>
            <ActivityFeed activities={activities} />
          </DashboardSection>
          
          <DashboardSection>
            <ChartTitle>시스템 알림</ChartTitle>
            <SystemAlerts />
          </DashboardSection>
        </div>
      </MainDashboard>

      <DashboardGrid>
        <DashboardSection>
          <ChartTitle>오늘의 예약</ChartTitle>
          <TodayAppointments />
        </DashboardSection>
        
        <DashboardSection>
          <ChartTitle>통역 서비스 현황</ChartTitle>
          <InterpreterStatus />
        </DashboardSection>
        
        <DashboardSection>
          <ChartTitle>네트워크 상태</ChartTitle>
          <NetworkStatus {...networkStatusMockData} />
        </DashboardSection>
      </DashboardGrid>
    </>
  );

  const renderContent = () => {
    switch (activeMenu) {
      case 'overview':
        return renderOverview();
      case 'doctors':
        return <DoctorList />;
      case 'patients':
        return <PatientList />;
      case 'appointments':
        return <AppointmentList />;
      case 'medical-records':
        return <MedicalRecordList />;
      case 'schedule':
        return <Calendar />;
      case 'statistics':
        return (
          <>
            <Charts />
            <Reports />
          </>
        );
      case 'server':
        return <ServerDashboard />;
      case 'settings':
        return (
          <>
            <GeneralSettings />
            <UserSettings />
            <NotificationSettings />
          </>
        );
      case 'docs':
        return <DashboardStructure />;
      case 'treatments':
        return <TreatmentList />;
      case 'consultations':
        return <ConsultationList />;
      case 'departments':
        return <DepartmentList />;
      default:
        return renderOverview();
    }
  };

  return (
    <div>
      {!isAuthenticated ? (
        showLoginModal && (
          <LoginModal 
            onClose={() => setShowLoginModal(false)}
            onLoginSuccess={handleLoginSuccess}
          />
        )
      ) : (
        <DashboardContainer>
          <Sidebar>
            <MenuList>
              {menuItems.map(item => (
                <MenuItem key={item.id}>
                  <MenuButton
                    active={activeMenu === item.id}
                    onClick={() => setActiveMenu(item.id)}
                  >
                    <span style={{ marginRight: '8px' }}>{item.icon}</span>
                    {item.label}
                  </MenuButton>
                </MenuItem>
              ))}
            </MenuList>
          </Sidebar>
          <MainContent>
            {renderContent()}
            {showOnboarding && <OnboardingModal onClose={handleCloseOnboarding} />}
          </MainContent>
        </DashboardContainer>
      )}
    </div>
  );
};

export default PatientDashboard; 