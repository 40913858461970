import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiActivity, FiCpu, FiHardDrive, FiWifi } from 'react-icons/fi';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { makeApiRequest } from '../../../utils/apiUtils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Container = styled.div`
  padding: 24px;
  max-width: 1600px;
  margin: 0 auto;
  background: #f5f5f7;
  min-height: 100vh;
`;

const DashboardHeader = styled.div`
  margin-bottom: 24px;
`;

const DashboardTitle = styled.h2`
  font-size: 24px;
  color: #1d1d1f;
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;

  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 24px;
    background: #007AFF;
    border-radius: 2px;
  }
`;

const Description = styled.p`
  color: #86868b;
  margin: 0;
  font-size: 14px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

const Card = styled.div`
  background: white;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  transition: transform 0.2s, box-shadow 0.2s;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }

  &.system-metrics {
    grid-column: span 12;

    @media (max-width: 1400px) {
      grid-column: span 8;
    }

    @media (max-width: 1024px) {
      grid-column: span 4;
    }

    @media (max-width: 768px) {
      grid-column: span 1;
    }
  }

  &.server-stats {
    grid-column: span 4;

    @media (max-width: 1024px) {
      grid-column: span 2;
    }

    @media (max-width: 768px) {
      grid-column: span 1;
    }
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  
  &::before {
    content: '';
    display: block;
    width: 3px;
    height: 16px;
    background: #007AFF;
    border-radius: 2px;
  }
`;

const CardTitle = styled.h3`
  font-size: 16px;
  color: #1d1d1f;
  margin: 0;
  font-weight: 600;
`;

const MetricValue = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: #1d1d1f;
  margin-bottom: 8px;
`;

const MetricLabel = styled.div`
  font-size: 14px;
  color: #86868b;
`;

const ServerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InfoLabel = styled.div`
  font-size: 14px;
  color: #86868b;
`;

const InfoValue = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  background: linear-gradient(180deg, #1a1b1e 0%, #2d2f34 100%);
  border-radius: 20px;
  color: #ffffff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const ChartSection = styled.div`
  background: white;
  padding: 20px;
  border-radius: 12px;
  height: 300px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 12px;
  margin-top: 16px;
`;

const CoreGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 8px;
  margin-top: 16px;
`;

const CoreItem = styled.div`
  background: ${props => {
    if (props.load >= 90) return 'rgba(255,59,48,0.2)';
    if (props.load >= 70) return 'rgba(255,204,0,0.2)';
    return 'rgba(52,199,89,0.2)';
  }};
  padding: 12px;
  border-radius: 8px;
  text-align: center;

  .core-number {
    font-size: 12px;
    color: #86868b;
    margin-bottom: 4px;
  }

  .core-load {
    font-size: 18px;
    font-weight: 600;
    color: ${props => {
      if (props.load >= 90) return '#ff3b30';
      if (props.load >= 70) return '#ffcc00';
      return '#34c759';
    }};
  }
`;

const MetricCardContainer = styled.div`
  background: ${props => props.alert ? 'rgba(255,59,48,0.15)' : 'rgba(255,255,255,0.07)'};
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 12px;
  border: 1px solid ${props => props.alert ? 'rgba(255,59,48,0.3)' : 'rgba(255,255,255,0.1)'};

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    font-size: 14px;
    color: #1d1d1f;
    margin-bottom: 8px;
  }

  .value {
    font-size: 24px;
    font-weight: 600;
    color: ${props => props.alert ? '#ff3b30' : '#1d1d1f'};
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  .unit {
    font-size: 14px;
    color: #86868b;
  }
`;

const MetricGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
`;

const DetailSection = styled.div`
  background: rgba(255,255,255,0.05);
  padding: 20px;
  border-radius: 12px;
  margin-top: 16px;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

const Tab = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background: ${props => props.active ? '#007AFF' : 'rgba(255,255,255,0.1)'};
  color: ${props => props.active ? 'white' : '#86868b'};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.active ? '#007AFF' : 'rgba(255,255,255,0.2)'};
  }
`;

const DetailCard = styled.div`
  background: rgba(255,255,255,0.07);
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 16px;

  h4 {
    color: #ffffff;
    margin: 0 0 16px 0;
    font-size: 18px;
  }
`;

const ErrorMessage = styled.div`
  padding: 16px;
  background: #ff3b30;
  color: white;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const ServerDashboard = () => {
  const [metrics, setMetrics] = useState(null);
  const [systemInfo, setSystemInfo] = useState(null);
  const [networkStatus, setNetworkStatus] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [error, setError] = useState(null);
  const [historicalData, setHistoricalData] = useState({
    labels: [],
    datasets: []
  });

  const getMetricValue = (name) => {
    if (!metrics?.current) return 0;
    const metric = metrics.current.find(m => m.name === name);
    if (!metric) return 0;
    
    const value = typeof metric.value === 'string' ? 
      parseFloat(metric.value) : metric.value;
    
    return Number.isFinite(value) ? value : 0;
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: { color: '#ffffff' }
      },
      title: {
        display: true,
        text: '시스템 리소스 사용량 추이',
        color: '#ffffff',
        font: { size: 16 }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        grid: { color: 'rgba(255,255,255,0.1)' },
        ticks: { color: '#ffffff' }
      },
      x: {
        grid: { color: 'rgba(255,255,255,0.1)' },
        ticks: { color: '#ffffff' }
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    maintainAspectRatio: false
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [metricsRes, systemRes, networkRes] = await Promise.all([
          makeApiRequest('/api/system/metrics'),
          makeApiRequest('/api/system/info'),
          makeApiRequest('/api/network/status')
        ]);

        const currentMetrics = [
          {
            name: 'cpu_usage',
            value: systemRes?.data?.data?.cpu?.load?.currentLoad || 0,
            unit: '%'
          },
          {
            name: 'memory_usage',
            value: ((systemRes?.data?.data?.memory?.used / systemRes?.data?.data?.memory?.total) * 100) || 0,
            unit: '%'
          },
          {
            name: 'free_memory',
            value: (systemRes?.data?.data?.memory?.free / (1024 * 1024 * 1024)) || 0,
            unit: 'GB'
          },
          {
            name: 'total_memory',
            value: (systemRes?.data?.data?.memory?.total / (1024 * 1024 * 1024)) || 0,
            unit: 'GB'
          },
          {
            name: 'disk_usage',
            value: getMetricValue('disk_usage'),
            unit: '%'
          },
          {
            name: 'disk_free',
            value: getMetricValue('disk_free'),
            unit: 'GB'
          },
          {
            name: 'disk_total',
            value: getMetricValue('disk_total'),
            unit: 'GB'
          },
          {
            name: 'network_rx',
            value: (networkRes?.data?.data?.stats?.rx_sec / (1024 * 1024)) || 0,
            unit: 'MB/s'
          },
          {
            name: 'network_tx',
            value: (networkRes?.data?.data?.stats?.tx_sec / (1024 * 1024)) || 0,
            unit: 'MB/s'
          }
        ];

        setMetrics({ current: currentMetrics });
        setSystemInfo(systemRes?.data?.data);
        setNetworkStatus(networkRes?.data?.data);

        if (metricsRes?.data?.data?.history) {
          updateHistoricalData(metricsRes.data.data.history);
        }

        setError(null);
      } catch (error) {
        console.error('데이터 로딩 실패:', error);
        setError('시스템 정보를 불러오는데 실패했습니다.');
      }
    };

    fetchAllData();
    const interval = setInterval(fetchAllData, 5000);
    return () => clearInterval(interval);
  }, []);

  const updateHistoricalData = (history) => {
    if (!Array.isArray(history) || history.length === 0) return;

    const timeLabels = [...new Set(history.map(m => 
      new Date(m.recorded_at).toLocaleTimeString('ko-KR', {
        hour: '2-digit',
        minute: '2-digit'
      })
    ))].slice(-10);

    const cpuData = timeLabels.map(time => {
      const metric = history.find(m => 
        m.name === 'cpu_usage' && 
        new Date(m.recorded_at).toLocaleTimeString('ko-KR', {
          hour: '2-digit',
          minute: '2-digit'
        }) === time
      );
      return metric ? parseFloat(metric.value) : null;
    });

    const memoryData = timeLabels.map(time => {
      const metric = history.find(m => 
        m.name === 'memory_usage' && 
        new Date(m.recorded_at).toLocaleTimeString('ko-KR', {
          hour: '2-digit',
          minute: '2-digit'
        }) === time
      );
      return metric ? parseFloat(metric.value) : null;
    });

    setHistoricalData({
      labels: timeLabels,
      datasets: [
        {
          label: 'CPU 사용량',
          data: cpuData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.1)',
          fill: true
        },
        {
          label: '메모리 사용량',
          data: memoryData,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.1)',
          fill: true
        }
      ]
    });
  };

  const calculateTotalDiskSize = (disks) => {
    if (!Array.isArray(disks)) return 0;
    return disks.reduce((total, disk) => {
      const size = disk.size ? parseFloat(disk.size) : 0;
      return total + (size / (1024 * 1024 * 1024));
    }, 0);
  };

  const bytesToGB = (bytes) => {
    if (!bytes) return 0;
    return parseFloat((bytes / (1024 * 1024 * 1024)).toFixed(2));
  };

  const calculateDiskUsage = (disks) => {
    if (!Array.isArray(disks) || disks.length === 0) return 0;
    
    const totalSize = disks.reduce((total, disk) => total + (disk.size || 0), 0);
    const totalUsed = disks.reduce((total, disk) => total + (disk.used || 0), 0);
    
    if (totalSize === 0) return 0;
    return ((totalUsed / totalSize) * 100).toFixed(1);
  };

  const calculateDiskFree = (disks) => {
    if (!Array.isArray(disks) || disks.length === 0) return 0;
    
    const totalFree = disks.reduce((total, disk) => {
      const size = disk.size || 0;
      const used = disk.used || 0;
      return total + (size - used);
    }, 0);
    
    return (totalFree / (1024 * 1024 * 1024)).toFixed(1);
  };

  const renderOverviewTab = () => (
    <>
      <MetricGrid>
        <MetricCard
          icon={<FiCpu />}
          title="CPU"
          metrics={[
            {
              label: '전체 사용률',
              value: systemInfo?.cpu?.load?.currentLoad || 0,
              unit: '%',
              alert: (systemInfo?.cpu?.load?.currentLoad || 0) > 80
            },
            {
              label: '물리적 코어',
              value: systemInfo?.cpu?.physicalCores || 0,
              unit: '개'
            },
            {
              label: '논리적 코어',
              value: systemInfo?.cpu?.cores || 0,
              unit: '개'
            },
            {
              label: 'CPU 온도',
              value: systemInfo?.cpu?.temperature?.main || 0,
              unit: '°C',
              alert: (systemInfo?.cpu?.temperature?.main || 0) > 80
            }
          ]}
        />

        <MetricCard
          icon={<FiHardDrive />}
          title="메모리"
          metrics={[
            {
              label: '사용률',
              value: ((systemInfo?.memory?.used / systemInfo?.memory?.total) * 100) || 0,
              unit: '%',
              alert: ((systemInfo?.memory?.used / systemInfo?.memory?.total) * 100) > 90
            },
            {
              label: '여유 메모리',
              value: (systemInfo?.memory?.free / (1024 * 1024 * 1024)) || 0,
              unit: 'GB'
            },
            {
              label: '전체 메모리',
              value: (systemInfo?.memory?.total / (1024 * 1024 * 1024)) || 0,
              unit: 'GB'
            }
          ]}
        />

        <MetricCard
          icon={<FiHardDrive />}
          title="디스크"
          metrics={[
            {
              label: '사용률',
              value: getMetricValue('disk_usage'),
              unit: '%',
              alert: getMetricValue('disk_usage') > 90
            },
            {
              label: '여유 공간',
              value: getMetricValue('disk_free'),
              unit: 'GB'
            },
            {
              label: '전체 용량',
              value: getMetricValue('disk_total'),
              unit: 'GB'
            }
          ]}
        />

        <MetricCard
          icon={<FiWifi />}
          title="네트워크"
          metrics={[
            {
              label: '수신',
              value: getMetricValue('network_rx'),
              unit: 'MB/s',
              alert: getMetricValue('network_rx') > 100
            },
            {
              label: '송신',
              value: getMetricValue('network_tx'),
              unit: 'MB/s',
              alert: getMetricValue('network_tx') > 100
            }
          ]}
        />
      </MetricGrid>

      <ChartSection>
        <Line
          data={historicalData}
          options={chartOptions}
        />
      </ChartSection>
    </>
  );

  const renderCPUDetails = () => {
    if (!systemInfo?.cpu) return null;

    return (
      <DetailCard>
        <h4>CPU 상세 정보</h4>
        <InfoGrid>
          <InfoItem>
            <InfoLabel>모델</InfoLabel>
            <InfoValue>{systemInfo.cpu.brand}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>제조사</InfoLabel>
            <InfoValue>{systemInfo.cpu.manufacturer}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>물리적 코어</InfoLabel>
            <InfoValue>{systemInfo.cpu.physicalCores}개</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>논리적 코어</InfoLabel>
            <InfoValue>{systemInfo.cpu.cores}개</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>기본 속도</InfoLabel>
            <InfoValue>{systemInfo.cpu.speed.current} GHz</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>최대 속도</InfoLabel>
            <InfoValue>{systemInfo.cpu.speed.max} GHz</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>CPU 온도</InfoLabel>
            <InfoValue>{systemInfo.cpu.temperature.main}°C</InfoValue>
          </InfoItem>
        </InfoGrid>

        <h4>코어별 사용률</h4>
        <CoreGrid>
          {systemInfo.cpu.load.cpus.map((core, index) => (
            <CoreItem key={index} load={core.load}>
              <div className="core-number">Core {index + 1}</div>
              <div className="core-load">{core.load.toFixed(1)}%</div>
            </CoreItem>
          ))}
        </CoreGrid>
      </DetailCard>
    );
  };

  const renderMemoryDetails = () => {
    if (!systemInfo?.memory) return null;

    const totalGB = (systemInfo.memory.total / (1024 * 1024 * 1024)).toFixed(2);
    const usedGB = (systemInfo.memory.used / (1024 * 1024 * 1024)).toFixed(2);
    const freeGB = (systemInfo.memory.free / (1024 * 1024 * 1024)).toFixed(2);

    return (
      <DetailCard>
        <h4>메모리 상세 정보</h4>
        <InfoGrid>
          <InfoItem>
            <InfoLabel>전체 용량</InfoLabel>
            <InfoValue>{totalGB} GB</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>사용 중</InfoLabel>
            <InfoValue>{usedGB} GB</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>여유 공간</InfoLabel>
            <InfoValue>{freeGB} GB</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>스왑 사용률</InfoLabel>
            <InfoValue>
              {((systemInfo.memory.swap.used / systemInfo.memory.swap.total) * 100).toFixed(1)}%
            </InfoValue>
          </InfoItem>
        </InfoGrid>
      </DetailCard>
    );
  };

  const renderDiskDetails = () => {
    if (!systemInfo?.disk) return null;

    return (
      <DetailCard>
        <h4>디스크 상세 정보</h4>
        <InfoGrid>
          <InfoItem>
            <InfoLabel>전체 용량</InfoLabel>
            <InfoValue>
              {(systemInfo.disk[0]?.size / (1024 * 1024 * 1024)).toFixed(2)} GB
            </InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>전체 사용률</InfoLabel>
            <InfoValue>
              {systemInfo.disk[0]?.usedPercentage || 0}%
            </InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>전체 여유 공간</InfoLabel>
            <InfoValue>
              {(systemInfo.disk[0]?.free / (1024 * 1024 * 1024)).toFixed(1)} GB
            </InfoValue>
          </InfoItem>

          <InfoItem style={{ gridColumn: '1 / -1', borderBottom: '1px solid #eee', margin: '10px 0' }} />

          {systemInfo.disk.slice(1).map((disk, index) => (
            <React.Fragment key={index}>
              <InfoItem style={{ gridColumn: '1 / -1' }}>
                <InfoLabel style={{ fontSize: '1.1em', fontWeight: 'bold', color: '#1d1d1f' }}>
                  {disk.device || `디스크 ${index + 1}`} ({disk.type || 'Unknown'})
                </InfoLabel>
              </InfoItem>
              <InfoItem>
                <InfoLabel>용량</InfoLabel>
                <InfoValue>
                  {((disk.size || 0) / (1024 * 1024 * 1024)).toFixed(1)} GB
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>사용률</InfoLabel>
                <InfoValue>
                  {disk.usedPercentage || '0'}%
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>여유 공간</InfoLabel>
                <InfoValue>
                  {((disk.free || 0) / (1024 * 1024 * 1024)).toFixed(1)} GB
                </InfoValue>
              </InfoItem>
            </React.Fragment>
          ))}
        </InfoGrid>
      </DetailCard>
    );
  };

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <Container>
      <DashboardHeader>
        <DashboardTitle>
          <FiActivity />
          서버 모니터링 대시보드
        </DashboardTitle>
        <Description>
          실시간 시스템 리소스 사용량과 서버 상태를 모니터링합니다.
        </Description>
      </DashboardHeader>

      <TabContainer>
        <Tab active={activeTab === 'overview'} onClick={() => setActiveTab('overview')}>
          개요
        </Tab>
        <Tab active={activeTab === 'detail'} onClick={() => setActiveTab('detail')}>
          상세 정보
        </Tab>
      </TabContainer>

      {activeTab === 'overview' ? (
        renderOverviewTab()
      ) : (
        <DetailSection>
          {renderCPUDetails()}
          {renderMemoryDetails()}
          {renderDiskDetails()}
        </DetailSection>
      )}
    </Container>
  );
};

const MetricCard = ({ icon, title, metrics }) => {
  const validateMetric = (metric) => ({
    ...metric,
    value: metric.value ?? 0
  });

  return (
    <Card>
      <CardHeader>
        {icon}
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      {metrics.map((metric, index) => (
        <MetricItem key={index} {...validateMetric(metric)} />
      ))}
    </Card>
  );
};

const MetricItem = ({ label, value, unit, alert }) => {
  const formatValue = (val) => {
    if (typeof val !== 'number') return '0.00';
    return val.toFixed(2);
  };

  return (
    <MetricCardContainer alert={alert}>
      <div className="label">{label}</div>
      <div className="value">
        {formatValue(value)}
        <span className="unit">{unit}</span>
      </div>
    </MetricCardContainer>
  );
};

export default ServerDashboard; 