import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import DoctorForm from './DoctorForm';
import { toast } from 'react-toastify';

const Container = styled.div`
  padding: 24px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1d1d1f;
  margin: 0;
`;

const Button = styled.button`
  padding: 12px 24px;
  background: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background: #0056b3;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #e1e1e1;
  color: #86868b;
  font-weight: 600;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e1e1e1;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const ActionButton = styled.button`
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  border: none;
  
  ${props => props.danger ? `
    background: #ff3b30;
    color: white;
    
    &:hover {
      background: #dc3545;
    }
  ` : `
    background: #007AFF;
    color: white;
    
    &:hover {
      background: #0056b3;
    }
  `}
`;

const DoctorList = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    loadDoctors();
  }, []);

  const loadDoctors = async () => {
    try {
      setLoading(true);
      const response = await makeApiRequest('/api/doctors');
      console.log('의사 목록 응답:', response);

      if (response.data.success) {
        setDoctors(response.data.data);
      } else {
        console.error('의사 목록 응답 실패:', response.data);
        setDoctors([]);
        toast.error('의사 목록을 불러오는데 실패했습니다.');
      }
    } catch (error) {
      console.error('의사 목록 로딩 실패:', error);
      setDoctors([]);
      toast.error(error.response?.data?.message || '의사 목록을 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (doctor) => {
    setIsEditing(true);
    setSelectedDoctor({
      ...doctor,
      work_start_time: doctor.work_start_time.slice(0, 5),
      work_end_time: doctor.work_end_time.slice(0, 5),
      lunch_start: doctor.lunch_start.slice(0, 5),
      lunch_end: doctor.lunch_end.slice(0, 5)
    });
    setShowForm(true);
  };

  const handleAdd = () => {
    setIsEditing(false);
    setSelectedDoctor(null);
    setShowForm(true);
  };

  const handleSubmit = async (formData) => {
    try {
      // 시간 형식을 HH:mm:ss로 정확하게 변환
      const formatTime = (time) => {
        if (!time) return null;
        // 이미 HH:mm:ss 형식이면 그대로 반환
        if (time.split(':').length === 3) return time;
        // HH:mm 형식이면 :00 추가
        if (time.split(':').length === 2) return `${time}:00`;
        // 잘못된 형식이면 null 반환
        return null;
      };

      // 모든 시간 필드 검증
      const timeFields = ['work_start_time', 'work_end_time', 'lunch_start', 'lunch_end'];
      const formattedTimes = {};
      
      for (const field of timeFields) {
        const formattedTime = formatTime(formData[field]);
        if (!formattedTime) {
          toast.error(`${field} 시간 형식이 올바르지 않습니다.`);
          return;
        }
        formattedTimes[field] = formattedTime;
      }

      const submitData = {
        ...formData,
        ...formattedTimes,
        doctor_id: isEditing ? parseInt(formData.doctor_id) : undefined
      };

      if (isEditing) {
        const newDoctorId = parseInt(formData.doctor_id);
        const oldDoctorId = selectedDoctor.doctor_id;
        
        const response = await makeApiRequest(`/api/doctors/${oldDoctorId}`, {
          method: 'PUT',
          data: {
            ...submitData,
            old_doctor_id: oldDoctorId,
            new_doctor_id: newDoctorId
          }
        });
        
        if (response.data.success) {
          toast.success('의사 정보가 수정되었습니다.');
          loadDoctors();
        }
      } else {
        const response = await makeApiRequest('/api/doctors', {
          method: 'POST',
          data: submitData
        });
        
        if (response.data.success) {
          toast.success('새 의사가 등록되었습니다.');
          loadDoctors();
        }
      }
      setShowForm(false);
      setSelectedDoctor(null);
      setIsEditing(false);
    } catch (error) {
      console.error('의사 정보 저장 실패:', error);
      toast.error(error.response?.data?.message || '의사 정보 저장에 실패했습니다.');
    }
  };

  const handleClose = () => {
    setShowForm(false);
    setSelectedDoctor(null);
    setIsEditing(false);
  };

  const formatSchedule = (doctor) => {
    try {
      if (!doctor.work_days || !doctor.work_start_time || !doctor.work_end_time) {
        return '일정 없음';
      }
      return `${doctor.work_days} ${doctor.work_start_time.slice(0,5)}-${doctor.work_end_time.slice(0,5)}`;
    } catch (error) {
      console.error('스케줄 포맷팅 실패:', error);
      return '일정 없음';
    }
  };

  const handleDelete = async (doctorId) => {
    if (window.confirm('정말 이 의사를 삭제하시겠습니까?')) {
      try {
        const response = await makeApiRequest(`/api/doctors/${doctorId}`, {
          method: 'DELETE'
        });

        if (response.data.success) {
          toast.success('의사가 삭제되었습니다.');
          loadDoctors();
        }
      } catch (error) {
        console.error('의사 삭제 실패:', error);
        toast.error(error.response?.data?.message || '의사 삭제에 실패했습니다.');
      }
    }
  };

  return (
    <Container>
      <Header>
        <Title>의사 관리</Title>
        <Button onClick={handleAdd}>+ 의사 등록</Button>
      </Header>

      {loading ? (
        <div>로딩 중...</div>
      ) : doctors.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <Th>ID</Th>
              <Th>이름</Th>
              <Th>진료과</Th>
              <Th>전문분야</Th>
              <Th>진료 일정</Th>
              <Th>진료시간(분)</Th>
              <Th>점심시간</Th>
              <Th>작업</Th>
            </tr>
          </thead>
          <tbody>
            {doctors.map(doctor => (
              <tr key={doctor.doctor_id}>
                <Td>{doctor.doctor_id}</Td>
                <Td>{doctor.name}</Td>
                <Td>{doctor.department_name}</Td>
                <Td>{doctor.specialty}</Td>
                <Td>{formatSchedule(doctor)}</Td>
                <Td>{doctor.appointment_duration}</Td>
                <Td>{doctor.lunch_start?.slice(0,5)} - {doctor.lunch_end?.slice(0,5)}</Td>
                <Td>
                  <ButtonGroup>
                    <ActionButton onClick={() => handleEdit(doctor)}>수정</ActionButton>
                    <ActionButton danger onClick={() => handleDelete(doctor.doctor_id)}>삭제</ActionButton>
                  </ButtonGroup>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>등록된 의사가 없습니다.</div>
      )}

      {showForm && (
        <DoctorForm
          onSubmit={handleSubmit}
          onClose={handleClose}
          initialData={selectedDoctor}
          isEditing={isEditing}
        />
      )}
    </Container>
  );
};

export default DoctorList; 