import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/locale';
import { format } from 'date-fns';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #f8f9fa;
  padding: 0;
  border-radius: 8px;
  width: 95%;
  max-width: 1000px;
  max-height: 95vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
`;

const ModalHeader = styled.div`
  background: #fff;
  padding: 20px 24px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #2c3e50;
  }
`;

const FormContainer = styled.div`
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FormSection = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const SectionTitle = styled.h3`
  margin: 0 0 20px 0;
  padding-bottom: 12px;
  color: #2c3e50;
  font-size: 16px;
  border-bottom: 2px solid #3498db;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #34495e;
  font-size: 13px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s;
  
  &:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  font-size: 14px;
  background: white;
  cursor: pointer;
  
  &:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
`;

const TimeSlotGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 8px;
  margin-top: 12px;
`;

const TimeSlot = styled.button`
  padding: 8px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  background: ${props => props.selected ? '#3498db' : 'white'};
  color: ${props => props.selected ? 'white' : '#333'};
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:disabled {
    background: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
  
  &:hover:not(:disabled) {
    background: ${props => props.selected ? '#2980b9' : '#f8f9fa'};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  
  ${props => props.primary && `
    background: #3498db;
    color: white;
    &:hover {
      background: #2980b9;
    }
  `}
  
  ${props => props.secondary && `
    background: #f5f5f5;
    color: #333;
    &:hover {
      background: #e0e0e0;
    }
  `}
`;

const SearchContainer = styled.div`
  margin-bottom: 16px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const SearchType = styled.select`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 8px;
`;

const SearchResults = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 8px;
  max-height: 200px;
  overflow-y: auto;
  background: white;
`;

const SearchResultItem = styled.div`
  padding: 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  align-items: center;

  &:hover {
    background: #f5f7f9;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const SearchResultLabel = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

const SearchResultValue = styled.div`
  font-size: 14px;
  color: #333;
`;

const SearchField = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

const PatientInfo = styled.div`
  background: #f8f9fa;
  padding: 12px;
  border-radius: 4px;
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;

const SelectedPatient = styled.div`
  margin-top: 8px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const PATIENT_STATUS_OPTIONS = [
  { value: 'waiting', label: '대기' },
  { value: 'reserved', label: '예약' },
  { value: 'outpatient', label: '외래' },
  { value: 'hospitalized', label: '입원' },
  { value: 'discharged', label: '귀가' }
];

const generateTimeSlots = () => {
  const slots = [];
  const startTime = new Date();
  startTime.setHours(8, 30, 0);
  const endTime = new Date();
  endTime.setHours(17, 30, 0);

  while (startTime <= endTime) {
    slots.push(format(startTime, 'HH:mm'));
    startTime.setMinutes(startTime.getMinutes() + 15);
  }
  return slots;
};

const AppointmentForm = ({ onClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    user_id: '',
    doctor_id: '',
    department_id: '',
    appointment_date: new Date(),
    appointment_time: '',
    type: 'outpatient',
    special_requests: '',
    translator_required: false,
    patient_name: '',
    patient_status: 'waiting',
  });

  const [departments, setDepartments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState(generateTimeSlots());
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState('name');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        appointment_date: new Date(initialData.appointment_date),
        doctor_id: initialData.doctor_id.toString(),
        department_id: initialData.department_id.toString(),
        user_id: initialData.user_id,
        patient_name: initialData.patient_name,
        patient_number: initialData.patient_number,
        status: 'scheduled'
      });
      
      setSelectedPatient({
        user_id: initialData.user_id,
        name: initialData.patient_name,
        patient_number: initialData.patient_number,
        phone_number: initialData.patient_phone
      });
    }
  }, [initialData]);

  const loadAvailableTimeSlots = async () => {
    try {
      const date = format(formData.appointment_date, 'yyyy-MM-dd');
      const response = await makeApiRequest(
        `/api/appointments/available-slots?doctor_id=${formData.doctor_id}&date=${date}`
      );
      
      if (response.data.success) {
        const allTimeSlots = generateTimeSlots();
        const bookedSlots = new Set(response.data.data);
        const availableSlots = allTimeSlots.filter(slot => !bookedSlots.has(slot));
        setAvailableTimeSlots(availableSlots);
      }
    } catch (error) {
      toast.error('가능한 시간을 불러오는데 실패했습니다.');
    }
  };

  useEffect(() => {
    const loadDepartments = async () => {
      try {
        const response = await makeApiRequest('/api/departments');
        console.log('진료과 응답:', response);
        if (response?.data?.success) {
          setDepartments(response.data.data);
          console.log('설정된 진료과 목록:', response.data.data);
        }
      } catch (error) {
        console.error('진료과 목록 로드 실패:', error);
        toast.error('진료과 목록을 불러오는데 실패했습니다.');
      }
    };

    loadDepartments();
  }, []);

  useEffect(() => {
    const loadDoctors = async () => {
      if (!formData.department_id || formData.department_id === "") {
        setDoctors([]);
        return;
      }

      try {
        const response = await makeApiRequest(`/api/doctors?department_id=${formData.department_id}`);
        console.log('의사 목록 응답:', response);
        if (response?.data?.success) {
          setDoctors(response.data.data);
          console.log('설정된 의사 목록:', response.data.data);
        }
      } catch (error) {
        console.error('의사 목록 로드 실패:', error);
        toast.error('의사 목록을 불러오는데 실패했습니다.');
        setDoctors([]);
      }
    };

    loadDoctors();
  }, [formData.department_id]);

  useEffect(() => {
    if (formData.doctor_id && formData.appointment_date) {
      loadAvailableTimeSlots();
    }
  }, [formData.doctor_id, formData.appointment_date]);

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedPatient) {
      toast.error('환자를 선택해주세요.');
      return;
    }

    try {
      setLoading(true);
      
      const appointmentData = {
        ...formData,
        user_id: selectedPatient.user_id,
        patient_name: selectedPatient.name,
        patient_number: selectedPatient.patient_number,
        appointment_date: format(formData.appointment_date, 'yyyy-MM-dd'),
        status: 'scheduled',
        process_status: '예약완료',
        patient_status: 'reserved'
      };

      try {
        await makeApiRequest(`/api/patients/${selectedPatient.user_id}/status`, {
          method: 'PATCH',
          data: { status: 'reserved' }
        });
      } catch (error) {
        console.error('환자 상태 업데이트 실패:', error);
        toast.error('환자 상태 업데이트에 실패했습니다.');
      }

      await onSubmit(appointmentData, initialData?.appointment_id);
      onClose();
    } catch (error) {
      console.error('예약 처리 실패:', error);
      toast.error(error.response?.data?.message || '예약 처리에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (!query || query.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      const response = await makeApiRequest('/api/patients/search', {
        params: { query }
      });

      if (response.data.success) {
        setSearchResults(response.data.data);
      } else {
        console.error('검색 실패:', response.data.message);
        setSearchResults([]);
      }
    } catch (error) {
      console.error('환자 검색 실패:', error);
      if (error.response?.status !== 404) {
        toast.error('환자 검색에 실패했습니다.');
      }
      setSearchResults([]);
    }
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchQuery) {
        handleSearch(searchQuery);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  const handlePatientSelect = (patient) => {
    setFormData(prev => ({
      ...prev,
      user_id: patient.user_id,
      patient_name: patient.name,
      patient_number: patient.hospital_patient_no,
      patient_status: patient.patient_status || 'waiting'
    }));
    setSelectedPatient({
      user_id: patient.user_id,
      name: patient.name,
      patient_number: patient.hospital_patient_no,
      phone_number: patient.phone_number,
      birth_date: patient.birth_date,
      patient_status: patient.patient_status || 'waiting'
    });
    setSearchResults([]);
  };

  const handleDepartmentChange = (e) => {
    const selectedDeptId = e.target.value;
    console.log('선택된 진료과:', selectedDeptId);
    const deptId = parseInt(selectedDeptId);
    setFormData(prev => ({ 
      ...prev, 
      department_id: deptId || '',
      doctor_id: ''
    }));
  };

  const handleTimeSlotClick = (event, time) => {
    event.preventDefault();
    event.stopPropagation();

    setFormData(prev => ({
      ...prev,
      appointment_time: time
    }));
  };

  return (
    <Modal onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <h2>{initialData ? '예약 정보 수정' : '새 예약 등록'}</h2>
          <Button type="button" secondary onClick={onClose}>✕</Button>
        </ModalHeader>
        
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <FormSection>
              <SectionTitle>환자 정보</SectionTitle>
              <FormGroup>
                <Label>환자 검색</Label>
                <SearchInput
                  type="text"
                  placeholder="이름, 연락처, 생년월일로 검색 (2글자 이상)"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleSearchKeyPress}
                />

                {searchResults.length > 0 && (
                  <SearchResults>
                    {searchResults.map(patient => (
                      <SearchResultItem
                        key={patient.user_id}
                        onClick={() => handlePatientSelect(patient)}
                      >
                        <div>
                          <SearchResultLabel>이름</SearchResultLabel>
                          <SearchResultValue>
                            {patient.name}
                            {patient.english_name && ` (${patient.english_name})`}
                          </SearchResultValue>
                        </div>
                        <div>
                          <SearchResultLabel>환자번호</SearchResultLabel>
                          <SearchResultValue>{patient.hospital_patient_no || '-'}</SearchResultValue>
                        </div>
                        <div>
                          <SearchResultLabel>연락처</SearchResultLabel>
                          <SearchResultValue>{patient.phone_number}</SearchResultValue>
                        </div>
                        <div>
                          <SearchResultLabel>생년월일</SearchResultLabel>
                          <SearchResultValue>
                            {patient.birth_date ? format(new Date(patient.birth_date), 'yyyy-MM-dd') : '-'}
                          </SearchResultValue>
                        </div>
                      </SearchResultItem>
                    ))}
                  </SearchResults>
                )}

                {selectedPatient && (
                  <PatientInfo>
                    <div>이름: {selectedPatient.name}</div>
                    <div>환자번호: {selectedPatient.patient_number}</div>
                    <div>연락처: {selectedPatient.phone_number}</div>
                    {selectedPatient.birth_date && (
                      <div>생년월일: {format(new Date(selectedPatient.birth_date), 'yyyy-MM-dd')}</div>
                    )}
                  </PatientInfo>
                )}
              </FormGroup>
            </FormSection>

            <FormSection>
              <SectionTitle>진료 정보</SectionTitle>
              <FormGroup>
                <Label>진료과 *</Label>
                <Select
                  value={formData.department_id}
                  onChange={handleDepartmentChange}
                  required
                >
                  <option value="">진료과를 선택하세요</option>
                  {departments && departments.length > 0 ? departments.map(dept => (
                    <option 
                      key={dept.department_id} 
                      value={dept.department_id}
                      disabled={dept.department_type !== '진료실'}
                    >
                      {dept.department_name}
                      {dept.department_type !== '진료실' && ' (선택 불가)'}
                    </option>
                  )) : (
                    <option value="" disabled>진료과 목록을 불러오는 중...</option>
                  )}
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>담당의 *</Label>
                <Select
                  value={formData.doctor_id}
                  onChange={e => setFormData(prev => ({ ...prev, doctor_id: e.target.value }))}
                  disabled={!formData.department_id}
                  required
                >
                  <option value="">담당의를 선택하세요</option>
                  {doctors && doctors.length > 0 ? doctors.map(doctor => (
                    <option key={doctor.doctor_id} value={doctor.doctor_id}>
                      {doctor.name} ({doctor.specialty || '일반'})
                    </option>
                  )) : (
                    <option value="" disabled>
                      {formData.department_id ? '해당 진료과의 의사가 없습니다.' : '진료과를 먼저 선택하세요.'}
                    </option>
                  )}
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>예약 날짜 *</Label>
                <DatePicker
                  selected={formData.appointment_date}
                  onChange={date => setFormData(prev => ({ ...prev, appointment_date: date }))}
                  dateFormat="yyyy-MM-dd"
                  minDate={new Date()}
                  locale={ko}
                  className="form-control"
                />
              </FormGroup>

              <FormGroup>
                <Label>예약 시간 *</Label>
                <TimeSlotGrid>
                  {availableTimeSlots.length > 0 ? (
                    availableTimeSlots.map(time => (
                      <TimeSlot
                        key={time}
                        type="button"
                        selected={formData.appointment_time === time}
                        onClick={(event) => handleTimeSlotClick(event, time)}
                      >
                        {time}
                      </TimeSlot>
                    ))
                  ) : (
                    <div style={{ 
                      gridColumn: '1/-1', 
                      textAlign: 'center', 
                      padding: '20px', 
                      color: '#666' 
                    }}>
                      선택한 날짜에 예약 가능한 시간이 없습니다.
                    </div>
                  )}
                </TimeSlotGrid>
              </FormGroup>
            </FormSection>

            <div>
              <FormSection>
                <SectionTitle>진료 유형</SectionTitle>
                <FormGroup>
                  <Label>진료 유형 *</Label>
                  <Select
                    value={formData.type}
                    onChange={e => setFormData(prev => ({ ...prev, type: e.target.value }))}
                    required
                  >
                    <option value="outpatient">외래</option>
                    <option value="consultation">상담</option>
                    <option value="emergency">응급</option>
                  </Select>
                </FormGroup>
              </FormSection>

              <FormSection>
                <SectionTitle>추가 정보</SectionTitle>
                <FormGroup>
                  <Label>특별 요청사항</Label>
                  <Input
                    as="textarea"
                    value={formData.special_requests}
                    onChange={e => setFormData(prev => ({ 
                      ...prev, 
                      special_requests: e.target.value
                    }))}
                    placeholder="특별히 요청하실 사항이 있다면 입력해주세요"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>
                    <input
                      type="checkbox"
                      checked={formData.translator_required}
                      onChange={e => setFormData(prev => ({ 
                        ...prev, 
                        translator_required: e.target.checked 
                      }))}
                    />
                    {' '}통역이 필요합니다
                  </Label>
                </FormGroup>
              </FormSection>
            </div>

            <FormSection>
              <SectionTitle>환자 상태</SectionTitle>
              <FormGroup>
                <Label>현재 상태</Label>
                <Select
                  value={formData.patient_status}
                  onChange={e => setFormData(prev => ({ 
                    ...prev, 
                    patient_status: e.target.value 
                  }))}
                >
                  {PATIENT_STATUS_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormGroup>
            </FormSection>
          </FormContainer>

          <div style={{ 
            padding: '20px 24px',
            background: 'white',
            borderTop: '1px solid #e9ecef',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
            position: 'sticky',
            bottom: 0
          }}>
            <Button type="button" secondary onClick={onClose}>
              취소
            </Button>
            <Button type="submit" primary disabled={loading}>
              {initialData ? '수정하기' : '등록하기'}
            </Button>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AppointmentForm; 