import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { makeApiRequest } from '../../../utils/apiUtils';
import AppointmentForm from './AppointmentForm';
import { toast } from 'react-toastify';
import { 
  format, 
  startOfMonth, 
  endOfMonth, 
  eachDayOfInterval, 
  isSameDay, 
  isSameMonth,
  addMonths,
  subMonths
} from 'date-fns';
import { ko } from 'date-fns/locale';
import { useAuth } from '../../../contexts/AuthContext';

const Container = styled.div`
  background: #f8f9fa;
  min-height: 100vh;
  padding: 24px;
`;

const ContentCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px;
  background: white;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    color: #2c3e50;
    font-size: 1.5rem;
  }
`;

const FilterContainer = styled.div`
  padding: 16px 24px;
  background: white;
  border-bottom: 1px solid #e9ecef;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  align-items: center;
`;

const SearchInput = styled.input`
  padding: 10px 12px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;

  &:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
`;

const DateFilter = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const StatusFilter = styled.select`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  padding: 0 24px 24px 24px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: white;
`;

const Th = styled.th`
  padding: 12px 16px;
  text-align: left;
  background: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  font-weight: 600;
  color: #495057;
  white-space: nowrap;
`;

const Td = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #dee2e6;
  vertical-align: middle;
  color: #212529;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: #f8f9fa;
  }
`;

const ActionButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  
  ${props => {
    switch (props.status) {
      case 'scheduled':
        return 'background: #E3F2FD; color: #1976D2; border: 1px solid #1976D2;';
      case 'cancelled':
        return 'background: #FFEBEE; color: #C62828; border: 1px solid #C62828;';
      default:
        return 'background: #F5F5F5; color: #424242; border: 1px solid #9E9E9E;';
    }
  }}
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
  
  ${props => props.primary && `
    background: #1976D2;
    color: white;
    &:hover { background: #1565C0; }
    &:disabled {
      background: #BBDEFB;
      cursor: not-allowed;
    }
  `}
  
  ${props => props.secondary && `
    background: #F5F5F5;
    color: #424242;
    &:hover { background: #E0E0E0; }
  `}
  
  ${props => props.danger && `
    background: #EF5350;
    color: white;
    &:hover { background: #E53935; }
    &:disabled {
      background: #FFCDD2;
      cursor: not-allowed;
    }
  `}
`;

const DeleteModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
`;

const DeleteModalContent = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
`;

const DeleteModalButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
`;

const CalendarSection = styled.div`
  background: white;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const MonthNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const NavButton = styled.button`
  padding: 8px 16px;
  background: none;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  cursor: pointer;
  color: #1d1d1f;
  
  &:hover {
    background: #f5f5f7;
  }
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background: #e1e1e1;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const DayHeader = styled.div`
  background: #f8f9fa;
  padding: 12px;
  text-align: center;
  font-weight: 600;
  color: #1d1d1f;
  border-bottom: 1px solid #e1e1e1;
  
  &:nth-child(1) {
    color: #e53935;  // 일요일
  }
  &:nth-child(7) {
    color: #1976D2;  // 토요일
  }
`;

const DayCell = styled.div`
  background: white;
  min-height: 120px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  border: 1px solid transparent;
  
  ${props => props.isToday && `
    background: #f0f7ff;
    &::after {
      content: '오늘';
      position: absolute;
      top: 4px;
      right: 4px;
      background: #007AFF;
      color: white;
      padding: 2px 6px;
      border-radius: 4px;
      font-size: 11px;
    }
  `}
  
  ${props => props.isOtherMonth && `
    background: #fafafa;
    color: #86868b;
  `}
  
  ${props => props.isSelected && `
    background: #e3f2fd;
    border: 2px solid #1976D2;
    box-shadow: 0 0 0 1px #1976D2;
  `}
  
  &:hover {
    background: ${props => props.isOtherMonth ? '#f5f5f5' : '#e3f2fd'};
    border: 1px solid #1976D2;
  }
`;

const DateNumber = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  color: ${props => props.isToday ? '#007AFF' : props.isOtherMonth ? '#86868b' : '#333'};
  font-weight: ${props => props.isToday || props.isSelected ? '600' : 'normal'};
  padding: 2px 4px;
  border-radius: 4px;
  display: inline-block;
  
  ${props => props.isSelected && `
    background: #1976D2;
    color: white;
  `}
`;

const EventItem = styled.div`
  background: ${props => {
    switch(props.status) {
      case 'scheduled': return '#E3F2FD';
      case 'cancelled': return '#FFEBEE';
      default: return '#F5F5F5';
    }
  }};
  color: ${props => {
    switch(props.status) {
      case 'scheduled': return '#1976D2';
      case 'cancelled': return '#C62828';
      default: return '#424242';
    }
  }};
  border-left: 3px solid ${props => {
    switch(props.status) {
      case 'scheduled': return '#1976D2';
      case 'cancelled': return '#C62828';
      default: return '#9E9E9E';
    }
  }};
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 6px;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  
  &:hover {
    transform: translateX(2px);
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
    filter: brightness(0.95);
  }
  
  &:active {
    transform: translateX(1px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
`;

const EventTime = styled.div`
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 4px;
  color: #1976D2;
  background: rgba(25, 118, 210, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
`;

const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const EventDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  color: ${props => props.muted ? '#666' : 'inherit'};
`;

const MonthYearDisplay = styled.h3`
  margin: 0;
  min-width: 200px;
  text-align: center;
  font-size: 20px;
`;

const TodayButton = styled.button`
  padding: 8px 16px;
  background: #f5f5f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background: #e5e5e7;
  }
`;

const PatientStatusBadge = styled(StatusBadge)`
  ${props => {
    switch (props.status) {
      case 'waiting':
        return 'background: #FFF3E0; color: #E65100; border: 1px solid #E65100;';
      case 'reserved':
        return 'background: #E3F2FD; color: #1565C0; border: 1px solid #1565C0;';
      case 'outpatient':
        return 'background: #E8F5E9; color: #2E7D32; border: 1px solid #2E7D32;';
      case 'hospitalized':
        return 'background: #EDE7F6; color: #4527A0; border: 1px solid #4527A0;';
      case 'discharged':
        return 'background: #EFEBE9; color: #4E342E; border: 1px solid #4E342E;';
      default:
        return 'background: #F5F5F5; color: #424242; border: 1px solid #9E9E9E;';
    }
  }}
`;

const MoreEventsButton = styled.div`
  font-size: 12px;
  color: #1976D2;
  text-align: center;
  padding: 6px;
  background: #E3F2FD;
  border-radius: 4px;
  margin-top: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid #1976D2;
  
  &:hover {
    background: #1976D2;
    color: white;
  }
`;

const AppointmentList = () => {
  const auth = useAuth();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [filters, setFilters] = useState({
    search: '',
    status: 'all'
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(today);
  const [selectedDate, setSelectedDate] = useState(today);

  useEffect(() => {
    loadAppointments();
  }, [currentDate, filters.search, filters.status]);

  const loadAppointments = async () => {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams();
      
      const startDate = format(startOfMonth(currentDate), 'yyyy-MM-dd');
      const endDate = format(endOfMonth(currentDate), 'yyyy-MM-dd');
      
      queryParams.append('startDate', startDate);
      queryParams.append('endDate', endDate);

      if (filters.search) queryParams.append('search', filters.search);
      if (filters.status !== 'all') queryParams.append('status', filters.status);

      const response = await makeApiRequest(`/api/appointments?${queryParams}`);
      
      if (response.data.success) {
        const sortedAppointments = response.data.data.sort((a, b) => {
          const dateA = `${a.appointment_date} ${a.appointment_time}`;
          const dateB = `${b.appointment_date} ${b.appointment_time}`;
          return new Date(dateA) - new Date(dateB);
        });
        
        setAppointments(sortedAppointments);
      } else {
        toast.error('예약 목록을 불러오는데 실패했습니다.');
      }
    } catch (error) {
      console.error('예약 목록 로드 실패:', error);
      toast.error('예약 목록을 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (appointmentId, newStatus) => {
    try {
      const response = await makeApiRequest(`/api/appointments/${appointmentId}/status`, {
        method: 'PATCH',
        data: { status: newStatus }
      });

      if (response.data.success) {
        toast.success('예약 상태가 변경되었습니다.');
        loadAppointments();
      } else {
        throw new Error(response.data.message || '예약 상태 변경에 실패했습니다.');
      }
    } catch (error) {
      console.error('예약 상태 변경 실패:', error);
      toast.error(error.response?.data?.message || '예약 상태 변경에 실패했습니다.');
    }
  };

  const handleCancelClick = (appointmentId) => {
    handleStatusChange(appointmentId, 'cancelled');
  };

  const formatDateTime = (date, time) => {
    try {
      const appointmentDate = new Date(date);
      const [hours, minutes] = time.split(':');
      appointmentDate.setHours(hours);
      appointmentDate.setMinutes(minutes);

      return format(appointmentDate, 'PPP EEE p', { locale: ko });
    } catch (error) {
      console.error('날짜 형식 변환 오류:', error);
      return '날짜 형식 오류';
    }
  };

  const handleAppointmentUpdate = async (formData, appointmentId) => {
    try {
      const updateData = {
        user_id: formData.user_id,
        doctor_id: formData.doctor_id,
        department_id: formData.department_id,
        appointment_date: formData.appointment_date,
        appointment_time: formData.appointment_time,
        type: formData.type,
        special_requests: formData.special_requests,
        translator_required: formData.translator_required,
        status: 'scheduled',
        process_status: '예약완료'
      };

      const response = await makeApiRequest(`/api/appointments/${appointmentId}`, {
        method: 'PUT',
        data: updateData
      });

      if (response.data.success) {
        toast.success('예약이 수정되었습니다.');
        loadAppointments();
      } else {
        throw new Error(response.data.message || '예약 수정에 실패했습니다.');
      }
    } catch (error) {
      console.error('예약 수정 실패:', error);
      toast.error(error.response?.data?.message || '예약 수정에 실패했습니다.');
      throw error;
    }
  };

  const handleAppointmentSubmit = async (formData, appointmentId) => {
    try {
      if (appointmentId) {
        await handleAppointmentUpdate(formData, appointmentId);
      } else {
        const appointmentData = {
          ...formData,
          status: 'scheduled',
          created_by: auth?.user?.userId || null,
          patient_status: 'reserved'
        };

        const response = await makeApiRequest('/api/appointments', {
          method: 'POST',
          data: appointmentData
        });

        if (response.data.success) {
          try {
            await makeApiRequest(`/api/patients/${formData.user_id}/status`, {
              method: 'PATCH',
              data: { status: 'reserved' }
            });
          } catch (error) {
            console.error('환자 상태 업데이트 실패:', error);
            toast.error('환자 상태 업데이트에 실패했습니다.');
          }

          toast.success('예약이 등록되었습니다.');
        } else {
          throw new Error(response.data.message || '예약 등록에 실패했습니다.');
        }
      }
      setShowForm(false);
      loadAppointments();
    } catch (error) {
      console.error('예약 처리 실패:', error);
      throw error;
    }
  };

  const handleDeleteClick = (appointment) => {
    setAppointmentToDelete(appointment);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await makeApiRequest(`/api/appointments/${appointmentToDelete.appointment_id}`, {
        method: 'DELETE'
      });

      if (response.data.success) {
        toast.success('예약이 삭제되었습니다.');
        loadAppointments();
      } else {
        throw new Error(response.data.message || '예약 삭제에 실패했습니다.');
      }
    } catch (error) {
      console.error('예약 삭제 실패:', error);
      toast.error(error.response?.data?.message || '예약 삭제에 실패했습니다.');
    } finally {
      setDeleteModalOpen(false);
      setAppointmentToDelete(null);
    }
  };

  const appointmentsToEvents = (appointments) => {
    return appointments.map(appointment => ({
      id: appointment.appointment_id,
      title: `${appointment.patient_name} - ${appointment.department_name}`,
      start: `${appointment.appointment_date}T${appointment.appointment_time}`,
      end: `${appointment.appointment_date}T${
        (() => {
          const [hours, minutes] = appointment.appointment_time.split(':');
          const date = new Date();
          date.setHours(parseInt(hours));
          date.setMinutes(parseInt(minutes) + 30);
          return date.toTimeString().slice(0, 5);
        })()
      }`,
      className: `fc-event-${appointment.status}`,
      extendedProps: {
        appointment: appointment
      }
    }));
  };

  const handleEventClick = (info) => {
    const appointment = info.event.extendedProps.appointment;
    setAppointmentToDelete(appointment);
    setDeleteModalOpen(true);
  };

  useEffect(() => {
    if (!auth?.user) {
      console.log('사용자가 로그인되지 않았습니다.');
      return;
    }
  }, [auth]);

  const weekDays = ['일', '월', '화', '수', '목', '금', '토'];
  
  const getDaysInMonth = (date) => {
    const start = startOfMonth(date);
    const end = endOfMonth(date);
    const days = eachDayOfInterval({ start, end });
    
    const firstDayOfMonth = start.getDay();
    const prevMonthDays = Array(firstDayOfMonth).fill(null).map((_, index) => {
      return new Date(start.getFullYear(), start.getMonth(), -index);
    }).reverse();
    
    const lastDayOfMonth = end.getDay();
    const nextMonthDays = Array(6 - lastDayOfMonth).fill(null).map((_, index) => {
      return new Date(end.getFullYear(), end.getMonth() + 1, index + 1);
    });
    
    return [...prevMonthDays, ...days, ...nextMonthDays];
  };

  const handlePrevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const getAppointmentsForDate = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    return appointments.filter(appointment => {
      const appointmentDate = format(new Date(appointment.appointment_date), 'yyyy-MM-dd');
      return appointmentDate === formattedDate;
    }).sort((a, b) => a.appointment_time.localeCompare(b.appointment_time));
  };

  const getFilteredAppointments = () => {
    if (!selectedDate) return [];
    
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    return appointments.filter(appointment => {
      const appointmentDate = format(new Date(appointment.appointment_date), 'yyyy-MM-dd');
      const matchesDate = appointmentDate === formattedDate;
      const matchesSearch = !filters.search || 
        appointment.patient_name?.toLowerCase().includes(filters.search.toLowerCase()) ||
        appointment.doctor_name?.toLowerCase().includes(filters.search.toLowerCase());
      const matchesStatus = filters.status === 'all' || appointment.status === filters.status;
      
      return matchesDate && matchesSearch && matchesStatus;
    }).sort((a, b) => a.appointment_time.localeCompare(b.appointment_time));
  };

  const handleTodayClick = () => {
    const today = new Date();
    setCurrentDate(today);
    setSelectedDate(today);
    setFilters(prev => ({
      ...prev,
      date: format(today, 'yyyy-MM-dd')
    }));
  };

  const formatAppointmentTime = (time) => {
    return time;  // HH:mm 형식이므로 그대로 사용
  };

  const renderTableSection = () => {
    const filteredAppointments = getFilteredAppointments();
    
    if (loading) {
      return <div style={{ padding: '24px', textAlign: 'center' }}>로딩 중...</div>;
    }

    return (
      <TableContainer>
        <div style={{ padding: '16px 0', fontWeight: '500', fontSize: '16px', color: '#1976D2' }}>
          {format(selectedDate, 'yyyy년 M월 d일', { locale: ko })} 예약 목록
        </div>
        {filteredAppointments.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <Th>예약 시간</Th>
                <Th>상태</Th>
                <Th>환자명</Th>
                <Th>환자번호</Th>
                <Th>환자상태</Th>
                <Th>진료과</Th>
                <Th>담당의</Th>
                <Th>통역 필요</Th>
                <Th>작업</Th>
              </tr>
            </thead>
            <tbody>
              {filteredAppointments.map(appointment => (
                <TableRow key={appointment.appointment_id}>
                  <Td>{appointment.appointment_time}</Td>
                  <Td>
                    <StatusBadge status={appointment.status}>
                      {appointment.status === 'scheduled' && '예약됨'}
                      {appointment.status === 'cancelled' && '취소됨'}
                    </StatusBadge>
                  </Td>
                  <Td>{appointment.patient_name}</Td>
                  <Td>{appointment.patient_number || '-'}</Td>
                  <Td>
                    <PatientStatusBadge status={appointment.patient_status}>
                      {(() => {
                        switch (appointment.patient_status) {
                          case 'waiting': return '대기';
                          case 'reserved': return '예약';
                          case 'outpatient': return '외래';
                          case 'hospitalized': return '입원';
                          case 'discharged': return '귀가';
                          default: return '미정';
                        }
                      })()}
                    </PatientStatusBadge>
                  </Td>
                  <Td>{appointment.department_name}</Td>
                  <Td>{appointment.doctor_name}</Td>
                  <Td>
                    <StatusBadge status={appointment.translator_required ? 'pending' : 'completed'}>
                      {appointment.translator_required ? '필요' : '불필요'}
                    </StatusBadge>
                  </Td>
                  <Td>
                    <ActionButtonGroup>
                      <ActionButton
                        primary
                        onClick={() => {
                          setSelectedAppointment({
                            ...appointment,
                            appointment_date: new Date(appointment.appointment_date)
                          });
                          setShowForm(true);
                        }}
                      >
                        변경
                      </ActionButton>
                      <ActionButton
                        danger
                        onClick={() => handleCancelClick(appointment.appointment_id)}
                        disabled={appointment.status === 'cancelled'}
                      >
                        취소
                      </ActionButton>
                      <ActionButton
                        danger
                        onClick={() => handleDeleteClick(appointment)}
                      >
                        삭제
                      </ActionButton>
                    </ActionButtonGroup>
                  </Td>
                </TableRow>
              ))}
            </tbody>
          </Table>
        ) : (
          <div style={{ 
            padding: '48px 24px', 
            textAlign: 'center',
            color: '#666',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            margin: '24px'
          }}>
            <div style={{ marginBottom: '16px', fontSize: '16px' }}>
              {format(selectedDate, 'yyyy년 M월 d일', { locale: ko })}에 예약된 진료가 없습니다.
            </div>
            <ActionButton primary onClick={() => setShowForm(true)}>
              새 예약 등록하기
            </ActionButton>
          </div>
        )}
      </TableContainer>
    );
  };

  return (
    <Container>
      <ContentCard>
        <Header>
          <h2>예약 관리</h2>
          <ActionButton primary onClick={() => setShowForm(true)}>
            + 새 예약
          </ActionButton>
        </Header>

        <CalendarSection>
          <MonthNavigation>
            <div style={{ display: 'flex', gap: '12px' }}>
              <NavButton onClick={handlePrevMonth}>&lt; 이전달</NavButton>
              <NavButton onClick={handleNextMonth}>다음달 &gt;</NavButton>
            </div>
            <MonthYearDisplay>
              {format(currentDate, 'yyyy년 M월', { locale: ko })}
            </MonthYearDisplay>
            <TodayButton onClick={handleTodayClick}>
              오늘
            </TodayButton>
          </MonthNavigation>

          <CalendarGrid>
            {weekDays.map(day => (
              <DayHeader key={day}>{day}</DayHeader>
            ))}
            
            {getDaysInMonth(currentDate).map((date, index) => {
              const isToday = isSameDay(date, new Date());
              const isCurrentMonth = isSameMonth(date, currentDate);
              const isSelected = selectedDate && isSameDay(date, selectedDate);
              const dayAppointments = getAppointmentsForDate(date)
                .sort((a, b) => a.appointment_time.localeCompare(b.appointment_time));
              
              return (
                <DayCell 
                  key={index}
                  isToday={isToday}
                  isOtherMonth={!isCurrentMonth}
                  isSelected={isSelected}
                  onClick={() => handleDateClick(date)}
                >
                  <DateNumber 
                    isToday={isToday} 
                    isSelected={isSelected}
                    isOtherMonth={!isCurrentMonth}
                  >
                    {format(date, 'd')}
                  </DateNumber>
                  {dayAppointments.slice(0, 3).map(appointment => (
                    <EventItem 
                      key={appointment.appointment_id}
                      status={appointment.status}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedAppointment({
                          ...appointment,
                          appointment_date: new Date(appointment.appointment_date)
                        });
                        setShowForm(true);
                      }}
                    >
                      <EventTime>
                        {formatAppointmentTime(appointment.appointment_time)}
                      </EventTime>
                      <EventContent>
                        <EventDetail>
                          <span>{appointment.patient_name}</span>
                          <span>{
                            appointment.status === 'scheduled' ? '예약' : '취소'
                          }</span>
                        </EventDetail>
                        <EventDetail muted>
                          <span>{appointment.department_name}</span>
                          <span>{appointment.doctor_name}</span>
                        </EventDetail>
                      </EventContent>
                    </EventItem>
                  ))}
                  {dayAppointments.length > 3 && (
                    <MoreEventsButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDateClick(date);
                        document.querySelector('.TableContainer')?.scrollIntoView({ 
                          behavior: 'smooth',
                          block: 'start'
                        });
                      }}
                    >
                      +{dayAppointments.length - 3}개 더보기
                    </MoreEventsButton>
                  )}
                  {isToday && dayAppointments.length === 0 && (
                    <div style={{ 
                      textAlign: 'center', 
                      color: '#666', 
                      fontSize: '12px',
                      marginTop: '20px',
                      padding: '8px',
                      background: '#f8f9fa',
                      borderRadius: '4px'
                    }}>
                      예약 없음
                    </div>
                  )}
                </DayCell>
              );
            })}
          </CalendarGrid>
        </CalendarSection>

        <FilterContainer>
          <SearchInput
            placeholder="환자명 또는 의사명 검색..."
            value={filters.search}
            onChange={e => setFilters(prev => ({ ...prev, search: e.target.value }))}
          />
          <StatusFilter
            value={filters.status}
            onChange={e => setFilters(prev => ({ ...prev, status: e.target.value }))}
          >
            <option value="all">모든 상태</option>
            <option value="scheduled">예약됨</option>
            <option value="cancelled">취소됨</option>
          </StatusFilter>
        </FilterContainer>

        {renderTableSection()}
      </ContentCard>

      {showForm && (
        <AppointmentForm
          onClose={() => {
            setShowForm(false);
            setSelectedAppointment(null);
          }}
          onSubmit={handleAppointmentSubmit}
          initialData={selectedAppointment}
        />
      )}

      {deleteModalOpen && (
        <DeleteModal onClick={() => setDeleteModalOpen(false)}>
          <DeleteModalContent onClick={e => e.stopPropagation()}>
            <h3>예약 삭제 확인</h3>
            <p>
              정말로 이 예약을 삭제하시겠습니까?<br />
              {appointmentToDelete && (
                <>
                  환자명: {appointmentToDelete.patient_name}<br />
                  예약 일시: {formatDateTime(
                    appointmentToDelete.appointment_date,
                    appointmentToDelete.appointment_time
                  )}
                </>
              )}
            </p>
            <DeleteModalButtons>
              <ActionButton
                secondary
                onClick={() => setDeleteModalOpen(false)}
              >
                취소
              </ActionButton>
              <ActionButton
                danger
                onClick={handleDeleteConfirm}
              >
                삭제
              </ActionButton>
            </DeleteModalButtons>
          </DeleteModalContent>
        </DeleteModal>
      )}
    </Container>
  );
};

export default AppointmentList; 