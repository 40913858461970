import axios from 'axios';
import { API_URL } from '../config/config';
import { getAuthHeaders } from './authService';

// axios 인스턴스 생성
const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// 요청 인터셉터 추가
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // 토큰이 만료되었거나 유효하지 않은 경우
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const getPatients = async () => {
  try {
    const response = await api.get('/api/users');
    return response.data;
  } catch (error) {
    console.error('사용자 목록 조회 실패:', error);
    console.error('Error details: ', error);
    throw error;
  }
};

export const fetchPatients = getPatients;

export const createPatient = async (userData) => {
  try {
    const response = await api.post('/api/auth/signup', userData);
    return response.data;
  } catch (error) {
    console.error('사용자 등록 실패:', error);
    throw error;
  }
};

export const updatePatient = async (userId, userData) => {
  try {
    const response = await api.put(`/api/users/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error('사용자 정보 수정 실패:', error);
    throw error;
  }
};

export const deletePatient = async (userId) => {
  try {
    const response = await api.delete(`/api/users/${userId}`);
    
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.message || '환자 삭제에 실패했습니다.');
    }
  } catch (error) {
    console.error('환자 삭제 실패:', error);
    throw new Error(error.response?.data?.message || '환자 삭제 중 오류가 발생했습니다.');
  }
};

export const getPatientById = async (patientId) => {
  try {
    const response = await api.get(`/api/patients/${patientId}`);
    return response.data;
  } catch (error) {
    console.error('환자 정보 조회 실패:', error);
    throw error;
  }
};

export default {
  getPatients,
  fetchPatients,
  createPatient,
  updatePatient,
  deletePatient,
  getPatientById,
}; 