import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';
import TreatmentForm from './TreatmentForm';
import PatientTreatmentFlow from './PatientTreatmentFlow';

const Container = styled.div`
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1a1a1a;
  margin: 0;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
`;

const Th = styled.th`
  padding: 12px;
  background: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  text-align: left;
  font-weight: 600;
  color: #495057;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
`;

const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  
  ${props => {
    switch (props.status) {
      case 'completed':
        return 'background: #E3F2FD; color: #1976D2; border: 1px solid #1976D2;';
      case 'in_progress':
        return 'background: #FFF3E0; color: #E65100; border: 1px solid #E65100;';
      case 'pending':
        return 'background: #F5F5F5; color: #616161; border: 1px solid #616161;';
      default:
        return 'background: #FFEBEE; color: #C62828; border: 1px solid #C62828;';
    }
  }}
`;

const ActionButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
`;

const SearchInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 300px;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const WaitingPatientsSection = styled.div`
  margin-top: 32px;
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  color: #1a1a1a;
  margin-bottom: 16px;
`;

const WaitingPatientsTable = styled(Table)`
  margin-top: 16px;
`;

const PatientStatusBadge = styled(StatusBadge)`
  ${props => {
    switch (props.status) {
      case 'waiting':
        return 'background: #FFF3E0; color: #E65100; border: 1px solid #E65100;';
      case 'in_treatment':
        return 'background: #E3F2FD; color: #1565C0; border: 1px solid #1565C0;';
      case 'completed':
        return 'background: #E8F5E9; color: #2E7D32; border: 1px solid #2E7D32;';
      default:
        return 'background: #F5F5F5; color: #424242; border: 1px solid #9E9E9E;';
    }
  }}
`;

const StatusButton = styled(ActionButton)`
  padding: 4px 8px;
  font-size: 12px;
  background: transparent;
  border: 1px solid #ddd;
  color: #495057;
  
  &:hover {
    background: #f8f9fa;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}
`;

const StatusDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 120px;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.2s;
  
  &:hover {
    background: #f8f9fa;
  }
`;

const EditableCell = styled(Td)`
  padding: 4px;
  position: relative;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
    box-shadow: inset 0 0 0 1px #007AFF;
    border-radius: 2px;
  }

  &.editing {
    background-color: #fff;
    box-shadow: inset 0 0 0 2px #007AFF;
    border-radius: 2px;
    padding: 0;
  }

  &:hover .edit-icon {
    opacity: 1;
  }
`;

const EditInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 4px;
  border: none;
  background: transparent;
  font-size: 14px;
  border-radius: 2px;
  
  &:focus {
    outline: none;
    background: white;
  }
`;

const EditIcon = styled.span`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  color: #007AFF;
  font-size: 12px;
  transition: opacity 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
  padding: 2px;
  border-radius: 4px;
`;

const EditSelect = styled.select`
  width: 100%;
  height: 100%;
  padding: 4px;
  border: none;
  background: transparent;
  font-size: 14px;
  border-radius: 2px;
  
  &:focus {
    outline: none;
    background: white;
  }

  option {
    background: white;
    color: #1d1d1f;
  }
`;

const patientStatusMap = {
  'waiting': '대기',
  'reserved': '예약',
  'outpatient': '외래',
  'in_treatment': '진료중',
  'completed': '완료',
  'discharged': '귀가'
};

const patientStatusOptions = [
  { value: 'waiting', label: '대기' },
  { value: 'reserved', label: '예약' },
  { value: 'outpatient', label: '외래' },
  { value: 'in_treatment', label: '진료중' },
  { value: 'completed', label: '완료' },
  { value: 'discharged', label: '귀가' }
];

const isPatientStatus = (value) => {
  return value && Object.keys(patientStatusMap).includes(value);
};

const EditableField = ({ value, onChange, type = 'text', options = [], disabled = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (!disabled) {
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (tempValue !== value) {
      onChange(tempValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    } else if (e.key === 'Escape') {
      setTempValue(value);
      setIsEditing(false);
    }
  };

  const renderValue = () => {
    if (type === 'select' && isPatientStatus(value)) {
      return (
        <PatientStatusBadge status={value}>
          {patientStatusMap[value] || '대기'}
        </PatientStatusBadge>
      );
    }
    return (
      <span 
        style={{ 
          padding: '8px', 
          display: 'block',
          cursor: disabled ? 'not-allowed' : 'text'
        }}
      >
        {value || '-'}
      </span>
    );
  };

  return (
    <EditableCell 
      onClick={handleClick}
      className={isEditing ? 'editing' : ''}
      style={disabled ? { cursor: 'not-allowed' } : {}}
    >
      {isEditing ? (
        type === 'select' ? (
          <EditSelect
            ref={inputRef}
            value={tempValue}
            onChange={(e) => setTempValue(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onClick={(e) => e.stopPropagation()}
          >
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </EditSelect>
        ) : (
          <EditInput
            ref={inputRef}
            type={type}
            value={tempValue}
            onChange={(e) => setTempValue(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onClick={(e) => e.stopPropagation()}
          />
        )
      ) : (
        <>
          {renderValue()}
          {!disabled && <EditIcon className="edit-icon">✎</EditIcon>}
        </>
      )}
    </EditableCell>
  );
};

const TableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #f8f9fa;
  }
`;

const TreatmentList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openStatusDropdown, setOpenStatusDropdown] = useState(null);
  const [editingPatientId, setEditingPatientId] = useState(null);
  const [editingPatientNumber, setEditingPatientNumber] = useState('');
  const [activeDropdown, setActiveDropdown] = useState({ userId: null, type: null });
  const [selectedPatient, setSelectedPatient] = useState(null);

  const getAppointmentStatusText = (status) => {
    switch (status) {
      case 'scheduled':
        return '예약완료';
      case 'confirmed':
        return '확정';
      case 'in_progress':
        return '진행중';
      case 'completed':
        return '완료';
      case 'cancelled':
        return '취소';
      case 'no_show':
        return '미방문';
      default:
        return '미정';
    }
  };

  const getPatientStatusText = (status) => {
    switch (status) {
      case 'waiting':
        return '대기';
      case 'reserved':
        return '예약';
      case 'outpatient':
        return '외래';
      case 'in_treatment':
        return '진료중';
      case 'completed':
        return '완료';
      case 'discharged':
        return '귀가';
      default:
        return '미정';
    }
  };

  const handleTreatmentSubmit = async (formData) => {
    try {
      if (selectedTreatment?.treatment_id) {
        await makeApiRequest(`/api/treatments/${selectedTreatment.treatment_id}`, {
          method: 'PUT',
          data: formData
        });
      } else {
        await makeApiRequest('/api/treatments', {
          method: 'POST',
          data: formData
        });
      }
      setShowForm(false);
      setSelectedTreatment(null);
      toast.success('진료가 등록되었습니다.');
      
      await Promise.all([
        loadAppointments(),
        loadUsers()
      ]);
    } catch (error) {
      console.error('진료 처리 실패:', error);
      toast.error('진료 처리에 실패했습니다.');
    }
  };

  useEffect(() => {
    loadUsers();
    loadAppointments();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const filtered = users.filter(user => {
        const searchRegex = new RegExp(searchTerm, 'i');
        
        return (
          (searchTerm === '' || 
           searchRegex.test(user.name) || 
           searchRegex.test(user.hospital_patient_no || '')) &&
          (user.role_id === 3 || user.role_id === 4) // 환자 역할(3)과 입원환자 역할(4) 표시
        );
      });
      setFilteredUsers(filtered);
    }
  }, [users, searchTerm]);

  const loadUsers = async () => {
    try {
      setLoading(true);
      const response = await makeApiRequest('/api/users');
      if (response.data.success) {
        const mappedUsers = response.data.data.map(user => ({
          userId: user.userId,
          user_id: user.userId,
          name: user.name,
          hospital_patient_no: user.hospital_patient_no,
          role_id: user.role_id,
          patient_status: user.patient_status
        }));
        setUsers(mappedUsers);
        setFilteredUsers(mappedUsers);
      }
    } catch (error) {
      console.error('사용자 목록 로드 실패:', error);
      toast.error('사용자 목록을 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const loadAppointments = async () => {
    try {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      const response = await makeApiRequest('/api/appointments', {
        params: {
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          status: ['scheduled', 'confirmed', 'in_progress'].join(','),
          sort: 'appointment_date,ASC'
        }
      });

      if (response.data.success) {
        const appointments = response.data.data;
        console.log('Raw appointments:', appointments);

        setAppointments(appointments);
      }
    } catch (error) {
      console.error('예약 목록 로드 실패:', error);
      toast.error('예약 목록을 불러오는데 실패했습니다.');
    }
  };

  const getAppointmentInfo = (user) => {
    // userId와 hospital_patient_no로 예약 찾기
    const userAppointments = appointments.filter(apt => {
      return (
        (apt.user_id === user.user_id || apt.patient_number === user.hospital_patient_no) && 
        ['scheduled', 'confirmed', 'in_progress'].includes(apt.status)
      );
    });

    if (userAppointments.length === 0) {
      // 예약이 없는 경우 null 대신 기본 객체 반환
      return {
        department_name: '-',
        doctor_name: '-',
        formatted_date: '-',
        formatted_time: '-',
        status: 'none'
      };
    }

    // 가장 최근 예약 반환
    const sortedAppointments = userAppointments.sort((a, b) => {
      const dateA = new Date(`${a.appointment_date} ${a.appointment_time || '00:00'}`);
      const dateB = new Date(`${b.appointment_date} ${b.appointment_time || '00:00'}`);
      return dateA - dateB;
    });

    const appointment = sortedAppointments[0];
    return {
      ...appointment,
      formatted_date: new Date(appointment.appointment_date).toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }),
      formatted_time: appointment.appointment_time?.substring(0, 5) || ''
    };
  };

  const handleStatusChange = async (userId, newStatus) => {
    try {
      await makeApiRequest(`/api/users/${userId}/status`, {
        method: 'PUT',
        data: { patient_status: newStatus }
      });
      toast.success('환자 상태가 변경되었습니다.');
      loadUsers();
      setOpenStatusDropdown(null);
    } catch (error) {
      console.error('상태 변경 실패:', error);
      toast.error('상태 변경에 실패했습니다.');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // 현재 편집 중인 요소 확인
      const currentEditingCell = document.querySelector(`[data-user-id="${editingPatientId}"]`);
      const clickedElement = event.target;

      // 현재 편집 중인 셀 외부를 클릭한 경우에만 편집 모드 종료
      if (currentEditingCell && !currentEditingCell.contains(clickedElement)) {
        setEditingPatientId(null);
        setEditingPatientNumber('');
      }

      // 드롭다운 처리
      if (!event.target.closest('.status-dropdown')) {
        setActiveDropdown({ userId: null, type: null });
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [editingPatientId]);

  const handlePatientNumberEdit = async (userId, newNumber) => {
    try {
      // 빈 값이거나 undefined인 경우 처리하지 않음
      if (!userId || !newNumber) {
        return;
      }

      await makeApiRequest(`/api/users/${userId}`, {
        method: 'PUT',
        data: { hospital_patient_no: newNumber }
      });
      toast.success('환자 번호가 수정되었습니다.');
      loadUsers();
      setEditingPatientId(null);
      setEditingPatientNumber('');
    } catch (error) {
      console.error('환자 번호 수정 실패:', error);
      toast.error('환자 번호 수정에 실패했습니다.');
      // 에러 발생 시 편집 모드 종료
      setEditingPatientId(null);
      setEditingPatientNumber('');
    }
  };

  const toggleDropdown = (userId, type, e) => {
    e.preventDefault();
    e.stopPropagation();
    
    setActiveDropdown(prev => {
      if (prev.userId === userId && prev.type === type) {
        return { userId: null, type: null };
      }
      return { userId, type };
    });
  };

  const handleFieldUpdate = async (userId, field, value) => {
    try {
      // userId가 없으면 에러 처리
      if (!userId) {
        throw new Error('유효하지 않은 사용자입니다.');
      }

      // 필드 매핑
      const fieldMapping = {
        patientStatus: 'patient_status',
        hospitalPatientNo: 'hospital_patient_no'
      };

      // 업데이트할 데이터 준비
      const serverField = fieldMapping[field] || field;
      const updateData = {
        [serverField]: value
      };

      // patient_status를 업데이트할 때는 유효성 검사
      if (serverField === 'patient_status') {
        const validStatuses = ['waiting', 'reserved', 'outpatient', 'hospitalized', 'discharged'];
        if (!validStatuses.includes(value)) {
          throw new Error('유효하지 않은 환자 상태입니다.');
        }
      }

      console.log('Updating user:', userId, 'with data:', updateData);

      const response = await makeApiRequest(`/api/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: updateData
      });

      if (response.data.success) {
        toast.success('정보가 업데이트되었습니다.');
        await loadUsers(); // 목록 새로고침
      } else {
        throw new Error(response.data.message || '업데이트 실패');
      }
    } catch (error) {
      console.error('Update failed:', error);
      toast.error(error.message || '업데이트에 실패했습니다.');
    }
  };

  const handleTreatmentClick = (user, appointment) => {
    setSelectedTreatment({ 
      user_id: user.userId,
      appointment_id: appointment?.appointment_id,
      department_id: appointment?.department_id,
      doctor_id: appointment?.doctor_id,
      treatment_date: appointment?.appointment_date,
      treatment_time: appointment?.appointment_time,
      appointment_status: appointment?.status,
      patient_name: user.name,
      patient_number: user.hospital_patient_no,
      treatment_type: 'outpatient'
    });
    setShowForm(true);
  };

  // 단일 클릭 핸들러 추가
  const handleRowClick = (user) => {
    // 이미 선택된 환자를 다시 클릭하면 선택 해제
    if (selectedPatient?.userId === user.userId) {
      setSelectedPatient(null);
    } else {
      setSelectedPatient(user);
    }
  };

  // 더블 클릭 핸들러 수정
  const handleRowDoubleClick = (user) => {
    handlePatientSelect(user);
  };

  // 환자 선택 핸들러
  const handlePatientSelect = (user) => {
    setSelectedPatient(user);
  };

  // 진료 플로우 완료 핸들러
  const handleTreatmentComplete = async () => {
    await loadUsers();
    setSelectedPatient(null);
  };

  if (selectedPatient) {
    return (
      <PatientTreatmentFlow
        patient={selectedPatient}
        onBack={() => setSelectedPatient(null)}
        onComplete={handleTreatmentComplete}
      />
    );
  }

  return (
    <Container>
      <Header>
        <Title>진료 관리</Title>
        <ActionButton primary onClick={() => setShowForm(true)}>
          새 진료 등록
        </ActionButton>
      </Header>

      <SearchContainer>
        <SearchInput
          type="text"
          placeholder="환자 이름 또는 환자번호로 검색..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchContainer>

      {loading ? (
        <div>로딩 중...</div>
      ) : (
        <Table>
          <thead>
            <tr>
              <Th>환자명</Th>
              <Th>환자번호</Th>
              <Th>진료과</Th>
              <Th>담당의</Th>
              <Th>예약일시</Th>
              <Th>예약상태</Th>
              <Th>환자상태</Th>
              <Th>작업</Th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map(user => (
              <TableRow
                key={user.userId}
                onClick={() => handleRowClick(user)}
                onDoubleClick={(e) => {
                  if (e.target.closest('.editing')) {
                    return;
                  }
                  handleRowDoubleClick(user);
                }}
                style={{ 
                  backgroundColor: selectedPatient?.userId === user.userId ? '#f0f7ff' : 'inherit'
                }}
              >
                <Td>{user.name || '-'}</Td>
                <EditableField
                  value={user.hospital_patient_no}
                  onChange={(value) => handleFieldUpdate(user.userId, 'hospitalPatientNo', value)}
                  disabled={user.role_id !== 3}
                  data-user-id={user.userId}
                />
                <Td>{getAppointmentInfo(user).department_name || '-'}</Td>
                <Td>{getAppointmentInfo(user).doctor_name || '-'}</Td>
                <Td>
                  {getAppointmentInfo(user).formatted_date} {getAppointmentInfo(user).formatted_time}
                </Td>
                <Td>
                  <StatusBadge status={getAppointmentInfo(user).status || 'none'}>
                    {getAppointmentInfo(user).status ? getAppointmentStatusText(getAppointmentInfo(user).status) : '예약없음'}
                  </StatusBadge>
                </Td>
                <EditableField
                  value={user.patient_status || 'waiting'}
                  onChange={(value) => handleFieldUpdate(user.userId, 'patientStatus', value)}
                  type="select"
                  options={patientStatusOptions}
                  disabled={user.role_id !== 3}
                  data-user-id={user.userId}
                />
                <Td onClick={(e) => e.stopPropagation()}>
                  <ActionButtonGroup>
                    <ActionButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTreatmentClick(user, getAppointmentInfo(user));
                      }}
                    >
                      진료 {getAppointmentInfo(user).status ? '수정' : '등록'}
                    </ActionButton>
                  </ActionButtonGroup>
                </Td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}

      {showForm && (
        <TreatmentForm
          onClose={() => {
            setShowForm(false);
            setSelectedTreatment(null);
          }}
          onSubmit={handleTreatmentSubmit}
          initialData={selectedTreatment}
        />
      )}
    </Container>
  );
};

export default TreatmentList; 