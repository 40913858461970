import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StatCard = styled.div`
  padding: 16px;
  background: ${props => props.status === 'error' ? '#ffebee' : '#f5f7f9'};
  border-radius: 8px;
  border-left: 4px solid ${props => {
    switch (props.status) {
      case 'error': return '#ff3b30';
      case 'warning': return '#ff9500';
      case 'success': return '#34c759';
      default: return '#86868b';
    }
  }};
`;

const StatTitle = styled.div`
  font-size: 14px;
  color: #86868b;
  margin-bottom: 8px;
`;

const StatValue = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
`;

const ServerStats = () => {
  const [stats, setStats] = useState({
    cpu: { usage: 0, status: 'success' },
    memory: { usage: 0, status: 'success' },
    disk: { usage: 0, status: 'success' },
    uptime: { value: 0, status: 'success' }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchStats = async () => {
    try {
      const response = await makeApiRequest('/api/server/stats', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.success) {
        const data = response.data.data;
        setStats({
          cpu: {
            usage: data.cpuUsage,
            status: data.cpuUsage > 90 ? 'error' : data.cpuUsage > 70 ? 'warning' : 'success'
          },
          memory: {
            usage: data.memoryUsage,
            status: data.memoryUsage > 90 ? 'error' : data.memoryUsage > 70 ? 'warning' : 'success'
          },
          disk: {
            usage: data.diskUsage,
            status: data.diskUsage > 90 ? 'error' : data.diskUsage > 70 ? 'warning' : 'success'
          },
          uptime: {
            value: data.uptime,
            status: 'success'
          }
        });
      }
      setError(null);
    } catch (err) {
      setError('서버 상태 조회 실패');
      console.error('서버 상태 조회 오류:', err);
      // 에러 발생 시 기본값 설정
      setStats({
        cpu: { usage: 0, status: 'error' },
        memory: { usage: 0, status: 'error' },
        disk: { usage: 0, status: 'error' },
        uptime: { value: 0, status: 'error' }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
    const interval = setInterval(fetchStats, 10000);
    return () => clearInterval(interval);
  }, []);

  const formatUptime = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    return `${days}일 ${hours}시간 ${minutes}분`;
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (error) {
    return <div>에러: {error}</div>;
  }

  return (
    <StatsContainer>
      <StatCard status={stats.cpu.status}>
        <StatTitle>CPU 사용률11111</StatTitle>
        <StatValue>{stats.cpu.usage}%</StatValue>
      </StatCard>

      <StatCard status={stats.memory.status}>
        <StatTitle>메모리 사용률</StatTitle>
        <StatValue>{stats.memory.usage}%</StatValue>
      </StatCard>

      <StatCard status={stats.disk.status}>
        <StatTitle>디스크 사용률</StatTitle>
        <StatValue>{stats.disk.usage}%</StatValue>
      </StatCard>

      <StatCard status={stats.uptime.status}>
        <StatTitle>가동 시간</StatTitle>
        <StatValue>{formatUptime(stats.uptime.value)}</StatValue>
      </StatCard>
    </StatsContainer>
  );
};

export default ServerStats; 