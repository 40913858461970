import React from 'react';
import styled from 'styled-components';
import CodeBlock from '../common/CodeBlock';

const Section = styled.section`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  color: #1d1d1f;
  margin: 24px 0 16px;
`;

const Description = styled.p`
  color: #4b4b4b;
  line-height: 1.6;
  margin-bottom: 16px;
`;

const TableWrapper = styled.div`
  margin: 20px 0;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Th = styled.th`
  padding: 12px 16px;
  text-align: left;
  background: #f5f5f7;
  border-bottom: 1px solid #e1e1e1;
`;

const Td = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #e1e1e1;
`;

const FilterButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  background: ${props => props.$active ? '#007AFF' : 'white'};
  color: ${props => props.$active ? 'white' : '#1d1d1f'};
  cursor: pointer;
  
  &:hover {
    background: ${props => props.$active ? '#0056b3' : '#f5f5f7'};
  }
`;

const Overview = () => {
  return (
    <div>
      <Section>
        <Title>INTER MEDIC API 문서</Title>
        <Description>
          INTER MEDIC API는 의료 통역 서비스를 위한 종합 솔루션을 제공하는 RESTful API입니다.
          이 문서는 API의 모든 기능과 사용 방법을 상세히 설명합니다.
        </Description>

        <SubTitle>주요 기능</SubTitle>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>카테고리</Th>
                <Th>기능</Th>
                <Th>설명</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td>사용자 관리</Td>
                <Td>인증 및 권한</Td>
                <Td>
                  - JWT 기반 사용자 인증<br/>
                  - 역할 기반 접근 제어<br/>
                  - 다국어 지원
                </Td>
              </tr>
              <tr>
                <Td>실시간 채팅</Td>
                <Td>통역 서비스</Td>
                <Td>
                  - 실시간 메시지 교환<br/>
                  - 자동 번역 기능<br/>
                  - 채팅방 관리
                </Td>
              </tr>
              <tr>
                <Td>알림</Td>
                <Td>웹훅 & 이벤트</Td>
                <Td>
                  - 실시간 이벤트 알림<br/>
                  - 상태 변경 추적<br/>
                  - 커스텀 웹훅 설정
                </Td>
              </tr>
            </tbody>
          </Table>
        </TableWrapper>

        <SubTitle>시스템 구성</SubTitle>
        <CodeBlock
          language="plaintext"
          code={`
Client App ─── API Gateway ─┬─ Auth Service
                           ├─ Chat Service
                           ├─ Translation Service
                           └─ Notification Service
          `}
        />

        <SubTitle>기술 스택</SubTitle>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>구분</Th>
                <Th>기술</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td>백엔드</Td>
                <Td>
                  - Node.js<br/>
                  - Express.js<br/>
                  - MySQL<br/>
                  - WebSocket
                </Td>
              </tr>
              <tr>
                <Td>인증</Td>
                <Td>
                  - JWT<br/>
                  - OAuth 2.0<br/>
                  - HTTPS
                </Td>
              </tr>
              <tr>
                <Td>통신</Td>
                <Td>
                  - RESTful API<br/>
                  - WebSocket<br/>
                  - Server-Sent Events
                </Td>
              </tr>
            </tbody>
          </Table>
        </TableWrapper>

        <SubTitle>API 버전 정보</SubTitle>
        <Description>
          현재 API 버전: v1.0.0<br/>
          최소 호환 버전: v0.9.0<br/>
          업데이트 일자: 2024-03-20
        </Description>

        <SubTitle>시작하기</SubTitle>
        <CodeBlock
          language="bash"
          code={`
# Base URL
https://api.intermedic.com/v1

# API 키 인증 예시
curl -X GET "https://api.intermedic.com/v1/users" \
  -H "Authorization: Bearer YOUR_API_KEY"
          `}
        />

        <SubTitle>지원 및 문의</SubTitle>
        <Description>
          - 기술 문의: tech@intermedic.com<br/>
          - API 키 발급: https://developer.intermedic.com<br/>
          - 문서 버그 리포트: https://github.com/intermedic/api-docs/issues
        </Description>
      </Section>
    </div>
  );
};

export default Overview; 