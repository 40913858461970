import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
);

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const WidgetTitle = styled.h3`
  font-size: 18px;
  color: #1d1d1f;
  margin-bottom: 16px;
`;

const ChartContainer = styled.div`
  height: 300px;
`;

const ChartWidget = () => {
  const data = {
    labels: ['1월', '2월', '3월', '4월', '5월', '6월'],
    datasets: [
      {
        label: '외국인 환자 수',
        data: [65, 59, 80, 81, 56, 55],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom'
      }
    }
  };

  return (
    <Container>
      <WidgetTitle>외국인 환자 추이</WidgetTitle>
      <ChartContainer>
        <Line data={data} options={options} />
      </ChartContainer>
    </Container>
  );
};

export default ChartWidget; 