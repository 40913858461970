import React from 'react';
import styled from 'styled-components';
import CodeBlock from './CodeBlock';

const Section = styled.section`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const Description = styled.p`
  color: #4b4b4b;
  line-height: 1.6;
  margin-bottom: 16px;
`;

const EndpointCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  overflow: hidden;
`;

const EndpointHeader = styled.div`
  padding: 16px;
  background: #f8f9fa;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Method = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  min-width: 60px;
  text-align: center;
  
  &.GET {
    background: #e3f2fd;
    color: #1976d2;
  }
  &.POST {
    background: #e8f5e9;
    color: #388e3c;
  }
  &.PUT {
    background: #fff3e0;
    color: #f57c00;
  }
  &.DELETE {
    background: #ffebee;
    color: #d32f2f;
  }
`;

const EndpointPath = styled.code`
  font-family: 'Fira Code', monospace;
  font-size: 14px;
`;

const EndpointContent = styled.div`
  padding: 16px;
`;

const ParamTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 16px 0;
  font-size: 14px;
`;

const Th = styled.th`
  text-align: left;
  padding: 8px;
  background: #f5f5f7;
  border-bottom: 1px solid #e1e1e1;
`;

const Td = styled.td`
  padding: 8px;
  border-bottom: 1px solid #e1e1e1;
`;

const Required = styled.span`
  color: #dc3545;
  font-weight: 600;
`;

const ApiTable = () => {
  const endpoints = [
    {
      category: '인증',
      items: [
        {
          method: 'POST',
          path: '/api/auth/login',
          description: '사용자 로그인을 처리하고 JWT 토큰을 발급합니다.',
          auth: false,
          parameters: [
            { name: 'email', type: 'string', required: true, description: '사용자 이메일 주소' },
            { name: 'password', type: 'string', required: true, description: '비밀번호' }
          ],
          example: {
            request: `{
  "email": "user@example.com",
  "password": "SecurePass123!"
}`,
            response: `{
  "success": true,
  "data": {
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...",
    "user": {
      "userId": 1,
      "email": "user@example.com",
      "name": "홍길동",
      "roleId": 3,
      "phoneNumber": "1012345678",
      "phoneCode": "82",
      "countryId": 1,
      "preferredLanguage": "ko",
      "status": "active"
    }
  }
}`
          }
        },
        {
          method: 'POST',
          path: '/api/auth/logout',
          description: '현재 세션을 종료하고 토큰을 무효화합니다.',
          auth: true,
          parameters: [],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "message": "로그아웃되었습니다."
}`
          }
        },
        {
          method: 'GET',
          path: '/api/auth/check',
          description: '현재 인증 토큰의 유효성을 확인하고 사용자 정보를 반환합니다.',
          auth: true,
          parameters: [],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "data": {
    "userId": 1,
    "email": "user@example.com",
    "name": "홍길동",
    "preferredLanguage": "ko",
    "roleId": 3,
    "status": "active",
    "lastLogin": "2024-03-20T00:00:00Z"
  }
}`
          }
        }
      ]
    },
    {
      category: '관리자 기능',
      items: [
        {
          method: 'GET',
          path: '/api/admin/chats',
          description: '관리자 권한으로 활성화된 채팅방 목록(마지막 메시지 포함)을 조회합니다.',
          auth: true,
          parameters: [],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

// GET /api/admin/chats`,
            response: `{
  "success": true,
  "data": [
    {
      "roomId": "uuid-v4",
      "roomName": "사용자_1_chat",
      "roomType": "INDIVIDUAL",
      "creatorId": 1,
      "status": "in_progress",
      "createdAt": "2024-03-20T00:00:00Z",
      "updatedAt": "2024-03-20T00:10:00Z",
      "createdBy": 1,
      "title": "User_1_chat",
      "type": "consultation",
      "userName": "홍길동",
      "userEmail": "user@example.com",
      "userLanguage": "ko",
      "lastMessage": "안녕하세요?",
      "lastMessageTime": "2024-03-20T00:10:00Z",
      "lastMessageLanguage": "ko",
      "lastMessageTranslatedContent": null
    }
  ]
}`
          }
        },
        {
          method: 'GET',
          path: '/api/admin/chat-rooms',
          description: '관리자 권한으로 상태가 waiting인 채팅방 목록을 조회합니다.',
          auth: true,
          parameters: [],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

// GET /api/admin/chat-rooms`,
            response: `{
  "success": true,
  "data": [
    {
      "room_id": "uuid-v4",
      "room_name": "User_2_chat",
      "room_type": "INDIVIDUAL",
      "creator_id": 2,
      "status": "waiting",
      "created_by": 2,
      "title": "User_2_chat",
      "type": "consultation",
      "created_at": "2024-03-20T00:05:00Z",
      "updated_at": null,
      "creator_name": "김철수",
      "creator_email": "another@example.com",
      "creator_language": "en"
    }
  ]
}`
          }
        },
        {
          method: 'POST',
          path: '/api/chat/rooms/join',
          description: '관리자(운영자)가 특정 채팅방에 참여하고 상태를 in_progress로 변경합니다.',
          auth: true,
          parameters: [
            { name: 'roomId', type: 'string', required: true, description: '참여할 채팅방의 room_id' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

// Body
{
  "roomId": "uuid-v4"
}`,
            response: `{
  "success": true,
  "message": "관리자가 채팅방에 참여했습니다."
}`
          }
        }
      ]
    },
    {
      category: '채팅',
      items: [
        {
          method: 'GET',
          path: '/api/chat/user-room/:userId',
          description: '특정 사용자의 채팅방을 조회합니다. 존재하지 않으면 생성합니다.',
          auth: false, // 해당 라우트에서 authMiddleware가 없으므로 인증 불필요
          parameters: [
            { name: 'userId', type: 'number', required: true, description: '사용자 ID (경로 파라미터)' }
          ],
          example: {
            request: `GET /api/chat/user-room/1`,
            response: `{
  "success": true,
  "data": {
    "roomId": "uuid-v4",
    "created_at": "2024-03-20T00:00:00Z"
  }
}`
          }
        },
        {
          method: 'POST',
          path: '/api/chat/room',
          description: '새로운 채팅방을 생성합니다.',
          auth: true,
          parameters: [
            { name: 'userId', type: 'number', required: true, description: '채팅방 생성자 ID' },
            { name: 'title', type: 'string', required: true, description: '채팅방 제목' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

// Body
{
  "userId": 1,
  "title": "User_1_chat"
}`,
            response: `{
  "success": true,
  "data": {
    "room_id": "uuid-v4",
    "room_name": "User_1_chat",
    "room_type": "INDIVIDUAL",
    "creator_id": 1,
    "status": "waiting",
    "created_by": 1,
    "title": "User_1_chat",
    "type": "consultation",
    "created_at": "2024-03-20T00:00:00Z"
  }
}`
          }
        },
        {
          method: 'GET',
          path: '/api/chat/rooms/:userId',
          description: '특정 사용자가 만든(또는 참여한) 채팅방 목록을 조회합니다.',
          auth: true,
          parameters: [
            { name: 'userId', type: 'number', required: true, description: '사용자 ID (경로 파라미터)' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

GET /api/chat/rooms/1`,
            response: `{
  "success": true,
  "data": [
    {
      "room_id": "uuid-v4",
      "room_name": "User_1_chat",
      "room_type": "INDIVIDUAL",
      "creator_id": 1,
      "status": "in_progress",
      "created_by": 1,
      "title": "User_1_chat",
      "type": "consultation",
      "creator_name": "홍길동",
      "creator_language": "ko",
      "creator_role": 3,
      "created_at": "2024-03-20T00:00:00Z",
      "updated_at": "2024-03-20T00:10:00Z"
    }
  ]
}`
          }
        },
        {
          method: 'POST',
          path: '/api/chat/messages',
          description: '특정 채팅방에 메시지를 전송합니다. 번역 기능이 있으며, 참여자마다 선호 언어에 맞춰 번역이 저장됩니다.',
          auth: true,
          parameters: [
            { name: 'roomId', type: 'string', required: true, description: '메시지를 전송할 채팅방 ID' },
            { name: 'message', type: 'string', required: true, description: '전송할 메시지 내용' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

// Body
{
  "roomId": "uuid-v4",
  "message": "안녕하세요?"
}`,
            response: `{
  "success": true,
  "message": "메시지가 전송되었습니다.",
  "data": {
    "messageId": 10,
    "roomId": "uuid-v4",
    "senderId": 1,
    "senderName": "홍길동",
    "message": "안녕하세요?",
    "messageType": "TEXT",
    "content": "안녕하세요?",
    "translatedContent": {},
    "originalLanguage": "ko",
    "status": "active",
    "createdAt": "2024-03-20T00:10:00Z"
  }
}`
          }
        },
        {
          method: 'GET',
          path: '/api/chat/messages',
          description: '특정 채팅방의 메시지를 조회합니다. (최대 100개)',
          auth: true,
          parameters: [
            { name: 'roomId', type: 'string', required: true, description: '조회할 채팅방 ID (쿼리 파라미터)' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

// GET /api/chat/messages?roomId=uuid-v4`,
            response: `{
  "success": true,
  "data": [
    {
      "messageId": 10,
      "roomId": "uuid-v4",
      "senderId": 1,
      "message": "안녕하세요?",
      "senderName": "홍길동",
      "messageType": "TEXT",
      "content": "안녕하세요?",
      "translatedContent": {},
      "status": "active",
      "createdAt": "2024-03-20T00:10:00Z",
      "updatedAt": null,
      "originalLanguage": "ko"
    }
  ]
}`
          }
        }
      ]
    },
    {
      category: '사용자 관리',
      items: [
        {
          method: 'GET',
          path: '/api/users',
          description: '모든 활성/비활성 사용자를 조회합니다. (deleted 제외)',
          auth: true,
          parameters: [],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

GET /api/users`,
            response: `{
  "success": true,
  "data": [
    {
      "userId": 1,
      "email": "user@example.com",
      "name": "홍길동",
      "phone_code": "82",
      "phone_number": "1012345678",
      "country_id": 1,
      "preferred_language": "ko",
      "role_id": 3,
      "status": "active",
      "last_login": "2024-03-20T00:00:00Z",
      "created_at": "2024-03-20T00:00:00Z"
    }
  ]
}`
          }
        },
        {
          method: 'PUT',
          path: '/api/users/:id',
          description: '특정 사용자의 정보를 수정합니다.',
          auth: true,
          parameters: [
            { name: 'id', type: 'number', required: true, description: '수정할 사용자 ID (경로 파라미터)' },
            { name: 'name', type: 'string', required: false, description: '변경할 이름' },
            { name: 'phone_code', type: 'string', required: false, description: '변경할 국가 전화 코드' },
            { name: 'phone_number', type: 'string', required: false, description: '변경할 전화번호' },
            { name: 'preferred_language', type: 'string', required: false, description: '변경할 선호 언어' },
            { name: 'status', type: 'string', required: false, description: '변경할 상태 (active/inactive)' },
            { name: 'role_id', type: 'number', required: false, description: '변경할 역할 ID' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

// Body
{
  "name": "홍길순",
  "phone_code": "82",
  "phone_number": "1098765432",
  "preferred_language": "en",
  "status": "active",
  "role_id": 3
}`,
            response: `{
  "success": true,
  "message": "사용자 정보가 수정되었습니다."
}`
          }
        }
      ]
    },
    {
      category: '채팅 관리',
      items: [
        {
          method: 'PUT',
          path: '/api/chat/rooms/:roomId/status',
          description: '채팅방의 상태를 변경합니다.',
          auth: true,
          parameters: [
            { name: 'roomId', type: 'string', required: true, description: '상태를 변경할 채팅방 ID' },
            { name: 'status', type: 'string', required: true, description: '변경할 상태 (waiting/in_progress/completed/cancelled)' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

// Body
{
  "status": "in_progress"
}`,
            response: `{
  "success": true,
  "message": "채팅방 상태가 변경되었습니다.",
  "data": {
    "roomId": "uuid-v4",
    "status": "in_progress",
    "updatedAt": "2024-03-20T00:00:00Z"
  }
}`
          }
        },
        {
          method: 'POST',
          path: '/api/chat/rooms/:roomId/join',
          description: '채팅방에 참여합니다.',
          auth: true,
          parameters: [
            { name: 'roomId', type: 'string', required: true, description: '참여할 채팅방 ID' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "message": "채팅방에 참여했습니다.",
  "data": {
    "roomId": "uuid-v4",
    "userId": 1,
    "role": "member",
    "joinedAt": "2024-03-20T00:00:00Z"
  }
}`
          }
        }
      ]
    },
    {
      category: '번역 관리',
      items: [
        {
          method: 'POST',
          path: '/api/translations/message',
          description: '메시지를 지정된 언어로 번역합니다.',
          auth: true,
          parameters: [
            { name: 'messageId', type: 'string', required: true, description: '번역할 메시지 ID' },
            { name: 'targetLanguage', type: 'string', required: true, description: '번역할 목표 언어 코드' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}

// Body
{
  "messageId": "msg-123",
  "targetLanguage": "en"
}`,
            response: `{
  "success": true,
  "data": {
    "messageId": "msg-123",
    "originalContent": "안녕하세요",
    "translatedContent": "Hello",
    "sourceLanguage": "ko",
    "targetLanguage": "en"
  }
}`
          }
        }
      ]
    },
    {
      category: '시스템 설정',
      items: [
        {
          method: 'GET',
          path: '/api/settings/languages',
          description: '지원되는 언어 목록을 조회합니다.',
          auth: true,
          parameters: [],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "data": {
    "languages": [
      {
        "code": "ko",
        "name": "한국어",
        "status": "active"
      },
      {
        "code": "en",
        "name": "English",
        "status": "active"
      },
      {
        "code": "ja",
        "name": "日本語",
        "status": "active"
      }
    ]
  }
}`
          }
        },
        {
          method: 'GET',
          path: '/api/settings/countries',
          description: '지원되는 국가 목록을 조회합니다.',
          auth: true,
          parameters: [],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "data": {
    "countries": [
      {
        "id": 1,
        "name": "대한민국",
        "code": "KR",
        "phoneCode": "82",
        "defaultLanguage": "ko"
      },
      {
        "id": 2,
        "name": "日本",
        "code": "JP",
        "phoneCode": "81",
        "defaultLanguage": "ja"
      }
    ]
  }
}`
          }
        }
      ]
    },
    {
      category: '채팅방 관리',
      items: [
        {
          method: 'GET',
          path: '/api/chat/rooms/:roomId',
          description: '특정 채팅방의 상세 정보를 조회합니다.',
          auth: true,
          parameters: [
            { name: 'roomId', type: 'string', required: true, description: '채팅방 ID' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "data": {
    "room": {
      "roomId": "room-123",
      "roomName": "의료 상담",
      "roomType": "direct",
      "status": "in_progress",
      "createdAt": "2024-03-20T00:00:00Z",
      "participants": [
        {
          "userId": 1,
          "name": "홍길동",
          "role": "patient",
          "language": "ko"
        },
        {
          "userId": 2,
          "name": "의사",
          "role": "doctor",
          "language": "en"
        }
      ]
    }
  }
}`
          }
        },
        {
          method: 'DELETE',
          path: '/api/chat/rooms/:roomId',
          description: '채팅방을 삭제합니다.',
          auth: true,
          parameters: [
            { name: 'roomId', type: 'string', required: true, description: '삭제할 채팅방 ID' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "message": "채팅방이 삭제되었습니다."
}`
          }
        }
      ]
    },
    {
      category: '알림 관리',
      items: [
        {
          method: 'GET',
          path: '/api/notifications',
          description: '사용자의 알림 목록을 조회합니다.',
          auth: true,
          parameters: [
            { name: 'page', type: 'number', required: false, description: '페이지 번호' },
            { name: 'limit', type: 'number', required: false, description: '페이지당 알림 수' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "data": {
    "notifications": [
      {
        "id": "notif-123",
        "type": "chat_message",
        "title": "새 메시지",
        "content": "새로운 메시지가 도착했습니다.",
        "roomId": "room-123",
        "read": false,
        "createdAt": "2024-03-20T00:00:00Z"
      }
    ],
    "pagination": {
      "total": 50,
      "page": 1,
      "limit": 20
    }
  }
}`
          }
        },
        {
          method: 'PUT',
          path: '/api/notifications/:notificationId/read',
          description: '알림을 읽음 처리합니다.',
          auth: true,
          parameters: [
            { name: 'notificationId', type: 'string', required: true, description: '알림 ID' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "message": "알림이 읽음 처리되었습니다."
}`
          }
        }
      ]
    },
    {
      category: '파일 관리',
      items: [
        {
          method: 'POST',
          path: '/api/files/upload',
          description: '파일을 업로드합니다.',
          auth: true,
          parameters: [
            { name: 'file', type: 'file', required: true, description: '업로드할 파일' },
            { name: 'type', type: 'string', required: true, description: '파일 타입 (chat_image/chat_file)' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}
// Form Data
{
  "file": "(binary)",
  "type": "chat_image"
}`,
            response: `{
  "success": true,
  "data": {
    "fileId": "file-123",
    "fileName": "image.jpg",
    "fileType": "image/jpeg",
    "fileSize": 1024,
    "fileUrl": "https://example.com/files/image.jpg",
    "uploadedAt": "2024-03-20T00:00:00Z"
  }
}`
          }
        },
        {
          method: 'GET',
          path: '/api/files/:fileId',
          description: '파일 정보를 조회합니다.',
          auth: true,
          parameters: [
            { name: 'fileId', type: 'string', required: true, description: '파일 ID' }
          ],
          example: {
            request: `// Headers
{
  "Authorization": "Bearer your_jwt_token"
}`,
            response: `{
  "success": true,
  "data": {
    "fileId": "file-123",
    "fileName": "image.jpg",
    "fileType": "image/jpeg",
    "fileSize": 1024,
    "fileUrl": "https://example.com/files/image.jpg",
    "uploadedAt": "2024-03-20T00:00:00Z",
    "uploadedBy": 1
  }
}`
          }
        }
      ]
    }
  ];

  return (
    <div>
      {endpoints.map((category, index) => (
        <Section key={index}>
          <Title>{category.category}</Title>
          {category.items.map((endpoint, i) => (
            <EndpointCard key={i}>
              <EndpointHeader>
                <Method className={endpoint.method}>{endpoint.method}</Method>
                <EndpointPath>{endpoint.path}</EndpointPath>
              </EndpointHeader>
              <EndpointContent>
                <Description>{endpoint.description}</Description>
                {endpoint.auth && (
                  <Description>
                    <strong>인증 필요:</strong> Bearer 토큰이 필요합니다.
                  </Description>
                )}
                
                <h4>파라미터</h4>
                <ParamTable>
                  <thead>
                    <tr>
                      <Th>이름</Th>
                      <Th>타입</Th>
                      <Th>필수</Th>
                      <Th>설명</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {endpoint.parameters.map((param, j) => (
                      <tr key={j}>
                        <Td>{param.name}</Td>
                        <Td>{param.type}</Td>
                        <Td>
                          {param.required && <Required>필수</Required>}
                          {!param.required && '선택'}
                        </Td>
                        <Td>{param.description}</Td>
                      </tr>
                    ))}
                  </tbody>
                </ParamTable>

                <h4>요청 예시</h4>
                <CodeBlock
                  language="javascript"
                  code={endpoint.example.request}
                />

                <h4>응답 예시</h4>
                <CodeBlock
                  language="javascript"
                  code={endpoint.example.response}
                />
              </EndpointContent>
            </EndpointCard>
          ))}
        </Section>
      ))}
    </div>
  );
};

export default ApiTable;
