import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import ReactFlow, { 
  MiniMap, 
  Controls, 
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  Handle
} from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import styled from 'styled-components';
import axios from 'axios';
import { makeApiRequest } from '../../../utils/apiUtils';

// 스타일 컴포넌트 정의
const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  font-size: 20px;
  margin: 20px 0 10px;
`;

const Section = styled.section`
  margin: 30px 0;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  margin: 20px 0;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #f5f5f5;
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #666;
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  padding: 20px;
  text-align: center;
`;

const TableSectionWrapper = styled.div`
  margin: 30px 0;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 8px;
  background: white;
`;

const TableTitle = styled.h4`
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
`;

const RefreshButton = styled.button`
  padding: 8px 16px;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3182ce;
  }

  &:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

// API URL을 환경변수로 관리
const API_URL = process.env.REACT_APP_API_URL;

// axios 인스턴스 생성
const api = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// TableNode 컴포넌트 정의
const TableNode = memo(({ data }) => {
  return (
    <div className="table-node">
      <Handle type="target" position="left" />
      <div className="table-frame">
        <div className="table-header">
          <div className="table-name">{data.label}</div>
          <div className="database-name">{data.database}</div>
        </div>
        
        <div className="columns-container">
          <table className="columns-table">
            <thead>
              <tr>
                <th width="35%">컬럼명</th>
                <th width="35%">타입</th>
                <th width="15%">Null</th>
                <th width="15%">키</th>
              </tr>
            </thead>
            <tbody>
              {data.columns.map((col, i) => (
                <tr 
                  key={i} 
                  className={`
                    ${col.key === 'PRI' ? 'primary-key-row' : ''}
                    ${col.key === 'MUL' ? 'foreign-key-row' : ''}
                    ${col.key === 'UNI' ? 'unique-key-row' : ''}
                  `}
                >
                  <td className="column-name">
                    <span className="column-name-text">{col.name}</span>
                  </td>
                  <td className="column-type">{col.type}</td>
                  <td className="column-null">
                    {col.nullable ? 'YES' : 'NO'}
                  </td>
                  <td className="column-key">
                    <span className={`key-badge ${col.key?.toLowerCase() || 'none'}`}>
                      {col.key === 'PRI' && 'PK'}
                      {col.key === 'UNI' && 'UK'}
                      {col.key === 'MUL' && 'FK'}
                      {!col.key && '-'}
                    </span>
                  </td>
                  {col.key === 'MUL' && (
                    <Handle
                      type="source"
                      position="right"
                      id={`${data.label}-${col.name}`}
                      style={{ top: `${(i * 32) + 64}px` }}
                    />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

// 스타일 컴포넌트 수정
const TableNodeStyles = styled.div`
  .table-node {
    padding: 8px;
    background: transparent;
  }

  .table-frame {
    border: 2px solid #4a5568;
    border-radius: 8px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 480px;
    overflow: hidden;
    position: relative;
  }

  .table-header {
    background: #63B3ED;  // 하늘색 배경
    color: white;
    padding: 12px 16px;
    border-bottom: 2px solid #4299E1;
  }

  .table-name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #fff;
    text-shadow: 1px 1px 0 rgba(0,0,0,0.1);
  }

  .database-name {
    font-size: 12px;
    color: #EBF8FF;
    font-weight: 500;
  }

  .columns-container {
    max-height: 400px;
    overflow-y: auto;
    background: #fff;
    border-radius: 0 0 6px 6px;
  }

  .columns-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
  }

  .columns-table th {
    background: #EBF8FF;
    padding: 10px;
    text-align: left;
    font-weight: 600;
    color: #2C5282;
    border-bottom: 2px solid #BEE3F8;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .columns-table td {
    padding: 8px 10px;
    border-bottom: 1px solid #EBF8FF;
  }

  .primary-key-row {
    background: #EBF8FF;
  }

  .foreign-key-row {
    background: #F0FFF4;
  }

  .unique-key-row {
    background: #FFF5F5;
  }

  .column-name {
    font-weight: 500;
    color: #2D3748;
  }

  .column-name-text {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .column-type {
    color: #4A5568;
    font-family: monospace;
  }

  .column-null {
    text-align: center;
    color: #718096;
  }

  .key-badge {
    display: inline-block;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    min-width: 24px;
  }

  .key-badge.pri {
    background: #EBF8FF;
    color: #2B6CB0;
    border: 1px solid #BEE3F8;
  }

  .key-badge.mul {
    background: #F0FFF4;
    color: #2F855A;
    border: 1px solid #C6F6D5;
  }

  .key-badge.uni {
    background: #FFF5F5;
    color: #C53030;
    border: 1px solid #FED7D7;
  }

  /* 스크롤바 스타일링 */
  .columns-container::-webkit-scrollbar {
    width: 6px;
  }

  .columns-container::-webkit-scrollbar-track {
    background: #EBF8FF;
  }

  .columns-container::-webkit-scrollbar-thumb {
    background: #90CDF4;
    border-radius: 3px;
  }
`;

// MiniMap 스타일링을 위한 함수 추가
const getMiniMapNodeColor = (node) => {
  switch (node.type) {
    case 'tableNode':
      return '#2b6cb0';  // 테이블 노드 색상
    default:
      return '#666';     // 기본 노드 색상
  }
};

const getMiniMapNodeStrokeColor = (node) => {
  switch (node.type) {
    case 'tableNode':
      return '#4299e1';  // 테이블 노드 테두리 색상
    default:
      return '#999';     // 기본 테두리 색상
  }
};

// 새로운 스타일 컴포넌트 추가
const DetailSection = styled.div`
  margin: 15px 0;
  padding: 15px;
  background-color: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
`;

const DetailTitle = styled.h5`
  font-size: 16px;
  color: #2d3748;
  margin-bottom: 12px;
  font-weight: 600;
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 15px;
`;

const DetailItem = styled.div`
  font-size: 14px;
  color: #4a5568;
  
  strong {
    color: #2d3748;
    margin-right: 8px;
  }
`;

const IndexSection = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e2e8f0;
`;

const RelationshipSection = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e2e8f0;
`;

// TableDetails 컴포넌트 수정
const TableDetails = ({ node }) => {
  const { data } = node;
  
  return (
    <TableSectionWrapper>
      <TableTitle>{data.label}</TableTitle>
      
      {/* 테이블 정보 섹션 */}
      <DetailSection>
        <DetailTitle>테이블 정보</DetailTitle>
        <DetailGrid>
          <DetailItem>
            <strong>엔진:</strong> {data.info?.engine || 'N/A'}
          </DetailItem>
          <DetailItem>
            <strong>문자셋:</strong> {data.info?.collation || 'N/A'}
          </DetailItem>
          <DetailItem>
            <strong>레코드 수:</strong> {data.info?.rowCount?.toLocaleString() ?? 'N/A'}
          </DetailItem>
          <DetailItem>
            <strong>생성일:</strong> {data.info?.createTime ? new Date(data.info.createTime).toLocaleString() : 'N/A'}
          </DetailItem>
          <DetailItem>
            <strong>수정일:</strong> {data.info?.updateTime ? new Date(data.info.updateTime).toLocaleString() : 'N/A'}
          </DetailItem>
        </DetailGrid>
      </DetailSection>

      {/* 컬럼 정보 테이블 */}
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th>컬럼명</Th>
              <Th>타입</Th>
              <Th>Null</Th>
              <Th>키</Th>
              <Th>기본값</Th>
              <Th>추가 속성</Th>
              <Th>설명</Th>
            </tr>
          </thead>
          <tbody>
            {data.columns.map((col, index) => (
              <tr key={index}>
                <Td>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {col.key === 'PRI' && <span className="key-badge pri">PK</span>}
                    {col.key === 'MUL' && <span className="key-badge mul">FK</span>}
                    {col.key === 'UNI' && <span className="key-badge uni">UK</span>}
                    {col.name}
                  </div>
                </Td>
                <Td>{col.type}</Td>
                <Td>{col.nullable ? 'YES' : 'NO'}</Td>
                <Td>
                  {col.key === 'PRI' && 'Primary Key'}
                  {col.key === 'MUL' && 'Foreign Key'}
                  {col.key === 'UNI' && 'Unique Key'}
                  {!col.key && '-'}
                </Td>
                <Td>{col.default || '-'}</Td>
                <Td>{col.extra || '-'}</Td>
                <Td>{col.comment || '-'}</Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </TableSectionWrapper>
  );
};

// nodeTypes를 컴포넌트 외부로 이동
const nodeTypes = {
  tableNode: TableNode
};

const FlowContainer = styled.div`
  height: 800px;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  background: #F7FAFC;
  
  @media (max-width: 768px) {
    height: 500px;
  }

  .react-flow__node {
    width: 500px;
    
    @media (max-width: 768px) {
      width: 380px;
    }
  }

  .react-flow__handle {
    width: 8px;
    height: 8px;
    background: #4299E1;
    border: 2px solid #fff;
  }

  .react-flow__edge-path {
    stroke-width: 2;
  }

  .react-flow__edge-text {
    font-size: 11px;
  }
`;

// 엣지 스타일 설정 수정
const getEdgeStyle = (relationship) => {
  return {
    strokeWidth: 2,
    stroke: '#4299E1',
    markerEnd: { 
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: '#4299E1'
    },
    labelBgStyle: { 
      fill: '#fff',
      padding: 8,
      borderRadius: 4,
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      border: '1px solid #E2E8F0'
    },
    labelStyle: { 
      fill: '#2D3748', 
      fontSize: 11,
      fontWeight: 500
    }
  };
};

// analyzeRelationships 함수 수정
const analyzeRelationships = (schema) => {
  const relationships = [];

  Object.entries(schema).forEach(([schemaName, tables]) => {
    Object.entries(tables).forEach(([tableName, tableData]) => {
      // columns 배열에 접근하도록 수정
      tableData.columns.forEach(column => {
        if (column.key === 'MUL') {  // Foreign Key 확인
          // 참조 테이블 이름 추정 (예: user_id -> users)
          const referencedTable = column.name.replace('_id', 's');
          
          relationships.push({
            source: `${schemaName}.${tableName}`,
            target: `${schemaName}.${referencedTable}`,
            sourceHandle: `${tableName}-${column.name}`,
            label: `${tableName}.${column.name} → ${referencedTable}.id`,
            data: {
              sourceField: column.name,
              targetField: 'id',
              relationship: 'FOREIGN KEY'
            }
          });
        }
      });
    });
  });

  return relationships;
};

// Database 컴포넌트
const Database = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [renderTime, setRenderTime] = useState(null);

  // processData 함수를 컴포넌트 내부로 이동
  const processData = useCallback((dbData) => {
    const nodes = [];
    const edges = [];
    let yOffset = 0;
    const HORIZONTAL_SPACING = 500;
    const VERTICAL_SPACING = 450;
    const MAX_COLUMNS_PER_ROW = 3;

    Object.entries(dbData).forEach(([schemaName, schema]) => {
      let xOffset = 0;
      let columnCount = 0;
      
      Object.entries(schema).forEach(([tableName, tableData]) => {
        nodes.push({
          id: `${schemaName}.${tableName}`,
          type: 'tableNode',
          position: { x: xOffset, y: yOffset },
          data: {
            label: tableName,
            database: schemaName,
            info: tableData.info,
            columns: tableData.columns.map(col => ({
              ...col,
              nullable: col.nullable === 'YES'
            }))
          }
        });

        columnCount++;
        if (columnCount >= MAX_COLUMNS_PER_ROW) {
          columnCount = 0;
          xOffset = 0;
          yOffset += VERTICAL_SPACING;
        } else {
          xOffset += HORIZONTAL_SPACING;
        }
      });

      // 관계 분석 및 엣지 생성
      const relationships = analyzeRelationships({ [schemaName]: schema });
      edges.push(...relationships.map((rel, index) => ({
        id: `edge-${index}`,
        ...rel,
        ...getEdgeStyle(rel)
      })));
    });

    return { nodes, edges };
  }, []);

  // fetchDatabaseStructure 함수
  const fetchDatabaseStructure = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      const startTime = performance.now();
      const response = await makeApiRequest('/api/database/structure');
      
      if (response?.data?.success) {
        const { nodes, edges } = processData(response.data.data);
        setNodes(nodes);
        setEdges(edges);
        setLastUpdated(new Date().toLocaleString());
        setRenderTime(performance.now() - startTime);
      } else {
        throw new Error('데이터베이스 구조를 불러오는데 실패했습니다.');
      }
    } catch (error) {
      console.error('DB 구조 조회 실패:', error);
      setError(error.message || '데이터베이스 구조를 불러오는데 실패했습니다.');
      setNodes([]);
      setEdges([]);
    } finally {
      setLoading(false);
    }
  }, [processData, setNodes, setEdges]);

  // 초기 데이터 로딩
  useEffect(() => {
    fetchDatabaseStructure();
  }, [fetchDatabaseStructure]);

  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <div>
      <HeaderSection>
        <Title>데이터베이스 구조</Title>
        <RefreshButton onClick={fetchDatabaseStructure} disabled={loading}>
          {loading ? '새로고침 중...' : '새로고침'}
        </RefreshButton>
      </HeaderSection>

      <Section>
        <SubTitle>ERD (Entity Relationship Diagram)</SubTitle>
        {loading ? (
          <LoadingMessage>데이터베이스 구조를 불러오는 중...</LoadingMessage>
        ) : (
          <FlowContainer>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              fitView
              fitViewOptions={{ padding: 0.3 }}
              minZoom={0.1}
              maxZoom={2}
              defaultEdgeOptions={{
                type: 'smoothstep',
                animated: true,
              }}
            >
              <Controls />
              <MiniMap 
                nodeColor={getMiniMapNodeColor}
                nodeStrokeColor={getMiniMapNodeStrokeColor}
                zoomable
                pannable
              />
              <Background gap={16} color="#aaa" />
            </ReactFlow>
          </FlowContainer>
        )}
      </Section>

      {/* 테이블 상세 정보 섹션 */}
      <Section>
        <SubTitle>테이블 상세 정보</SubTitle>
        {nodes.map(node => (
          <TableDetails key={node.id} node={node} />
        ))}
      </Section>
    </div>
  );
};

export default Database; 