import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';
import ConsultationForm from './ConsultationForm';
import ChatRoom from '../chat/ChatRoom';

const Container = styled.div`
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1a1a1a;
  margin: 0;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
`;

const Th = styled.th`
  padding: 12px;
  background: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  text-align: left;
  font-weight: 600;
  color: #495057;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
`;

const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  
  ${props => {
    switch (props.status) {
      case 'completed':
        return 'background: #E3F2FD; color: #1976D2; border: 1px solid #1976D2;';
      case 'in_progress':
        return 'background: #FFF3E0; color: #E65100; border: 1px solid #E65100;';
      case 'pending':
        return 'background: #F5F5F5; color: #616161; border: 1px solid #616161;';
      default:
        return 'background: #FFEBEE; color: #C62828; border: 1px solid #C62828;';
    }
  }}
`;

const ActionButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const ConsultationContent = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ChatModal = styled.div`
  position: fixed;
  top: ${props => props.position.y}px;
  left: ${props => props.position.x}px;
  z-index: 1000;
`;

const LanguageBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  
  ${props => props.disabled ? `
    background: #F5F5F5;
    color: #616161;
    border: 1px solid #616161;
  ` : `
    background: #E3F2FD;
    color: #1976D2;
    border: 1px solid #1976D2;
  `}
`;

const ConsultationList = () => {
  const [consultations, setConsultations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedConsultation, setSelectedConsultation] = useState(null);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [chatRooms, setChatRooms] = useState([]);
  const [positions, setPositions] = useState({});

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        // 사용자 정보 로드
        const userResponse = await makeApiRequest('/api/users/me');
        if (userResponse.data.success) {
          console.log('현재 사용자 정보:', userResponse.data.data);
          setCurrentUser(userResponse.data.data);
        }
        
        // 상담 목록 로드
        await loadConsultations();
      } catch (error) {
        console.error('초기화 실패:', error);
        toast.error('데이터 로드에 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  const loadConsultations = async () => {
    try {
      setLoading(true);
      const response = await makeApiRequest('/api/chat/admin/chat-rooms');
      
      if (response.data.success) {
        const consultationData = response.data.data.map(chat => ({
          consultation_id: chat.roomId,
          patient_name: chat.userName || '알 수 없음',
          patient_number: chat.userEmail || '-',
          consultation_date: new Date(chat.createdAt || Date.now()).toISOString().split('T')[0],
          consultation_time: new Date(chat.createdAt || Date.now()).toTimeString().slice(0,5),
          consultation_type: 'online',
          subject: chat.title || '상담 요청',
          content: chat.lastMessage || '',
          status: mapChatStatusToConsultation(chat.status || 'waiting'),
          priority: chat.priority || 'normal',
          translator_required: chat.userLanguage && chat.userLanguage !== 'ko',
          preferred_language: chat.userLanguage || 'ko',
          response: chat.response || ''
        }));
        
        setConsultations(consultationData);
      }
    } catch (error) {
      toast.error('상담 목록을 불러오는데 실패했습니다.');
      setConsultations([]);
    } finally {
      setLoading(false);
    }
  };

  const mapChatStatusToConsultation = (chatStatus) => {
    switch (chatStatus) {
      case 'waiting': return 'pending';
      case 'in_progress': return 'in_progress';
      case 'closed': return 'completed';
      default: return 'pending';
    }
  };

  const handleConsultationSubmit = async (formData) => {
    try {
      if (selectedConsultation) {
        // 기존 상담 수정 - 채팅방 상태 업데이트
        await makeApiRequest(`/api/chat/rooms/${selectedConsultation.consultation_id}/status`, {
          method: 'PUT',
          data: {
            status: mapConsultationStatusToChat(formData.status)
          }
        });

        // 답변이 있는 경우 메시지 전송
        if (formData.response && formData.response !== selectedConsultation.response) {
          await makeApiRequest('/api/chat/messages', {
            method: 'POST',
            data: {
              roomId: selectedConsultation.consultation_id,
              message: formData.response,
              messageType: 'text'
            }
          });
        }
      } else {
        // 새 상담 생성 - 새 채팅방 생성
        await makeApiRequest('/api/chat/rooms', {
          method: 'POST',
          data: {
            title: formData.subject,
            type: 'individual',
            userId: formData.user_id || null,
            initialMessage: formData.content
          }
        });
      }
      
      // 상담 목록 새로고침
      loadConsultations();
    } catch (error) {
      console.error('상담 처리 실패:', error);
      toast.error('상담 처리에 실패했습니다.');
      throw error;
    }
  };

  // 상담 상태를 채팅 상태로 매핑하는 함수 추가
  const mapConsultationStatusToChat = (consultationStatus) => {
    switch (consultationStatus) {
      case 'pending': return 'waiting';
      case 'in_progress': return 'in_progress';
      case 'completed': return 'closed';
      default: return 'waiting';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'completed': return '답변완료';
      case 'in_progress': return '답변중';
      case 'pending': return '답변대기';
      default: return '취소';
    }
  };

  const getConsultationTypeText = (type) => {
    switch (type) {
      case 'online': return '온라인 상담';
      case 'phone': return '전화 상담';
      case 'video': return '화상 상담';
      case 'in_person': return '대면 상담';
      default: return '기타';
    }
  };

  const getPriorityText = (priority) => {
    switch (priority) {
      case 'urgent': return '긴급';
      case 'high': return '높음';
      case 'normal': return '보통';
      case 'low': return '낮음';
      default: return '보통';
    }
  };

  const getLanguageText = (code) => {
    const languageMap = {
      'ar': '아랍어',
      'de': '독일어',
      'en': '영어',
      'es': '스페인어',
      'fr': '프랑스어',
      'he': '히브리어',
      'hi': '힌디어',
      'it': '이탈리아어',
      'ja': '일본어',
      'ko': '한국어',
      'nl': '네덜란드어',
      'pt': '포르투갈어',
      'ru': '러시아어',
      'zh': '중국어'
    };
    return languageMap[code] || code;
  };

  const handleChatClick = (consultation) => {
    if (!chatRooms.includes(consultation.consultation_id)) {
      setChatRooms(prev => [...prev, consultation.consultation_id]);
      // 채팅방 정보에 상대방 정보 추가
      const chatInfo = {
        roomId: consultation.consultation_id,
        patientName: consultation.patient_name,
        patientLanguage: consultation.preferred_language,
        translatorRequired: consultation.translator_required
      };
      
      setPositions(prev => ({
        ...prev,
        [consultation.consultation_id]: {
          x: 50 + (chatRooms.length * 30),
          y: 50 + (chatRooms.length * 30),
          chatInfo: chatInfo  // 채팅 정보 저장
        }
      }));
    }
  };

  const handleDragStart = (e, roomId) => {
    e.stopPropagation();
    
    const rect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    
    setPositions(prev => ({
      ...prev,
      [roomId]: {
        ...prev[roomId],
        offsetX,
        offsetY
      }
    }));
  };

  const handleDrag = (e, roomId) => {
    if (!e.clientX || !e.clientY) return;

    const { offsetX, offsetY } = positions[roomId] || { offsetX: 0, offsetY: 0 };
    
    setPositions(prev => ({
      ...prev,
      [roomId]: {
        ...prev[roomId],
        x: e.clientX - offsetX,
        y: e.clientY - offsetY,
        offsetX,
        offsetY
      }
    }));
  };

  return (
    <Container>
      <Header>
        <Title>1:1 상담</Title>
        <ActionButton 
          primary 
          onClick={() => {
            setSelectedConsultation(null);
            setShowForm(true);
          }}
        >
          새 상담 등록
        </ActionButton>
      </Header>

      {loading ? (
        <div>로딩 중...</div>
      ) : (
        <Table>
          <thead>
            <tr>
              <Th>상담 일시</Th>
              <Th>환자명</Th>
              <Th>상담 유형</Th>
              <Th>제목</Th>
              <Th>내용</Th>
              <Th>상태</Th>
              <Th>우선순위</Th>
              <Th>통역(언어)</Th>
              <Th>작업</Th>
            </tr>
          </thead>
          <tbody>
            {consultations.map(consultation => (
              <tr key={consultation.consultation_id}>
                <Td>{`${consultation.consultation_date} ${consultation.consultation_time}`}</Td>
                <Td>{consultation.patient_name}</Td>
                <Td>{getConsultationTypeText(consultation.consultation_type)}</Td>
                <Td>
                  <ConsultationContent>{consultation.subject}</ConsultationContent>
                </Td>
                <Td>
                  <ConsultationContent>{consultation.content}</ConsultationContent>
                </Td>
                <Td>
                  <StatusBadge status={consultation.status}>
                    {getStatusText(consultation.status)}
                  </StatusBadge>
                </Td>
                <Td>{getPriorityText(consultation.priority)}</Td>
                <Td>
                  {consultation.translator_required ? (
                    <LanguageBadge language={consultation.preferred_language}>
                      필요 ({getLanguageText(consultation.preferred_language)})
                    </LanguageBadge>
                  ) : (
                    <LanguageBadge disabled>불필요</LanguageBadge>
                  )}
                </Td>
                <Td>
                  <ActionButtonGroup>
                    <ActionButton
                      onClick={() => handleChatClick(consultation)}
                    >
                      채팅하기
                    </ActionButton>
                    <ActionButton
                      onClick={() => {
                        setSelectedConsultation(consultation);
                        setShowForm(true);
                      }}
                    >
                      상세/답변
                    </ActionButton>
                  </ActionButtonGroup>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {showForm && (
        <ConsultationForm
          onClose={() => {
            setShowForm(false);
            setSelectedConsultation(null);
          }}
          onSubmit={handleConsultationSubmit}
          initialData={selectedConsultation}
        />
      )}

      {chatRooms.map(roomId => (
        <ChatModal
          key={roomId}
          position={positions[roomId] || { x: 50, y: 50 }}
        >
          <ChatRoom
            roomId={roomId}
            chatInfo={positions[roomId]?.chatInfo}  // 채팅 정보 전달
            onClose={() => {
              setChatRooms(prev => prev.filter(id => id !== roomId));
              setPositions(prev => {
                const newPositions = { ...prev };
                delete newPositions[roomId];
                return newPositions;
              });
            }}
            currentUser={currentUser}
            onDragStart={(e) => handleDragStart(e, roomId)}
            onDrag={(e) => handleDrag(e, roomId)}
          />
        </ChatModal>
      ))}
    </Container>
  );
};

export default ConsultationList; 