import React from 'react';
import styled from 'styled-components';

const AppointmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const AppointmentCard = styled.div`
  padding: 16px;
  background: #f5f7f9;
  border-radius: 8px;
  border-left: 4px solid ${props => props.status === 'confirmed' ? '#34c759' : '#ff9500'};
`;

const Time = styled.div`
  font-size: 14px;
  color: #86868b;
  margin-bottom: 8px;
`;

const PatientName = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const Details = styled.div`
  font-size: 14px;
  color: #666;
`;

const Badge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 8px;
  background: ${props => props.type === 'interpreter' ? '#e3f2fd' : '#fff3e0'};
  color: ${props => props.type === 'interpreter' ? '#1976d2' : '#f57c00'};
`;

const TodayAppointments = () => {
  const appointments = [
    {
      id: 1,
      time: '09:30',
      patientName: 'John Smith',
      department: '정형외과',
      status: 'confirmed',
      interpreter: '영어',
      doctor: 'Dr. 김민수'
    },
    {
      id: 2,
      time: '10:15',
      patientName: 'Maria Garcia',
      department: '치과',
      status: 'pending',
      interpreter: '스페인어',
      doctor: 'Dr. 이지원'
    },
    {
      id: 3,
      time: '11:00',
      patientName: 'Liu Wei',
      department: '내과',
      status: 'confirmed',
      interpreter: '중국어',
      doctor: 'Dr. 박준영'
    }
  ];

  return (
    <AppointmentList>
      {appointments.map(appointment => (
        <AppointmentCard key={appointment.id} status={appointment.status}>
          <Time>{appointment.time}</Time>
          <PatientName>
            {appointment.patientName}
            <Badge type="interpreter">{appointment.interpreter}</Badge>
          </PatientName>
          <Details>
            {appointment.department} · {appointment.doctor}
          </Details>
        </AppointmentCard>
      ))}
    </AppointmentList>
  );
};

export default TodayAppointments; 