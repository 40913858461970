import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { makeApiRequest } from '../../../utils/apiUtils';

const Container = styled.div`
  padding: 24px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const MarkdownContent = styled.div`
  h2 {
    font-size: 20px;
    margin: 24px 0 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eee;
  }

  h3 {
    font-size: 18px;
    margin: 20px 0 12px;
  }

  p {
    line-height: 1.6;
    margin-bottom: 16px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 16px 0;
  }

  th, td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background: #f5f5f7;
  }

  code {
    background: #f5f5f7;
    padding: 2px 6px;
    border-radius: 4px;
    font-family: monospace;
  }

  pre {
    background: #f5f5f7;
    padding: 16px;
    border-radius: 8px;
    overflow-x: auto;
    margin: 16px 0;
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #666;
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  padding: 20px;
  text-align: center;
`;

// 바이트 단위 변환 유틸리티 함수
const formatBytes = (bytes, decimals = 2) => {
  if (!bytes) return '0 Bytes';
  
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// 날짜 포맷팅 유틸리티 함수
const formatDate = (date) => {
  if (!date) return 'N/A';
  return new Date(date).toLocaleString();
};

// 안전한 객체 접근 유틸리티 함수
const safeGet = (obj, path, defaultValue = '-') => {
  return path.split('.').reduce((acc, part) => (acc && acc[part] ? acc[part] : defaultValue), obj);
};

// SQL 포맷팅 함수 개선
const formatCreateSQL = (sql) => {
  if (!sql) {
    console.warn('SQL 정보가 없습니다');
    return '-- SQL 정보를 불러올 수 없습니다.';
  }

  try {
    return sql
      .replace(/\s+/g, ' ')  // 연속된 공백 제거
      .replace(/,/g, ',\n  ')
      .replace(/\(/g, ' (\n  ')
      .replace(/\)/g, '\n)')
      .replace(/CREATE TABLE/g, 'CREATE TABLE IF NOT EXISTS')
      .replace(/PRIMARY KEY/g, '\n  PRIMARY KEY')
      .replace(/FOREIGN KEY/g, '\n  FOREIGN KEY')
      .replace(/CONSTRAINT/g, '\n  CONSTRAINT')
      .replace(/REFERENCES/g, '\n    REFERENCES')
      .trim();
  } catch (error) {
    console.error('SQL 포맷팅 오류:', error);
    return sql;  // 포맷팅 실패 시 원본 반환
  }
};

const DashboardStructure = () => {
  const [structure, setStructure] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDashboardStructure = useCallback(async () => {
    try {
      console.log('데이터베이스 구조 조회 시작');
      setLoading(true);
      
      const response = await makeApiRequest('/api/system/dashboard/structure', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      // response가 직접 데이터 구조체임
      console.log('조회된 데이터베이스 구조:', response);
      setStructure(response);
      setError(null);
    } catch (error) {
      console.error('데이터베이스 구조 조회 실패:', error);
      setError('데이터베이스 구조를 불러오는데 실패했습니다.');
      setStructure(null);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDashboardStructure();
  }, [fetchDashboardStructure]);

  const generateDetailedMarkdown = (data) => {
    if (!data || !data.database || !data.tables) {
      console.log('데이터 유효성 검사 실패:', { data });
      return '데이터를 불러올 수 없습니다.';
    }

    const { database, tables } = data;
    
    // 안전한 객체 접근을 위한 함수
    const getTableStats = (tableInfo) => {
      const stats = tableInfo?.statistics || {};
      return {
        totalRows: stats.totalRows || 0,
        dataSize: stats.dataSize || 0,
        indexSize: stats.indexSize || 0,
        totalSize: stats.totalSize || 0,
        avgRowLength: stats.avgRowLength || 0
      };
    };

    // 컬럼 타입 설명 생성
    const getColumnTypeDescription = (column) => {
      let desc = column.type;
      if (column.keyType === 'PRI') desc += ' (Primary Key)';
      if (column.keyType === 'UNI') desc += ' (Unique)';
      if (column.keyType === 'MUL') desc += ' (Index)';
      if (column.extra === 'auto_increment') desc += ' (Auto Increment)';
      return desc;
    };

    // 인덱스 정보 포맷팅
    const formatIndexInfo = (indexes) => {
      return indexes.map(idx => ({
        name: idx.Key_name,
        columns: idx.Column_name,
        type: idx.Index_type,
        unique: idx.Non_unique === 0 ? 'Yes' : 'No'
      }));
    };

    return `
# 데이터베이스 상세 구조 문서

## 1. 데이터베이스 정보
- **데이터베이스명**: ${safeGet(database, 'dbName', 'N/A')}
- **문자셋**: ${safeGet(database, 'charset', 'N/A')}
- **콜레이션**: ${safeGet(database, 'collation', 'N/A')}
- **테이블 수**: ${Object.keys(tables).length}
- **마지막 업데이트**: ${new Date().toLocaleString()}

## 2. 테이블 상세 정보

${Object.entries(tables).map(([tableName, tableInfo]) => {
  if (!tableInfo) return `### ${tableName}\n정보를 불러올 수 없습니다.\n`;
  
  const stats = getTableStats(tableInfo);
  
  return `
### ${tableName}
${tableInfo.comment ? `> ${tableInfo.comment}` : ''}

#### 2.1 테이블 기본 정보
- **생성일**: ${formatDate(tableInfo.createTime)}
- **수정일**: ${formatDate(tableInfo.updateTime)}
- **엔진**: ${tableInfo.engine || 'N/A'}
- **레코드 수**: ${stats.totalRows.toLocaleString()}
- **데이터 크기**: ${formatBytes(stats.dataSize)}
- **인덱스 크기**: ${formatBytes(stats.indexSize)}

#### 2.2 테이블 생성 SQL
\`\`\`sql
${formatCreateSQL(tableInfo.createSQL)}
\`\`\`

#### 2.3 컬럼 상세
${Array.isArray(tableInfo.columns) ? `
| 컬럼명 | 타입 | NULL허용 | 기본값 | 설명 | 특이사항 |
|--------|------|----------|---------|------|----------|
${tableInfo.columns.map(col => 
  `| ${col.name || ''} | ${col.type || ''} | ${col.nullable || ''} | ${col.defaultValue || '-'} | ${col.comment || '-'} | ${getColumnTypeDescription(col)} |`
).join('\n')}
` : '컬럼 정보가 없습니다.'}

#### 2.4 인덱스 정보
${Array.isArray(tableInfo.indexes) && tableInfo.indexes.length > 0 ? `
| 인덱스명 | 컬럼 | 타입 | Unique | Cardinality |
|----------|------|------|---------|-------------|
${tableInfo.indexes.map(idx => 
  `| ${idx.Key_name || ''} | ${idx.Column_name || ''} | ${idx.Index_type || ''} | ${idx.Non_unique === 0 ? 'Yes' : 'No'} | ${idx.Cardinality || '-'} |`
).join('\n')}
` : '인덱스 정보가 없습니다.'}

#### 2.5 외래 키 관계
${Array.isArray(tableInfo.foreignKeys) && tableInfo.foreignKeys.length > 0 ? `
| 제약조건명 | 컬럼 | 참조 테이블 | 참조 컬럼 | 업데이트 규칙 | 삭제 규칙 |
|------------|------|--------------|------------|----------------|------------|
${tableInfo.foreignKeys.map(fk => 
  `| ${fk.name || ''} | ${fk.column || ''} | ${fk.refTable || ''} | ${fk.refColumn || ''} | ${fk.updateRule || ''} | ${fk.deleteRule || ''} |`
).join('\n')}
` : '외래 키 관계가 없습니다.'}
`;
}).join('\n')}

## 3. 데이터베이스 다이어그램

### 3.1 전체 ERD
\`\`\`mermaid
erDiagram
${Object.entries(tables)
  .filter(([_, tableInfo]) => tableInfo.foreignKeys && tableInfo.foreignKeys.length > 0)
  .map(([tableName, tableInfo]) => 
    tableInfo.foreignKeys.map(fk => 
      `    ${tableName} }|--|| ${fk.refTable} : "${fk.column}"`
    ).join('\n')
  ).join('\n')}
\`\`\`

### 3.2 테이블 그룹별 관계도

#### 사용자 관리 그룹
\`\`\`mermaid
erDiagram
    users ||--o{ user_sessions : "세션"
    users ||--|| roles : "권한"
    users ||--|| countries : "국가"
    users ||--|| languages : "언어"
\`\`\`

#### 환자 정보 그룹
\`\`\`mermaid
erDiagram
    users ||--o{ patient_info : "정보"
    users ||--o{ medical_info : "의료정보"
    users ||--o{ emergency_contacts : "비상연락처"
    users ||--o{ insurance_info : "보험정보"
\`\`\`

#### 진료 관리 그룹
\`\`\`mermaid
erDiagram
    users ||--o{ appointments : "예약"
    appointments ||--o{ appointment_treatments : "진료"
    departments ||--o{ treatments : "제공"
    users ||--o{ visits : "방문"
    users ||--o{ admissions : "입원"
\`\`\`

## 4. 데이터베이스 관리 정보

### 4.1 권한 설정
\`\`\`sql
-- 관리자 권한
GRANT ALL PRIVILEGES ON ${database.dbName}.* TO 'admin'@'localhost';

-- 운영자 권한
GRANT SELECT, INSERT, UPDATE ON ${database.dbName}.* TO 'operator'@'localhost';

-- 읽기 전용 권한
GRANT SELECT ON ${database.dbName}.* TO 'reader'@'localhost';
\`\`\`

### 4.2 백업 및 복구
\`\`\`bash
# 전체 백업
mysqldump -u [username] -p ${database.dbName} > backup_$(date +%Y%m%d).sql

# 특정 테이블 백업
mysqldump -u [username] -p ${database.dbName} [table_name] > table_backup_$(date +%Y%m%d).sql

# 복구
mysql -u [username] -p ${database.dbName} < backup_file.sql
\`\`\`

### 4.3 모니터링 쿼리
\`\`\`sql
-- 테이블 크기 확인
SELECT 
    table_name AS '테이블명',
    table_rows AS '행 수',
    ROUND(data_length/1024/1024, 2) AS '데이터크기(MB)',
    ROUND(index_length/1024/1024, 2) AS '인덱스크기(MB)'
FROM information_schema.tables
WHERE table_schema = '${database.dbName}'
ORDER BY data_length DESC;

-- 미사용 인덱스 확인
SELECT * FROM sys.schema_unused_indexes
WHERE object_schema = '${database.dbName}';

-- 슬로우 쿼리 확인
SELECT * FROM mysql.slow_log
WHERE db = '${database.dbName}'
ORDER BY start_time DESC LIMIT 10;
\`\`\`
`;
  };

  console.log('현재 상태:', {
    loading,
    hasError: !!error,
    hasStructure: !!structure,
    errorMessage: error
  });

  if (loading) {
    return <LoadingMessage>데이터베이스 구조를 불러오는 중...</LoadingMessage>;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  const markdown = generateDetailedMarkdown(structure);

  return (
    <Container>
      <Title>데이터베이스 구조</Title>
      <MarkdownContent>
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </MarkdownContent>
    </Container>
  );
};

export default DashboardStructure; 