import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`;

const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActivityItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e1e1e1;
  
  &:last-child {
    border-bottom: none;
  }
`;

const ActivityText = styled.div`
  color: #1d1d1f;
`;

const ActivityTime = styled.div`
  color: #86868b;
  font-size: 14px;
`;

const Title = styled.h3`
  font-size: 18px;
  color: #1d1d1f;
  margin-bottom: 16px;
`;

const ActivityFeed = ({ activities }) => {
  return (
    <Container>
      <Title>최근 활동</Title>
      <ActivityList>
        {activities.map((activity, index) => (
          <ActivityItem key={index}>
            <ActivityText>{activity.text}</ActivityText>
            <ActivityTime>{activity.time}</ActivityTime>
          </ActivityItem>
        ))}
      </ActivityList>
    </Container>
  );
};

export default ActivityFeed; 