import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StatusCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #f5f7f9;
  border-radius: 8px;
`;

const Language = styled.div`
  font-weight: 500;
`;

const Stats = styled.div`
  display: flex;
  gap: 16px;
`;

const Stat = styled.div`
  text-align: center;
  
  .value {
    font-size: 18px;
    font-weight: 600;
    color: ${props => props.highlight ? '#ff9500' : '#1d1d1f'};
  }
  
  .label {
    font-size: 12px;
    color: #86868b;
  }
`;

const InterpreterStatus = () => {
  const interpreterStats = [
    {
      language: '영어',
      waiting: 5,
      inProgress: 3,
      completed: 12
    },
    {
      language: '중국어',
      waiting: 8,
      inProgress: 2,
      completed: 7
    },
    {
      language: '일본어',
      waiting: 2,
      inProgress: 1,
      completed: 5
    }
  ];

  return (
    <Container>
      {interpreterStats.map((stat, index) => (
        <StatusCard key={index}>
          <Language>{stat.language}</Language>
          <Stats>
            <Stat highlight={stat.waiting > 0}>
              <div className="value">{stat.waiting}</div>
              <div className="label">대기</div>
            </Stat>
            <Stat>
              <div className="value">{stat.inProgress}</div>
              <div className="label">진행중</div>
            </Stat>
            <Stat>
              <div className="value">{stat.completed}</div>
              <div className="label">완료</div>
            </Stat>
          </Stats>
        </StatusCard>
      ))}
    </Container>
  );
};

export default InterpreterStatus; 