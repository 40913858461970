import React from 'react';
import styled from 'styled-components';
import CodeBlock from '../common/CodeBlock';

const Section = styled.section`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const Description = styled.p`
  color: #4b4b4b;
  line-height: 1.6;
  margin-bottom: 16px;
`;

const SubTitle = styled.h3`
  color: #1d1d1f;
  margin: 24px 0 16px;
`;

const Examples = () => {
  return (
    <div>
      <Title>사용 예제 (Examples)</Title>
      <Description>
        INTER MEDIC API의 주요 기능들을 실제 구현 예제와 함께 설명합니다.
      </Description>

      <Section>
        <SubTitle>1. 사용자 인증 구현</SubTitle>
        <Description>
          회원가입부터 로그인, 토큰 관리까지의 전체 인증 플로우 예제입니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// 1. 회원가입
async function signUp(userData) {
  const response = await fetch('/api/auth/signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
  });
  return response.json();
}

// 2. 로그인
async function login(email, password) {
  const response = await fetch('/api/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  });
  const data = await response.json();
  if (data.success) {
    // 토큰 저장
    localStorage.setItem('token', data.data.token);
    return data.data.user;
  }
  throw new Error(data.message);
}

// 3. 인증된 API 요청 헬퍼 함수
async function authenticatedRequest(url, options = {}) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': \`Bearer \${token}\`,
    'Content-Type': 'application/json',
    ...options.headers
  };

  const response = await fetch(url, {
    ...options,
    headers
  });

  if (response.status === 401) {
    // 토큰 만료 처리
    localStorage.removeItem('token');
    window.location.href = '/login';
    return;
  }

  return response.json();
}`}
        />
      </Section>

      <Section>
        <SubTitle>2. 실시간 채팅 구현</SubTitle>
        <Description>
          채팅방 생성, 메시지 송수신, 번역 기능이 포함된 채팅 구현 예제입니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// 1. 채팅방 생성
async function createChatRoom(participants) {
  return authenticatedRequest('/api/chat/rooms', {
    method: 'POST',
    body: JSON.stringify({
      room_name: '의료 상담',
      room_type: 'direct',
      participants
    })
  });
}

// 2. 메시지 전송 및 번역
async function sendMessage(roomId, content) {
  // 메시지 전송
  const message = await authenticatedRequest(
    \`/api/chat/rooms/\${roomId}/messages\`,
    {
      method: 'POST',
      body: JSON.stringify({
        content,
        messageType: 'text'
      })
    }
  );

  // 자동 번역 요청
  const translation = await authenticatedRequest(
    '/api/translations/message',
    {
      method: 'POST',
      body: JSON.stringify({
        messageId: message.data.messageId,
        targetLanguage: 'en'  // 상대방의 선호 언어
      })
    }
  );

  return {
    original: message.data,
    translated: translation.data
  };
}

// 3. 실시간 메시지 수신 (WebSocket)
function connectWebSocket(roomId, onMessage) {
  const token = localStorage.getItem('token');
  const ws = new WebSocket(
    \`wss://api.example.com/ws?token=\${token}&roomId=\${roomId}\`
  );

  ws.onmessage = (event) => {
    const message = JSON.parse(event.data);
    onMessage(message);
  };

  return ws;
}`}
        />
      </Section>

      <Section>
        <SubTitle>3. 파일 업로드 구현</SubTitle>
        <Description>
          채팅에서 이미지나 파일을 전송하는 예제입니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// 1. 이미지 업로드
async function uploadImage(file) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', 'chat_image');

  const response = await fetch('/api/files/upload', {
    method: 'POST',
    headers: {
      'Authorization': \`Bearer \${localStorage.getItem('token')}\`
    },
    body: formData
  });

  return response.json();
}

// 2. 이미지 메시지 전송
async function sendImageMessage(roomId, file) {
  // 먼저 이미지 업로드
  const upload = await uploadImage(file);
  
  // 이미지 메시지 전송
  return authenticatedRequest(
    \`/api/chat/rooms/\${roomId}/messages\`,
    {
      method: 'POST',
      body: JSON.stringify({
        messageType: 'image',
        content: upload.data.fileUrl,
        fileId: upload.data.fileId
      })
    }
  );
}`}
        />
      </Section>

      <Section>
        <SubTitle>4. 웹훅 구현 예제</SubTitle>
        <Description>
          웹훅을 등록하고 수신된 이벤트를 처리하는 서버 구현 예제입니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// Express.js 서버에서 웹훅 처리
const express = require('express');
const crypto = require('crypto');
const app = express();

// 웹훅 시크릿
const WEBHOOK_SECRET = 'your_webhook_secret';

// 서명 검증 미들웨어
function verifyWebhookSignature(req, res, next) {
  const signature = req.headers['x-webhook-signature'];
  const payload = req.body;
  
  const expectedSignature = crypto
    .createHmac('sha256', WEBHOOK_SECRET)
    .update(JSON.stringify(payload))
    .digest('hex');
    
  if (\`sha256=\${expectedSignature}\` !== signature) {
    return res.status(401).json({ error: '잘못된 서명' });
  }
  
  next();
}

// 웹훅 엔드포인트
app.post('/webhook',
  express.json(),
  verifyWebhookSignature,
  async (req, res) => {
    const { event, data } = req.body;
    
    // 이벤트 타입별 처리
    switch (event) {
      case 'chat.message':
        await handleNewMessage(data);
        break;
      case 'chat.status':
        await handleChatStatus(data);
        break;
      case 'user.status':
        await handleUserStatus(data);
        break;
    }
    
    res.status(200).json({ received: true });
  }
);

// 5초 이내 응답을 보장하기 위해 비동기 처리
async function handleNewMessage(data) {
  // 메시지 처리를 큐에 추가
  await messageQueue.add(data);
}`}
        />
      </Section>

      <Section>
        <SubTitle>5. 에러 처리</SubTitle>
        <Description>
          API 사용 시 발생할 수 있는 다양한 에러 상황에 대한 처리 예제입니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// API 에러 처리 클래스
class ApiError extends Error {
  constructor(status, message, errors = {}) {
    super(message);
    this.status = status;
    this.errors = errors;
  }
}

// API 요청 래퍼 함수
async function apiRequest(url, options = {}) {
  try {
    const response = await authenticatedRequest(url, options);
    
    if (!response.success) {
      throw new ApiError(
        response.status,
        response.message,
        response.errors
      );
    }
    
    return response.data;
  } catch (error) {
    if (error instanceof ApiError) {
      // 특정 에러 상황 처리
      switch (error.status) {
        case 401:
          handleAuthError();
          break;
        case 403:
          handleForbiddenError();
          break;
        case 429:
          await handleRateLimitError();
          // 재시도
          return apiRequest(url, options);
      }
    }
    
    // 예상치 못한 에러
    console.error('API Error:', error);
    throw error;
  }
}`}
        />
      </Section>

      <Section>
        <SubTitle>6. 다국어 처리 구현</SubTitle>
        <Description>
          채팅 메시지의 자동 번역과 언어 감지 기능 구현 예제입니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// 1. 언어 감지
async function detectLanguage(text) {
  return authenticatedRequest('/api/translations/detect', {
    method: 'POST',
    body: JSON.stringify({ text })
  });
}

// 2. 메시지 자동 번역 처리
class TranslationManager {
  constructor() {
    this.translationCache = new Map();
  }

  async translateMessage(message, targetLanguage) {
    const cacheKey = \`\${message.messageId}_\${targetLanguage}\`;
    
    // 캐시된 번역이 있는지 확인
    if (this.translationCache.has(cacheKey)) {
      return this.translationCache.get(cacheKey);
    }

    // 번역 요청
    const translation = await authenticatedRequest(
      '/api/translations/message',
      {
        method: 'POST',
        body: JSON.stringify({
          messageId: message.messageId,
          targetLanguage
        })
      }
    );

    // 캐시에 저장
    this.translationCache.set(cacheKey, translation.data);
    return translation.data;
  }

  // 채팅방의 모든 메시지 번역
  async translateChatHistory(messages, targetLanguage) {
    return Promise.all(
      messages.map(msg => this.translateMessage(msg, targetLanguage))
    );
  }
}`}
        />
      </Section>

      <Section>
        <SubTitle>7. 채팅방 관리 고급 기능</SubTitle>
        <Description>
          채팅방 상태 관리, 참여자 관리, 메시지 히스토리 관리 등의 고급 기능 구현 예제입니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// 1. 채팅방 관리 클래스
class ChatRoomManager {
  constructor(roomId) {
    this.roomId = roomId;
    this.participants = new Set();
    this.messageHistory = [];
    this.ws = null;
  }

  // 채팅방 초기화
  async initialize() {
    // 채팅방 정보 로드
    const roomInfo = await authenticatedRequest(
      \`/api/chat/rooms/\${this.roomId}\`
    );
    
    // 참여자 목록 설정
    roomInfo.data.participants.forEach(p => 
      this.participants.add(p.userId)
    );

    // 메시지 히스토리 로드
    await this.loadMessageHistory();

    // 웹소켓 연결
    this.connectWebSocket();
  }

  // 메시지 히스토리 로드
  async loadMessageHistory(limit = 50) {
    const response = await authenticatedRequest(
      \`/api/chat/rooms/\${this.roomId}/messages?limit=\${limit}\`
    );
    this.messageHistory = response.data.messages;
    return this.messageHistory;
  }

  // 참여자 추가
  async addParticipant(userId) {
    await authenticatedRequest(
      \`/api/chat/rooms/\${this.roomId}/participants\`,
      {
        method: 'POST',
        body: JSON.stringify({ userId })
      }
    );
    this.participants.add(userId);
  }

  // 채팅방 상태 변경
  async updateStatus(status) {
    return authenticatedRequest(
      \`/api/chat/rooms/\${this.roomId}/status\`,
      {
        method: 'PUT',
        body: JSON.stringify({ status })
      }
    );
  }

  // 메시지 읽음 처리
  async markAsRead(messageId) {
    return authenticatedRequest(
      \`/api/chat/rooms/\${this.roomId}/messages/\${messageId}/read\`,
      {
        method: 'PUT'
      }
    );
  }
}`}
        />
      </Section>

      <Section>
        <SubTitle>8. 알림 시스템 구현</SubTitle>
        <Description>
          실시간 알림 처리와 알림 상태 관리 구현 예제입니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// 1. 알림 관리자 클래스
class NotificationManager {
  constructor() {
    this.notifications = [];
    this.ws = null;
    this.handlers = new Map();
  }

  // 알림 웹소켓 연결
  connect() {
    const token = localStorage.getItem('token');
    this.ws = new WebSocket(
      \`wss://api.example.com/notifications?token=\${token}\`
    );

    this.ws.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      this.handleNotification(notification);
    };
  }

  // 알림 타입별 핸들러 등록
  on(type, handler) {
    this.handlers.set(type, handler);
  }

  // 알림 처리
  handleNotification(notification) {
    // 알림 목록에 추가
    this.notifications.push(notification);

    // 해당 타입의 핸들러 호출
    const handler = this.handlers.get(notification.type);
    if (handler) {
      handler(notification);
    }

    // 브라우저 알림 표시
    this.showBrowserNotification(notification);
  }

  // 브라우저 알림 표시
  async showBrowserNotification(notification) {
    if (!("Notification" in window)) return;

    if (Notification.permission === "granted") {
      new Notification(notification.title, {
        body: notification.content,
        icon: "/notification-icon.png"
      });
    } else if (Notification.permission !== "denied") {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        this.showBrowserNotification(notification);
      }
    }
  }

  // 알림 읽음 처리
  async markAsRead(notificationId) {
    await authenticatedRequest(
      \`/api/notifications/\${notificationId}/read\`,
      {
        method: 'PUT'
      }
    );
  }
}

// 2. 알림 매니저 사용 예시
const notificationManager = new NotificationManager();

// 알림 핸들러 등록
notificationManager.on('chat.message', (notification) => {
  console.log('새 메시지:', notification);
  updateUnreadCount();
  playNotificationSound();
});

notificationManager.on('chat.status', (notification) => {
  console.log('채팅방 상태 변경:', notification);
  updateChatRoomList();
});

// 연결 시작
notificationManager.connect();`}
        />
      </Section>

      <Section>
        <SubTitle>9. 재시도 및 오류 복구 구현</SubTitle>
        <Description>
          네트워크 오류나 일시적인 서버 문제에 대한 복구 로직 구현 예제입니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// 1. 재시도 가능한 API 요청
async function retryableRequest(url, options = {}, retries = 3) {
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  
  for (let i = 0; i < retries; i++) {
    try {
      return await authenticatedRequest(url, options);
    } catch (error) {
      if (i === retries - 1) throw error;
      
      // 재시도 간격을 지수적으로 증가
      const waitTime = Math.min(1000 * Math.pow(2, i), 10000);
      await delay(waitTime);
    }
  }
}

// 2. 웹소켓 재연결 관리
class WebSocketManager {
  constructor(url, options = {}) {
    this.url = url;
    this.options = options;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = options.maxReconnectAttempts || 5;
    this.ws = null;
  }

  connect() {
    this.ws = new WebSocket(this.url);

    this.ws.onclose = () => {
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 30000);
        setTimeout(() => this.connect(), delay);
      }
    };

    this.ws.onopen = () => {
      this.reconnectAttempts = 0;
      // 재연결 성공 후 필요한 초기화 작업
    };
  }
}

// 3. 오프라인 지원
class OfflineSupport {
  constructor() {
    this.pendingMessages = [];
    this.db = null;
    this.initIndexedDB();
  }

  async initIndexedDB() {
    // IndexedDB 초기화
    const request = indexedDB.open('chatOfflineStore', 1);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore('pendingMessages', { keyPath: 'id' });
    };
  }

  async savePendingMessage(message) {
    // 오프라인 상태에서 메시지 저장
    this.pendingMessages.push(message);
    await this.db
      .transaction(['pendingMessages'], 'readwrite')
      .objectStore('pendingMessages')
      .add(message);
  }

  async syncPendingMessages() {
    // 온라인 상태가 되면 저장된 메시지 전송
    const messages = await this.db
      .transaction(['pendingMessages'])
      .objectStore('pendingMessages')
      .getAll();

    for (const message of messages) {
      try {
        await sendMessage(message.roomId, message.content);
        // 성공적으로 전송된 메시지 삭제
        await this.db
          .transaction(['pendingMessages'], 'readwrite')
          .objectStore('pendingMessages')
          .delete(message.id);
      } catch (error) {
        console.error('메시지 동기화 실패:', error);
      }
    }
  }
}`}
        />
      </Section>
    </div>
  );
};

export default Examples; 