import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1d1d1f;
  margin: 0 0 24px 0;
`;

const Section = styled.div`
  margin-bottom: 32px;
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  color: #1d1d1f;
  margin: 0 0 16px 0;
`;

const SettingItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #e1e1e1;
  
  &:last-child {
    border-bottom: none;
  }
`;

const SettingInfo = styled.div`
  flex: 1;
`;

const SettingTitle = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const SettingDescription = styled.div`
  font-size: 14px;
  color: #666;
`;

const Toggle = styled.label`
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px;
  
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
    
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }
  
  input:checked + span {
    background-color: #007AFF;
  }
  
  input:checked + span:before {
    transform: translateX(24px);
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  background: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 24px;
  
  &:hover {
    background: #0066CC;
  }
`;

const NotificationSettings = () => {
  const [settings, setSettings] = useState({
    emailAppointment: true,
    emailCancellation: true,
    emailReminder: true,
    smsAppointment: true,
    smsCancellation: false,
    smsReminder: true,
    browserNotification: true,
    soundNotification: false
  });

  const handleToggle = (setting) => {
    setSettings(prev => ({
      ...prev,
      [setting]: !prev[setting]
    }));
  };

  return (
    <Container>
      <Title>알림 설정</Title>
      
      <Section>
        <SectionTitle>이메일 알림</SectionTitle>
        <SettingItem>
          <SettingInfo>
            <SettingTitle>예약 확인</SettingTitle>
            <SettingDescription>새로운 예약이 등록되었을 때 이메일 알림</SettingDescription>
          </SettingInfo>
          <Toggle>
            <input
              type="checkbox"
              checked={settings.emailAppointment}
              onChange={() => handleToggle('emailAppointment')}
            />
            <span />
          </Toggle>
        </SettingItem>
        <SettingItem>
          <SettingInfo>
            <SettingTitle>예약 취소</SettingTitle>
            <SettingDescription>예약이 취소되었을 때 이메일 알림</SettingDescription>
          </SettingInfo>
          <Toggle>
            <input
              type="checkbox"
              checked={settings.emailCancellation}
              onChange={() => handleToggle('emailCancellation')}
            />
            <span />
          </Toggle>
        </SettingItem>
      </Section>

      <Section>
        <SectionTitle>SMS 알림</SectionTitle>
        <SettingItem>
          <SettingInfo>
            <SettingTitle>예약 알림</SettingTitle>
            <SettingDescription>예약 시간 1시간 전 SMS 알림</SettingDescription>
          </SettingInfo>
          <Toggle>
            <input
              type="checkbox"
              checked={settings.smsReminder}
              onChange={() => handleToggle('smsReminder')}
            />
            <span />
          </Toggle>
        </SettingItem>
      </Section>

      <Section>
        <SectionTitle>브라우저 알림</SectionTitle>
        <SettingItem>
          <SettingInfo>
            <SettingTitle>실시간 알림</SettingTitle>
            <SettingDescription>새로운 알림이 있을 때 브라우저 알림</SettingDescription>
          </SettingInfo>
          <Toggle>
            <input
              type="checkbox"
              checked={settings.browserNotification}
              onChange={() => handleToggle('browserNotification')}
            />
            <span />
          </Toggle>
        </SettingItem>
        <SettingItem>
          <SettingInfo>
            <SettingTitle>알림음</SettingTitle>
            <SettingDescription>알림 시 소리 재생</SettingDescription>
          </SettingInfo>
          <Toggle>
            <input
              type="checkbox"
              checked={settings.soundNotification}
              onChange={() => handleToggle('soundNotification')}
            />
            <span />
          </Toggle>
        </SettingItem>
      </Section>

      <Button>저장</Button>
    </Container>
  );
};

export default NotificationSettings; 