import React, { useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FormContainer = styled.div`
  background: white;
  padding: 32px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
`;

const Title = styled.h3`
  margin: 0 0 24px 0;
  font-size: 20px;
  color: #1d1d1f;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #1d1d1f;
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const Select = styled.select`
  padding: 12px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  
  ${props => props.primary ? `
    background: #007AFF;
    color: white;
    border: none;
    
    &:hover {
      background: #0066CC;
    }
  ` : `
    background: white;
    color: #1d1d1f;
    border: 1px solid #e1e1e1;
    
    &:hover {
      background: #f5f5f7;
    }
  `}
`;

const EventForm = ({ onClose, onSubmit, selectedDate }) => {
  const [formData, setFormData] = useState({
    title: '',
    type: '',
    date: selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '',
    time: '',
    language: '',
    note: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Modal>
      <FormContainer>
        <Title>새 일정 등록</Title>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>일정 제목</Label>
            <Input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Label>일정 유형</Label>
            <Select
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value="">선택하세요</option>
              <option value="appointment">진료 예약</option>
              <option value="meeting">미팅</option>
              <option value="other">기타</option>
            </Select>
          </FormGroup>
          
          <FormGroup>
            <Label>날짜</Label>
            <Input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Label>시간</Label>
            <Input
              type="time"
              name="time"
              value={formData.time}
              onChange={handleChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Label>사용 언어</Label>
            <Select
              name="language"
              value={formData.language}
              onChange={handleChange}
            >
              <option value="">선택하세요</option>
              <option value="English">English</option>
              <option value="Chinese">Chinese</option>
              <option value="Japanese">Japanese</option>
              <option value="Spanish">Spanish</option>
            </Select>
          </FormGroup>
          
          <FormGroup>
            <Label>메모</Label>
            <Input
              as="textarea"
              name="note"
              value={formData.note}
              onChange={handleChange}
            />
          </FormGroup>

          <ButtonGroup>
            <Button type="button" onClick={onClose}>
              취소
            </Button>
            <Button type="submit" primary>
              등록
            </Button>
          </ButtonGroup>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default EventForm; 