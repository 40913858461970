import React from 'react';
import styled from 'styled-components';
import CodeBlock from '../common/CodeBlock';

const Section = styled.section`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const Description = styled.p`
  color: #4b4b4b;
  line-height: 1.6;
  margin-bottom: 16px;
`;

const SubTitle = styled.h3`
  color: #1d1d1f;
  margin: 24px 0 16px;
`;

const List = styled.ul`
  margin: 0 0 16px;
  padding-left: 20px;
  color: #4b4b4b;
  line-height: 1.6;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 16px 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Th = styled.th`
  padding: 12px 16px;
  text-align: left;
  background: #f5f5f7;
  border-bottom: 1px solid #e1e1e1;
`;

const Td = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #e1e1e1;
`;

const WebHooks = () => {
  return (
    <div>
      <Title>웹훅 (WebHooks)</Title>
      <Description>
        INTER MEDIC API는 실시간 이벤트 알림을 위한 웹훅 시스템을 제공합니다. 
        등록된 웹훅 URL로 특정 이벤트 발생 시 자동으로 알림을 전송합니다.
      </Description>

      <Section>
        <SubTitle>1. 웹훅 등록</SubTitle>
        <Description>
          웹훅을 등록하여 원하는 이벤트의 알림을 받을 수 있습니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`
POST /api/webhooks/register
Content-Type: application/json
Authorization: Bearer your_jwt_token

{
  "url": "https://your-domain.com/webhook",
  "events": ["chat.message", "chat.status", "user.status"],
  "description": "채팅 알림 웹훅",
  "secret": "your_webhook_secret"  // 웹훅 요청 검증을 위한 시크릿
}`}
        />

        <SubTitle>응답 예시</SubTitle>
        <CodeBlock
          language="javascript"
          code={`{
  "success": true,
  "data": {
    "webhookId": "webhook-123",
    "url": "https://your-domain.com/webhook",
    "events": ["chat.message", "chat.status", "user.status"],
    "status": "active",
    "createdAt": "2024-03-20T00:00:00Z"
  }
}`}
        />
      </Section>

      <Section>
        <SubTitle>2. 지원하는 이벤트 목록</SubTitle>
        <Table>
          <thead>
            <tr>
              <Th>이벤트</Th>
              <Th>설명</Th>
              <Th>페이로드 예시</Th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Td>chat.message</Td>
              <Td>새로운 채팅 메시지 발생</Td>
              <Td>
                <CodeBlock
                  language="javascript"
                  code={`{
  "event": "chat.message",
  "data": {
    "messageId": "msg-123",
    "roomId": "room-456",
    "senderId": 1,
    "content": "안녕하세요",
    "messageType": "text",
    "createdAt": "2024-03-20T00:00:00Z"
  }
}`}
                />
              </Td>
            </tr>
            <tr>
              <Td>chat.status</Td>
              <Td>채팅방 상태 변경</Td>
              <Td>
                <CodeBlock
                  language="javascript"
                  code={`{
  "event": "chat.status",
  "data": {
    "roomId": "room-456",
    "status": "in_progress",
    "updatedAt": "2024-03-20T00:00:00Z"
  }
}`}
                />
              </Td>
            </tr>
            <tr>
              <Td>user.status</Td>
              <Td>사용자 상태 변경</Td>
              <Td>
                <CodeBlock
                  language="javascript"
                  code={`{
  "event": "user.status",
  "data": {
    "userId": 1,
    "status": "online",
    "updatedAt": "2024-03-20T00:00:00Z"
  }
}`}
                />
              </Td>
            </tr>
          </tbody>
        </Table>
      </Section>

      <Section>
        <SubTitle>3. 웹훅 보안</SubTitle>
        <Description>
          모든 웹훅 요청에는 보안을 위한 서명이 포함됩니다. 
          요청 헤더의 X-Webhook-Signature를 검증하여 요청의 신뢰성을 확인할 수 있습니다.
        </Description>
        <CodeBlock
          language="javascript"
          code={`// 웹훅 요청 헤더
{
  "Content-Type": "application/json",
  "X-Webhook-Signature": "sha256=hash...",
  "X-Webhook-Event": "chat.message",
  "X-Webhook-Id": "webhook-123"
}

// Node.js에서 서명 검증 예시
const crypto = require('crypto');

function verifyWebhookSignature(payload, signature, secret) {
  const expectedSignature = crypto
    .createHmac('sha256', secret)
    .update(JSON.stringify(payload))
    .digest('hex');
  
  return signature === \`sha256=\${expectedSignature}\`;
}`}
        />
      </Section>

      <Section>
        <SubTitle>4. 웹훅 관리</SubTitle>
        <Description>
          등록된 웹훅은 다음 API를 통해 관리할 수 있습니다.
        </Description>

        <SubTitle>웹훅 목록 조회</SubTitle>
        <CodeBlock
          language="javascript"
          code={`GET /api/webhooks
Authorization: Bearer your_jwt_token

// Response
{
  "success": true,
  "data": {
    "webhooks": [
      {
        "webhookId": "webhook-123",
        "url": "https://your-domain.com/webhook",
        "events": ["chat.message"],
        "status": "active",
        "lastTriggered": "2024-03-20T00:00:00Z",
        "createdAt": "2024-03-20T00:00:00Z"
      }
    ]
  }
}`}
        />

        <SubTitle>웹훅 삭제</SubTitle>
        <CodeBlock
          language="javascript"
          code={`DELETE /api/webhooks/:webhookId
Authorization: Bearer your_jwt_token

// Response
{
  "success": true,
  "message": "웹훅이 삭제되었습니다."
}`}
        />
      </Section>

      <Section>
        <SubTitle>5. 모범 사례</SubTitle>
        <List>
          <ListItem>
            <strong>응답 시간:</strong> 웹훅 수신 후 5초 이내에 응답을 반환하세요.
          </ListItem>
          <ListItem>
            <strong>재시도 정책:</strong> 실패한 웹훅은 최대 5회까지 재시도됩니다.
          </ListItem>
          <ListItem>
            <strong>서명 검증:</strong> 모든 웹훅 요청의 서명을 반드시 검증하세요.
          </ListItem>
          <ListItem>
            <strong>멱등성:</strong> 동일한 이벤트가 중복 전송될 수 있으므로, 멱등성을 보장하도록 구현하세요.
          </ListItem>
        </List>
      </Section>
    </div>
  );
};

export default WebHooks; 