import React, { useState } from 'react';
import styled from 'styled-components';

const OnboardingModal = ({ onClose }) => {
  const [step, setStep] = useState(0);
  
  const steps = [
    {
      title: '환영합니다!',
      description: '서비스 사용을 위한 간단한 가이드를 시작하겠습니다.',
    },
    {
      title: '대시보드 살펴보기',
      description: '대시보드에서 모든 주요 기능을 확인할 수 있습니다.',
    },
    {
      title: '기능 둘러보기',
      description: '주요 기능들을 확인하고 시작해보세요.',
    }
  ];

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      onClose();
    }
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <h2>{steps[step].title}</h2>
        <p>{steps[step].description}</p>
        <ButtonGroup>
          <Button onClick={onClose}>건너뛰기</Button>
          <Button primary onClick={handleNext}>
            {step === steps.length - 1 ? '시작하기' : '다음'}
          </Button>
        </ButtonGroup>
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background: ${props => props.primary ? '#007AFF' : '#E5E5E5'};
  color: ${props => props.primary ? 'white' : 'black'};
`;

export default OnboardingModal; 