import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';
import DepartmentForm from './DepartmentForm';
import { useNavigate } from 'react-router-dom';
import TreatmentModal from './TreatmentModal';
import TreatmentForm from './TreatmentForm';

const Container = styled.div`
  padding: 24px;
  min-height: calc(100vh - 64px);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1a1a1a;
  margin: 0;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 6px;
  
  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}
  
  ${props => props.secondary && `
    background: #e9ecef;
    color: #495057;
    &:hover { background: #dee2e6; }
  `}

  ${props => props.danger && `
    background: #dc3545;
    color: white;
    &:hover { background: #c82333; }
  `}
`;

const ContentLayout = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  padding: 24px;
`;

const FloorSection = styled.div`
  margin-bottom: 32px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const FloorHeader = styled.div`
  padding: 20px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 18px;
    color: #1a1a1a;
    font-weight: 600;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  min-width: 800px;
  overflow-y: visible;
  position: relative;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const Th = styled.th`
  padding: 16px;
  text-align: left;
  font-weight: 600;
  color: #495057;
  border-bottom: 2px solid #e9ecef;
  background: white;
  white-space: nowrap;
  
  &:nth-child(1) { width: 20%; } // 진료과명
  &:nth-child(2) { width: 15%; } // 유형
  &:nth-child(3) { width: 30%; } // 설명
  &:nth-child(4) { width: 12%; } // 소속 의사
  &:nth-child(5) { width: 12%; } // 등록일
  &:nth-child(6) { width: 11%; } // 작업
`;

const Td = styled.td`
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  color: #212529;
  vertical-align: middle;
  
  &:nth-child(1) { // 진료과명
    font-weight: 500;
  }
  
  &:nth-child(3) { // 설명
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
`;

const DoctorLink = styled.div`
  cursor: pointer;
  color: #007AFF;
  display: flex;
  align-items: center;
  gap: 4px;
  
  &:hover {
    text-decoration: underline;
  }
  
  i {
    font-size: 12px;
  }
`;

const DepartmentBadge = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  background: ${props => props.type === '진료실' ? '#e3f2fd' : '#fff3e0'};
  color: ${props => props.type === '진료실' ? '#1976d2' : '#f57c00'};
`;

const DepartmentCount = styled.span`
  font-size: 12px;
  color: #666;
  margin-left: 8px;
`;

const EmptyState = styled.div`
  padding: 32px;
  text-align: center;
  color: #666;

  p {
    margin: 0 0 16px 0;
  }
`;

const DoctorModal = styled.div`
  position: absolute;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 16px;
  min-width: 240px;
  z-index: 1000;
  border: 1px solid #e0e0e0;
  top: 100%;
  left: 0;
  margin-top: 4px;
`;

const DoctorModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const DoctorLinkContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DoctorList = styled.div`
  margin-top: 12px;
  background-color: #ffffff;
`;

const DoctorItem = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid #eee;
  background-color: #ffffff;
  
  &:last-child {
    border-bottom: none;
  }
`;

const DoctorInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DoctorName = styled.div`
  font-weight: 500;
  color: #333;
`;

const DoctorSpecialty = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 4px;
`;

const PopoverTitle = styled.div`
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid #eee;
  background-color: #ffffff;
`;

const DepartmentNameLink = styled.span`
  cursor: pointer;
  color: #1a1a1a;
  font-weight: 500;
  
  &:hover {
    color: #007AFF;
    text-decoration: underline;
  }
`;

const TreatmentSection = styled.td`
  padding: 0;
  background-color: #f8f9fa;
`;

const TreatmentContainer = styled.div`
  padding: 20px;
  border-top: 1px solid #eee;
`;

const TreatmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const TreatmentTitle = styled.h4`
  margin: 0;
  font-size: 18px;
  color: #1a1a1a;
`;

const TreatmentTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
`;

const TreatmentTh = styled.th`
  padding: 12px 16px;
  text-align: left;
  background: #f8f9fa;
  border-bottom: 2px solid #e9ecef;
  color: #495057;
  font-weight: 600;
`;

const TreatmentTd = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #e9ecef;
  color: #212529;
`;

const TreatmentInputGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TreatmentInput = styled.input`
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDoctors, setSelectedDoctors] = useState(null);
  const popoverRef = useRef(null);
  const navigate = useNavigate();
  const [showTreatmentModal, setShowTreatmentModal] = useState(false);
  const [selectedDepartmentForTreatments, setSelectedDepartmentForTreatments] = useState(null);
  const [expandedDepartment, setExpandedDepartment] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [newTreatment, setNewTreatment] = useState('');
  const [showTreatmentForm, setShowTreatmentForm] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);

  // 층수 배열을 내림차순으로 정렬 (11층부터 B1층까지)
  const floors = ['11', '10', '9', '8', '7', '6', '5', '4', '3', '2', '1', 'B1'];

  useEffect(() => {
    loadDepartments();
  }, []);

  const loadDepartments = async () => {
    try {
      setLoading(true);
      const response = await makeApiRequest('/api/departments');
      if (response.data.success) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error('진료과 목록 로드 실패:', error);
      toast.error('진료과 목록을 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (department) => {
    setSelectedDepartment(department);
    setShowForm(true);
  };

  const handleSubmit = async (departmentData) => {
    try {
      if (selectedDepartment?.department_id) {
        // 수정
        const response = await makeApiRequest(`/api/departments/${selectedDepartment.department_id}`, {
          method: 'PUT',
          data: departmentData
        });
        
        if (response.data.success) {
          toast.success('진료과 정보가 수정되었습니다.');
          await loadDepartments();
          setShowForm(false);
          setSelectedDepartment(null);
        }
      } else {
        // 추가
        const response = await makeApiRequest('/api/departments', {
          method: 'POST',
          data: departmentData
        });
        
        if (response.data.success) {
          toast.success('새 진료과가 추가되었습니다.');
          await loadDepartments();
          setShowForm(false);
          setSelectedDepartment(null);
        }
      }
    } catch (error) {
      console.error('진료과 저장 실패:', error);
      toast.error(error.response?.data?.message || '진료과 정보 저장에 실패했습니다.');
    }
  };

  const handleDelete = async (department) => {
    if (window.confirm('정말 이 진료과를 삭제하시겠습니까?')) {
      try {
        const response = await makeApiRequest(`/api/departments/${department.department_id}`, {
          method: 'DELETE'
        });
        
        if (response.data.success) {
          toast.success('진료과가 삭제되었습니다.');
          await loadDepartments();
        }
      } catch (error) {
        console.error('진료과 삭제 실패:', error);
        toast.error(error.response?.data?.message || '진료과 삭제에 실패했습니다.');
      }
    }
  };

  const getDepartmentsByFloor = (floor) => {
    return departments.filter(dept => dept.location === floor);
  };

  const handleDoctorClick = async (event, departmentId, departmentName) => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedDoctors?.departmentId === departmentId) {
      setSelectedDoctors(null);
      return;
    }

    try {
      const response = await makeApiRequest(`/api/departments/${departmentId}/doctors`);
      if (response.data.success) {
        setSelectedDoctors({
          departmentId,
          departmentName,
          doctors: response.data.data
        });
      }
    } catch (error) {
      console.error('의사 목록 로드 실패:', error);
      toast.error('의사 목록을 불러오는데 실패했습니다.');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        if (!event.target.closest('[data-doctor-link="true"]')) {
          setSelectedDoctors(null);
        }
      }
    };

    if (selectedDoctors) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [selectedDoctors]);

  const handleDepartmentClick = async (department) => {
    if (expandedDepartment === department.department_id) {
      setExpandedDepartment(null);
      setTreatments([]);
    } else {
      try {
        const response = await makeApiRequest(`/api/departments/${department.department_id}/treatments`);
        
        if (response.data.success) {
          setTreatments(response.data.data);
          setExpandedDepartment(department.department_id);
        }
      } catch (error) {
        console.error('진료 항목 로드 실패:', error);
        toast.error('진료 항목을 불러오는데 실패했습니다.');
      }
    }
  };

  const handleAddTreatment = async (treatmentData) => {
    try {
      const response = await makeApiRequest(`/api/departments/${treatmentData.department_id}/treatments`, {
        method: 'POST',
        data: treatmentData
      });
      
      if (response.data.success) {
        const updatedResponse = await makeApiRequest(`/api/departments/${treatmentData.department_id}/treatments`);
        if (updatedResponse.data.success) {
          setTreatments(updatedResponse.data.data);
          setShowTreatmentForm(false);
          setSelectedTreatment(null);
          toast.success('진료 항목이 추가되었습니다.');
        }
      }
    } catch (error) {
      console.error('진료 항목 추가 실패:', error);
      toast.error('진료 항목 추가에 실패했습니다.');
    }
  };

  const handleEditTreatment = async (treatmentData) => {
    try {
      const response = await makeApiRequest(
        `/api/departments/${treatmentData.department_id}/treatments/${selectedTreatment.treatment_id}`,
        {
          method: 'PUT',
          data: treatmentData
        }
      );
      
      if (response.data.success) {
        const updatedResponse = await makeApiRequest(`/api/departments/${treatmentData.department_id}/treatments`);
        if (updatedResponse.data.success) {
          setTreatments(updatedResponse.data.data);
          setShowTreatmentForm(false);
          setSelectedTreatment(null);
          toast.success('진료 항목이 수정되었습니다.');
        }
      }
    } catch (error) {
      console.error('진료 항목 수정 실패:', error);
      toast.error('진료 항목 수정에 실패했습니다.');
    }
  };

  const handleDeleteTreatment = async (treatmentId, departmentId) => {
    if (window.confirm('이 진료 항목을 삭제하시겠습니까?')) {
      try {
        const response = await makeApiRequest(`/api/treatments/${treatmentId}`, {
          method: 'DELETE'
        });
        
        if (response.data.success) {
          const updatedResponse = await makeApiRequest(`/api/departments/${departmentId}/treatments`);
          if (updatedResponse.data.success) {
            setTreatments(updatedResponse.data.data);
            toast.success('진료 항목이 삭제되었습니다.');
          }
        }
      } catch (error) {
        console.error('진료 항목 삭제 실패:', error);
        toast.error('진료 항목 삭제에 실패했습니다.');
      }
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  return (
    <Container>
      <Header>
        <Title>진료실 관리</Title>
        <ActionButton primary onClick={() => setShowForm(true)}>
          <i className="fas fa-plus"></i>
          새 진료과 추가
        </ActionButton>
      </Header>

      <ContentLayout>
        {floors.map(floor => {
          const floorDepartments = getDepartmentsByFloor(floor);
          const floorDisplay = floor === 'B1' ? '지하 1층' : `${floor}층`;
          
          return (
            <FloorSection key={floor}>
              <FloorHeader>
                <h3>{floorDisplay}</h3>
                <ActionButton 
                  secondary
                  onClick={() => {
                    setSelectedDepartment({ location: floor, department_type: '진료실' });
                    setShowForm(true);
                  }}
                >
                  <i className="fas fa-plus"></i>
                  진료과 추가
                </ActionButton>
              </FloorHeader>

              {floorDepartments.length > 0 ? (
                <TableWrapper>
                  <Table>
                    <thead>
                      <tr>
                        <Th>진료과명</Th>
                        <Th>유형</Th>
                        <Th>설명</Th>
                        <Th>소속 의사</Th>
                        <Th>등록일</Th>
                        <Th>작업</Th>
                      </tr>
                    </thead>
                    <tbody>
                      {floorDepartments.map(dept => (
                        <React.Fragment key={dept.department_id}>
                          <tr>
                            <Td>
                              <DepartmentNameLink onClick={() => handleDepartmentClick(dept)}>
                                {dept.department_name}
                              </DepartmentNameLink>
                            </Td>
                            <Td>
                              <DepartmentBadge type={dept.department_type}>
                                {dept.department_type}
                              </DepartmentBadge>
                            </Td>
                            <Td title={dept.description || '-'}>
                              {dept.description || '-'}
                            </Td>
                            <Td>
                              <DoctorLinkContainer>
                                <DoctorLink 
                                  data-doctor-link="true"
                                  onClick={(e) => {
                                    handleDoctorClick(e, dept.department_id, dept.department_name);
                                  }}
                                >
                                  {dept.doctor_count}명
                                  <i className="fas fa-chevron-down"></i>
                                </DoctorLink>
                                {selectedDoctors?.departmentId === dept.department_id && (
                                  <>
                                    <DoctorModalOverlay onClick={() => setSelectedDoctors(null)} />
                                    <DoctorModal ref={popoverRef}>
                                      <PopoverTitle>{selectedDoctors.departmentName} 소속 의사</PopoverTitle>
                                      <DoctorList>
                                        {selectedDoctors.doctors.length > 0 ? (
                                          selectedDoctors.doctors.map(doctor => (
                                            <DoctorItem key={doctor.doctor_id}>
                                              <DoctorInfo>
                                                <div>
                                                  <DoctorName>{doctor.name}</DoctorName>
                                                  <DoctorSpecialty>
                                                    {doctor.specialty || '전공 미지정'}
                                                  </DoctorSpecialty>
                                                </div>
                                              </DoctorInfo>
                                            </DoctorItem>
                                          ))
                                        ) : (
                                          <DoctorItem>등록된 의사가 없습니다.</DoctorItem>
                                        )}
                                      </DoctorList>
                                    </DoctorModal>
                                  </>
                                )}
                              </DoctorLinkContainer>
                            </Td>
                            <Td>{new Date(dept.created_at).toLocaleDateString()}</Td>
                            <Td>
                              <ButtonGroup>
                                <ActionButton 
                                  secondary 
                                  onClick={() => handleEdit(dept)}
                                >
                                  <i className="fas fa-edit"></i>
                                  수정
                                </ActionButton>
                                <ActionButton 
                                  danger
                                  onClick={() => handleDelete(dept)}
                                  disabled={dept.doctor_count > 0}
                                  title={dept.doctor_count > 0 ? "소속 의사가 있는 진료과는 삭제할 수 없습니다" : ""}
                                >
                                  <i className="fas fa-trash"></i>
                                  삭제
                                </ActionButton>
                              </ButtonGroup>
                            </Td>
                          </tr>
                          {expandedDepartment === dept.department_id && (
                            <tr>
                              <TreatmentSection colSpan="6">
                                <TreatmentContainer>
                                  <TreatmentHeader>
                                    <TreatmentTitle>{dept.department_name} 진료 항목</TreatmentTitle>
                                    <ActionButton
                                      primary
                                      onClick={() => {
                                        setSelectedTreatment(null);
                                        setShowTreatmentForm(true);
                                      }}
                                    >
                                      <i className="fas fa-plus"></i>
                                      새 진료 항목
                                    </ActionButton>
                                  </TreatmentHeader>
                                  
                                  <TreatmentTable>
                                    <thead>
                                      <tr>
                                        <TreatmentTh style={{ width: '35%' }}>진료 항목명</TreatmentTh>
                                        <TreatmentTh style={{ width: '45%' }}>설명</TreatmentTh>
                                        <TreatmentTh style={{ width: '20%' }}>작업</TreatmentTh>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {treatments.length > 0 ? (
                                        treatments.map(treatment => (
                                          <tr key={treatment.treatment_id}>
                                            <TreatmentTd>{treatment.treatment_name}</TreatmentTd>
                                            <TreatmentTd>{treatment.description || '-'}</TreatmentTd>
                                            <TreatmentTd>
                                              <ButtonGroup>
                                                <ActionButton
                                                  secondary
                                                  onClick={() => {
                                                    setSelectedTreatment(treatment);
                                                    setShowTreatmentForm(true);
                                                  }}
                                                >
                                                  <i className="fas fa-edit"></i>
                                                  수정
                                                </ActionButton>
                                                <ActionButton
                                                  danger
                                                  onClick={() => handleDeleteTreatment(treatment.treatment_id, dept.department_id)}
                                                >
                                                  <i className="fas fa-times"></i>
                                                  삭제
                                                </ActionButton>
                                              </ButtonGroup>
                                            </TreatmentTd>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <TreatmentTd colSpan="3" style={{ textAlign: 'center' }}>
                                            등록된 진료 항목이 없습니다.
                                          </TreatmentTd>
                                        </tr>
                                      )}
                                    </tbody>
                                  </TreatmentTable>
                                </TreatmentContainer>
                              </TreatmentSection>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </TableWrapper>
              ) : (
                <EmptyState>
                  <p>이 층에 등록된 진료과가 없습니다.</p>
                  <ActionButton 
                    secondary
                    onClick={() => {
                      setSelectedDepartment({ location: floor });
                      setShowForm(true);
                    }}
                  >
                    <i className="fas fa-plus"></i>
                    진료과 추가하기
                  </ActionButton>
                </EmptyState>
              )}
            </FloorSection>
          );
        })}
      </ContentLayout>

      {showForm && (
        <DepartmentForm
          onClose={() => {
            setShowForm(false);
            setSelectedDepartment(null);
          }}
          onSubmit={handleSubmit}
          initialData={selectedDepartment}
          floors={floors}
          isEdit={!!selectedDepartment?.department_id}
        />
      )}

      {showTreatmentModal && (
        <TreatmentModal
          department={selectedDepartmentForTreatments}
          onClose={() => {
            setShowTreatmentModal(false);
            setSelectedDepartmentForTreatments(null);
          }}
        />
      )}

      {showTreatmentForm && (
        <TreatmentForm
          onClose={() => {
            setShowTreatmentForm(false);
            setSelectedTreatment(null);
          }}
          onSubmit={selectedTreatment ? handleEditTreatment : handleAddTreatment}
          initialData={selectedTreatment}
          departmentId={expandedDepartment}
        />
      )}
    </Container>
  );
};

export default DepartmentList; 