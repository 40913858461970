import React from 'react';
import styled from 'styled-components';
import CodeBlock from '../common/CodeBlock';

const Section = styled.section`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const Description = styled.p`
  color: #4b4b4b;
  line-height: 1.6;
  margin-bottom: 16px;
`;

const SubTitle = styled.h3`
  color: #1d1d1f;
  margin: 24px 0 16px;
`;

const List = styled.ul`
  margin: 0 0 16px;
  padding-left: 20px;
  color: #4b4b4b;
  line-height: 1.6;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
`;

const TableWrapper = styled.div`
  margin: 20px 0;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Th = styled.th`
  padding: 12px 16px;
  text-align: left;
  background: #f5f5f7;
  border-bottom: 1px solid #e1e1e1;
  font-weight: 600;
`;

const Td = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #e1e1e1;
  line-height: 1.5;
`;

const Authentication = () => {
  return (
    <div>
      <Title>인증</Title>
      <Description>
        INTER MEDIC API는 JWT(JSON Web Token) 기반의 인증 시스템을 사용합니다.
        모든 보호된 API 엔드포인트에 접근하기 위해서는 유효한 JWT 토큰이 필요합니다.
      </Description>

      <Section>
        <SubTitle>1. 회원가입 (Sign Up)</SubTitle>
        <Description>
          새로운 사용자를 등록합니다. 이메일 주소는 고유해야 하며, 비밀번호는 보안 요구사항을 충족해야 합니다.
        </Description>

        <SubTitle>요청 형식</SubTitle>
        <CodeBlock
          language="javascript"
          code={`
POST /api/auth/signup
Content-Type: application/json

{
  "email": "user@example.com",
  "password": "your_password",      // 최소 8자, 영문/숫자/특수문자 조합
  "name": "사용자 이름",
  "phone_code": "82",              // 국가 전화번호 코드
  "phone_number": "1012345678",    // - 없이 숫자만
  "country_id": 1,                 // 국가 ID
  "preferred_language": "ko",      // 선호 언어 코드
  "role_id": 3                     // 사용자 역할 ID
}`}
        />

        <SubTitle>응답 형식</SubTitle>
        <CodeBlock
          language="javascript"
          code={`
// 성공 응답 (200 OK)
{
  "success": true,
  "message": "회원가입이 완료되었습니다.",
  "data": {
    "userId": 1,
    "email": "user@example.com",
    "name": "사용자 이름",
    "role": {
      "id": 3,
      "name": "환자"
    }
  }
}

// 실패 응답 (400 Bad Request)
{
  "success": false,
  "message": "이미 사용 중인 이메일입니다.",
  "errors": {
    "email": "이미 등록된 이메일 주소입니다."
  }
}`}
        />

        <SubTitle>비밀번호 요구사항</SubTitle>
        <List>
          <ListItem>최소 8자 이상</ListItem>
          <ListItem>영문 대/소문자 포함</ListItem>
          <ListItem>숫자 포함</ListItem>
          <ListItem>특수문자 1개 이상 포함</ListItem>
          <ListItem>연속된 문자 3개 이상 사용 불가</ListItem>
          <ListItem>이메일 주소에 포함된 문자열 사용 불가</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>2. 로그인 (Login)</SubTitle>
        <Description>
          등록된 이메일과 비밀번호로 로그인하여 JWT 토큰을 발급받습니다.
          발급된 토큰은 24시간 동안 유효합니다.
        </Description>

        <SubTitle>요청 형식</SubTitle>
        <CodeBlock
          language="javascript"
          code={`
POST /api/auth/login
Content-Type: application/json

{
  "email": "user@example.com",
  "password": "your_password"
}`}
        />

        <SubTitle>응답 형식</SubTitle>
        <CodeBlock
          language="javascript"
          code={`
// 성공 응답 (200 OK)
{
  "success": true,
  "data": {
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...",
    "user": {
      "userId": 1,
      "email": "user@example.com",
      "name": "사용자 이름",
      "role_id": 3,
      "preferred_language": "ko",
      "permissions": ["chat:read", "chat:write"]
    },
    "expires_in": 86400  // 24시간 (초)
  }
}

// 실패 응답 (401 Unauthorized)
{
  "success": false,
  "message": "이메일 또는 비밀번호가 올바르지 않습니다.",
  "error_code": "AUTH_FAILED"
}`}
        />
      </Section>

      <Section>
        <SubTitle>3. 토큰 갱신 (Token Refresh)</SubTitle>
        <Description>
          만료되기 전의 유효한 토큰을 사용하여 새로운 토큰을 발급받습니다.
        </Description>

        <SubTitle>요청 형식</SubTitle>
        <CodeBlock
          language="javascript"
          code={`
POST /api/auth/refresh
Authorization: Bearer current_token

// 응답 형식
{
  "success": true,
  "data": {
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...",
    "expires_in": 86400
  }
}`}
        />
      </Section>

      <Section>
        <SubTitle>4. 로그아웃 (Logout)</SubTitle>
        <Description>
          현재 세션을 종료하고 토큰을 무효화합니다.
        </Description>

        <SubTitle>요청 형식</SubTitle>
        <CodeBlock
          language="javascript"
          code={`
POST /api/auth/logout
Authorization: Bearer your_token

// 응답 형식
{
  "success": true,
  "message": "로그아웃되었습니다."
}`}
        />
      </Section>

      <Section>
        <SubTitle>5. 토큰 검증</SubTitle>
        <Description>
          현재 토큰의 유효성을 확인합니다.
        </Description>

        <SubTitle>요청 형식</SubTitle>
        <CodeBlock
          language="javascript"
          code={`
GET /api/auth/verify
Authorization: Bearer your_token

// 응답 형식
{
  "success": true,
  "data": {
    "valid": true,
    "expires_in": 3600,  // 남은 시간 (초)
    "user": {
      "userId": 1,
      "role_id": 3,
      "permissions": ["chat:read", "chat:write"]
    }
  }
}`}
        />
      </Section>

      <Section>
        <SubTitle>6. 보안 정책</SubTitle>
        <List>
          <ListItem>
            <strong>로그인 시도 제한:</strong> 5회 실패 시 30분 동안 계정 잠금
          </ListItem>
          <ListItem>
            <strong>토큰 만료:</strong> 발급 후 24시간
          </ListItem>
          <ListItem>
            <strong>세션 관리:</strong> 디바이스당 하나의 활성 세션만 허용
          </ListItem>
          <ListItem>
            <strong>IP 기반 제한:</strong> 비정상적인 IP 변경 감지 시 추가 인증 요구
          </ListItem>
          <ListItem>
            <strong>HTTPS:</strong> 모든 인증 요청은 HTTPS를 통해서만 가능
          </ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>7. 에러 코드</SubTitle>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>에러 코드</Th>
                <Th>설명</Th>
                <Th>HTTP 상태</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td>AUTH_FAILED</Td>
                <Td>인증 실패 (잘못된 이메일/비밀번호)</Td>
                <Td>401</Td>
              </tr>
              <tr>
                <Td>TOKEN_EXPIRED</Td>
                <Td>토큰 만료</Td>
                <Td>401</Td>
              </tr>
              <tr>
                <Td>INVALID_TOKEN</Td>
                <Td>유효하지 않은 토큰</Td>
                <Td>401</Td>
              </tr>
              <tr>
                <Td>ACCOUNT_LOCKED</Td>
                <Td>계정 잠금</Td>
                <Td>403</Td>
              </tr>
              <tr>
                <Td>SESSION_EXPIRED</Td>
                <Td>세션 만료</Td>
                <Td>401</Td>
              </tr>
            </tbody>
          </Table>
        </TableWrapper>
      </Section>
    </div>
  );
};

export default Authentication; 