import React from 'react';
import styled from 'styled-components';

const StatusIndicator = styled.span.attrs(props => ({
  connected: props.connected
}))`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.connected ? '#34c759' : '#ff3b30'};
`;

export const NetworkStatus = ({ connected, ...props }) => (
  <StatusIndicator connected={connected} {...props} />
);

export default NetworkStatus; 