import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1d1d1f;
  margin: 0 0 24px 0;
`;

const ReportGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
`;

const ReportCard = styled.div`
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: #f8f9fa;
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }
`;

const ReportTitle = styled.h3`
  font-size: 16px;
  color: #1d1d1f;
  margin: 0 0 8px 0;
`;

const ReportDate = styled.div`
  font-size: 14px;
  color: #86868b;
  margin-bottom: 16px;
`;

const ReportSummary = styled.div`
  font-size: 14px;
  color: #1d1d1f;
  line-height: 1.5;
`;

const Button = styled.button`
  padding: 12px 24px;
  background: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 24px;
  
  &:hover {
    background: #0066CC;
  }
`;

const Reports = () => {
  // 목업 데이터
  const reports = [
    {
      id: 1,
      title: '3월 외국인 환자 진료 리포트',
      date: '2024-03-15',
      summary: '3월 한 달간의 외국인 환자 진료 현황과 통계 분석 보고서입니다.'
    },
    {
      id: 2,
      title: '통역 서비스 이용 분석',
      date: '2024-03-10',
      summary: '언어별 통역 서비스 이용 현황과 만족도 분석 결과입니다.'
    },
    {
      id: 3,
      title: '진료과별 외국인 환자 현황',
      date: '2024-03-05',
      summary: '각 진료과별 외국인 환자 방문 통계와 특이사항을 정리했습니다.'
    },
    {
      id: 4,
      title: '환자 만족도 조사 결과',
      date: '2024-03-01',
      summary: '외국인 환자 대상 만족도 조사 결과와 개선점 분석입니다.'
    }
  ];

  return (
    <Container>
      <Title>리포트</Title>
      <Button>+ 새 리포트 생성</Button>
      
      <ReportGrid>
        {reports.map(report => (
          <ReportCard key={report.id}>
            <ReportTitle>{report.title}</ReportTitle>
            <ReportDate>{report.date}</ReportDate>
            <ReportSummary>{report.summary}</ReportSummary>
          </ReportCard>
        ))}
      </ReportGrid>
    </Container>
  );
};

export default Reports; 