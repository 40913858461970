import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { makeApiRequest } from '../../../utils/apiUtils';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  &:focus {
    border-color: #4CAF50;
    outline: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  &:focus {
    border-color: #4CAF50;
    outline: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  
  ${props => props.primary && `
    background: #4CAF50;
    color: white;
    &:hover {
      background: #45a049;
    }
  `}
  
  ${props => props.secondary && `
    background: #f5f5f5;
    color: #333;
    &:hover {
      background: #e0e0e0;
    }
  `}
`;

const DayButton = styled.button`
  padding: 8px;
  margin: 2px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: ${props => props.selected ? '#4CAF50' : 'white'};
  color: ${props => props.selected ? 'white' : 'black'};
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: ${props => props.selected ? '#45a049' : '#f5f5f5'};
  }
`;

const TimeButton = styled.button`
  padding: 8px;
  margin: 2px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: ${props => props.active ? '#4CAF50' : 'white'};
  color: ${props => props.active ? 'white' : 'black'};
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: ${props => props.active ? '#45a049' : '#f5f5f5'};
  }
`;

const TimeSettingsContainer = styled.div`
  margin-left: 20px;
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
`;

const TimeInputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;
`;

const TimeLabel = styled.label`
  min-width: 100px;
  font-weight: bold;
  color: #333;
`;

const TimeSelect = styled.select`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  &:focus {
    border-color: #4CAF50;
    outline: none;
  }
`;

const PresetButton = styled(TimeButton)`
  flex: 1;
  padding: 12px;
  font-size: 14px;
  ${props => props.active && `
    background: #4CAF50;
    color: white;
  `}
`;

const PresetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
`;

const DoctorForm = ({ onSubmit, onClose, initialData = null, isEditing }) => {
  const [formData, setFormData] = useState({
    doctor_id: '',
    name: '',
    department_id: '',
    specialty: '',
    work_days: '월-금',
    work_start_time: '09:00',
    work_end_time: '18:00',
    lunch_start: '12:00',
    lunch_end: '13:00',
    appointment_duration: 15,
    is_regular: true
  });

  const [departments, setDepartments] = useState([]);
  const [selectedDays, setSelectedDays] = useState(new Set(['월','화','수','목','금']));
  const [activeTimePreset, setActiveTimePreset] = useState('fullday');
  const [showCustomTime, setShowCustomTime] = useState(false);

  useEffect(() => {
    if (initialData && isEditing) {
      setFormData({
        ...initialData,
        doctor_id: initialData.doctor_id.toString(),
        work_start_time: initialData.work_start_time.slice(0, 5),
        work_end_time: initialData.work_end_time.slice(0, 5),
        lunch_start: initialData.lunch_start.slice(0, 5),
        lunch_end: initialData.lunch_end.slice(0, 5)
      });
      if (initialData.work_days) {
        const [start, end] = initialData.work_days.split('-');
        const days = ['월','화','수','목','금','토','일'];
        const startIdx = days.indexOf(start);
        const endIdx = days.indexOf(end);
        const selected = new Set(days.slice(startIdx, endIdx + 1));
        setSelectedDays(selected);
      }
    }
  }, [initialData, isEditing]);

  useEffect(() => {
    const loadDepartments = async () => {
      try {
        const response = await makeApiRequest('/api/departments');
        if (response.data.success) {
          setDepartments(response.data.data);
        } else {
          toast.error('진료과 목록을 불러오는데 실패했습니다.');
        }
      } catch (error) {
        console.error('진료과 목록 로드 실패:', error);
        toast.error('진료과 목록을 불러오는데 실패했습니다.');
      }
    };

    loadDepartments();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // 시간 형식을 HH:mm:ss로 정확하게 변환
    const formatTime = (time) => {
      if (!time) return null;
      // 이미 HH:mm:ss 형식이면 그대로 반환
      if (time.split(':').length === 3) return time;
      // HH:mm 형식이면 :00 추가
      if (time.split(':').length === 2) return `${time}:00`;
      // 잘못된 형식이면 null 반환
      return null;
    };

    // 모든 시간 필드 검증
    const timeFields = ['work_start_time', 'work_end_time', 'lunch_start', 'lunch_end'];
    const formattedTimes = {};
    
    for (const field of timeFields) {
      const formattedTime = formatTime(formData[field]);
      if (!formattedTime) {
        toast.error(`${field} 시간 형식이 올바르지 않습니다.`);
        return;
      }
      formattedTimes[field] = formattedTime;
    }

    const submitData = {
      ...formData,
      ...formattedTimes
    };

    onSubmit(submitData);
  };

  const handleDayClick = (day) => {
    const newSelectedDays = new Set(selectedDays);
    if (newSelectedDays.has(day)) {
      newSelectedDays.delete(day);
    } else {
      newSelectedDays.add(day);
    }
    setSelectedDays(newSelectedDays);

    const days = ['월','화','수','목','금','토','일'];
    const selectedDaysArray = days.filter(d => newSelectedDays.has(d));
    if (selectedDaysArray.length > 0) {
      const workDays = `${selectedDaysArray[0]}-${selectedDaysArray[selectedDaysArray.length-1]}`;
      setFormData(prev => ({ ...prev, work_days: workDays }));
    }
  };

  const handleTimePreset = (preset) => {
    setActiveTimePreset(preset);
    switch(preset) {
      case 'morning':
        setFormData(prev => ({
          ...prev,
          work_start_time: '09:00',
          work_end_time: '13:00',
          lunch_start: '12:00',
          lunch_end: '13:00'
        }));
        break;
      case 'afternoon':
        setFormData(prev => ({
          ...prev,
          work_start_time: '14:00',
          work_end_time: '18:00',
          lunch_start: '12:00',
          lunch_end: '13:00'
        }));
        break;
      case 'fullday':
        setFormData(prev => ({
          ...prev,
          work_start_time: '09:00',
          work_end_time: '18:00',
          lunch_start: '12:00',
          lunch_end: '13:00'
        }));
        break;
      default:
        break;
    }
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        const label = `${hour < 12 ? '오전' : '오후'} ${hour === 0 ? 12 : hour > 12 ? hour - 12 : hour}:${minute.toString().padStart(2, '0')}`;
        options.push({ value: time, label });
      }
    }
    return options;
  };

  return (
    <Modal onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <h2>{isEditing ? '의사 정보 수정' : '새 의사 등록'}</h2>
        <form onSubmit={handleSubmit}>
          {isEditing && (
            <FormGroup>
              <Label>의사 ID *</Label>
              <Input
                type="number"
                value={formData.doctor_id}
                onChange={e => setFormData(prev => ({ 
                  ...prev, 
                  doctor_id: e.target.value 
                }))}
                required
                min="1"
                placeholder="의사 ID를 입력하세요"
              />
            </FormGroup>
          )}

          <FormGroup>
            <Label>이름 *</Label>
            <Input
              type="text"
              value={formData.name}
              onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>진료과 *</Label>
            <Select
              value={formData.department_id}
              onChange={e => setFormData(prev => ({ ...prev, department_id: e.target.value }))}
              required
            >
              <option value="">선택하세요</option>
              {departments.map(dept => (
                <option key={dept.department_id} value={dept.department_id}>
                  {dept.department_name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>전문분야</Label>
            <Input
              type="text"
              value={formData.specialty}
              onChange={e => setFormData(prev => ({ ...prev, specialty: e.target.value }))}
            />
          </FormGroup>

          <FormGroup>
            <Label>진료 요일</Label>
            <div style={{ display: 'flex', gap: '5px', marginBottom: '10px' }}>
              {['월','화','수','목','금','토','일'].map(day => (
                <DayButton
                  key={day}
                  type="button"
                  selected={selectedDays.has(day)}
                  onClick={() => handleDayClick(day)}
                >
                  {day}
                </DayButton>
              ))}
            </div>
          </FormGroup>

          <FormGroup>
            <Label>진료 시간</Label>
            <PresetContainer>
              <PresetButton
                type="button"
                active={activeTimePreset === 'morning'}
                onClick={() => handleTimePreset('morning')}
              >
                오전 진료
                <div style={{ fontSize: '12px', marginTop: '4px' }}>
                  09:00 - 13:00
                </div>
              </PresetButton>
              <PresetButton
                type="button"
                active={activeTimePreset === 'afternoon'}
                onClick={() => handleTimePreset('afternoon')}
              >
                오후 진료
                <div style={{ fontSize: '12px', marginTop: '4px' }}>
                  14:00 - 18:00
                </div>
              </PresetButton>
              <PresetButton
                type="button"
                active={activeTimePreset === 'fullday'}
                onClick={() => handleTimePreset('fullday')}
              >
                종일 진료
                <div style={{ fontSize: '12px', marginTop: '4px' }}>
                  09:00 - 18:00
                </div>
              </PresetButton>
              <PresetButton
                type="button"
                active={showCustomTime}
                onClick={() => setShowCustomTime(!showCustomTime)}
              >
                {showCustomTime ? '간단 설정' : '상세 설정'}
              </PresetButton>
            </PresetContainer>

            {showCustomTime && (
              <TimeSettingsContainer>
                <TimeInputGroup>
                  <TimeLabel>진료 시작</TimeLabel>
                  <TimeSelect
                    value={formData.work_start_time}
                    onChange={e => setFormData(prev => ({ ...prev, work_start_time: e.target.value }))}
                  >
                    {generateTimeOptions().map(({ value, label }) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </TimeSelect>
                </TimeInputGroup>

                <TimeInputGroup>
                  <TimeLabel>진료 종료</TimeLabel>
                  <TimeSelect
                    value={formData.work_end_time}
                    onChange={e => setFormData(prev => ({ ...prev, work_end_time: e.target.value }))}
                  >
                    {generateTimeOptions().map(({ value, label }) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </TimeSelect>
                </TimeInputGroup>

                <TimeInputGroup>
                  <TimeLabel>점심 시작</TimeLabel>
                  <TimeSelect
                    value={formData.lunch_start}
                    onChange={e => setFormData(prev => ({ ...prev, lunch_start: e.target.value }))}
                  >
                    {generateTimeOptions().map(({ value, label }) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </TimeSelect>
                </TimeInputGroup>

                <TimeInputGroup>
                  <TimeLabel>점심 종료</TimeLabel>
                  <TimeSelect
                    value={formData.lunch_end}
                    onChange={e => setFormData(prev => ({ ...prev, lunch_end: e.target.value }))}
                  >
                    {generateTimeOptions().map(({ value, label }) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </TimeSelect>
                </TimeInputGroup>
              </TimeSettingsContainer>
            )}
          </FormGroup>

          <ButtonGroup>
            <Button type="button" secondary onClick={onClose}>
              취소
            </Button>
            <Button type="submit" primary>
              {isEditing ? '수정' : '등록'}
            </Button>
          </ButtonGroup>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default DoctorForm; 