import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const StatCard = ({ title, value, icon }) => {
  return (
    <Card>
      <div>{icon}</div>
      <h3>{title}</h3>
      <div>{value}</div>
    </Card>
  );
};

export default StatCard; 