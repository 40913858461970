import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import MedicalRecordDetail from './MedicalRecordDetail';

const Container = styled.div`
  padding: 24px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #eee;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #eee;
`;

const Tr = styled.tr`
  cursor: pointer;
  &:hover {
    background: #f5f5f7;
  }
`;

const MedicalRecordList = () => {
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await makeApiRequest('/api/medical/records');
        if (response.data.success) {
          setRecords(response.data.data);
        } else {
          throw new Error(response.data.message || '진료 기록을 불러오는데 실패했습니다.');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  if (loading) return <div>로딩 중...</div>;
  if (error) return <div>에러: {error}</div>;

  return (
    <Container>
      <h2>진료 기록</h2>
      <Table>
        <thead>
          <tr>
            <Th>날짜</Th>
            <Th>환자명</Th>
            <Th>진료과</Th>
            <Th>담당의</Th>
            <Th>진단명</Th>
          </tr>
        </thead>
        <tbody>
          {records.map(record => (
            <Tr key={record.id} onClick={() => setSelectedRecord(record)}>
              <Td>{record.date}</Td>
              <Td>{record.patientName}</Td>
              <Td>{record.department}</Td>
              <Td>{record.doctor}</Td>
              <Td>{record.diagnosis}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>

      {selectedRecord && (
        <MedicalRecordDetail 
          record={selectedRecord} 
          onClose={() => setSelectedRecord(null)}
        />
      )}
    </Container>
  );
};

export default MedicalRecordList; 