import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;  // 최소 높이 설정
  min-width: 280px;   // 최소 너비 설정
  height: ${props => props.isMinimized ? '40px' : `${props.height}px`};
  width: ${props => `${props.width}px`};
  background: #f5f6f8;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;

  // 리사이즈 핸들 스타일 수정
  .resize-handle {
    position: absolute;
    background: transparent;
    z-index: 100;  // z-index 증가

    &.top {
      top: -3px;
      left: 0;
      right: 0;
      height: 6px;
      cursor: n-resize;
    }

    &.right {
      top: 0;
      right: -3px;
      bottom: 0;
      width: 6px;
      cursor: e-resize;
    }

    &.bottom {
      bottom: -3px;
      left: 0;
      right: 0;
      height: 6px;
      cursor: s-resize;
    }

    &.left {
      top: 0;
      left: -3px;
      bottom: 0;
      width: 6px;
      cursor: w-resize;
    }

    &.top-left {
      top: -3px;
      left: -3px;
      width: 10px;
      height: 10px;
      cursor: nw-resize;
    }

    &.top-right {
      top: -3px;
      right: -3px;
      width: 10px;
      height: 10px;
      cursor: ne-resize;
    }

    &.bottom-left {
      bottom: -3px;
      left: -3px;
      width: 10px;
      height: 10px;
      cursor: sw-resize;
    }

    &.bottom-right {
      bottom: -3px;
      right: -3px;
      width: 10px;
      height: 10px;
      cursor: se-resize;
    }

    &:hover {
      background: rgba(0, 122, 255, 0.1);
    }
  }

  // 모든 방향에서 크기 조절 가능하도록 테두리 추가
  &:hover {
    border: 1px solid #007AFF;
  }
`;

const Header = styled.div`
  padding: 12px 16px;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
  user-select: none;

  .header-left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .language-info {
    font-size: 11px;
    color: #666;
    padding: 2px 6px;
    background: #f1f3f5;
    border-radius: 4px;
  }

  &:active {
    cursor: grabbing;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  color: #000;
  font-weight: 600;
`;

const SubTitle = styled.span`
  font-size: 12px;
  color: #666;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #666;
  font-size: 20px;
  
  &:hover {
    color: #000;
  }
`;

const MinimizeButton = styled.button`
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  color: #666;
  margin-right: 8px;
  
  &:hover {
    color: #000;
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #abc0d1;
  min-height: 200px;  // 최소 높이 설정
`;

const MessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  ${props => props.isMine ? `
    align-items: flex-end;
  ` : `
    align-items: flex-start;
  `}
`;

const MessageBubble = styled.div`
  max-width: 70%;
  margin: 2px 0;
  padding: 10px 16px;
  border-radius: 16px;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  transition: background-color 0.2s;
  position: relative;
  
  ${props => props.isMine ? `
    background: #fee500;
    color: #000;
    border-top-right-radius: 4px;
    &:hover {
      background: #ffd900;
    }
  ` : `
    background: white;
    color: #000;
    border-top-left-radius: 4px;
    &:hover {
      background: #f8f9fa;
    }
  `}

  &:hover::after {
    content: '${props => props.isToggled ? "원문 보기" : "번역본 보기"}';
    position: absolute;
    bottom: -18px;
    ${props => props.isMine ? 'right: 0;' : 'left: 0;'};
    font-size: 11px;
    color: #999;
  }
`;

const MessageInfo = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
  gap: 4px;
  ${props => props.isMine ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
`;

const SenderName = styled.span`
  font-size: 13px;
  color: #666;
  margin-bottom: 4px;
  padding-left: 12px;  // 말풍선과 같은 위치에 맞추기
`;

const MessageTime = styled.span`
  font-size: 11px;
  color: #999;
`;

const InputContainer = styled.div`
  padding: 12px 16px;
  background: white;
  border-top: 1px solid #e6e6e6;
  display: flex;
  gap: 12px;
  align-items: flex-end;
  flex-shrink: 0;
  min-height: 60px;  // 최소 높이 설정
`;

const Input = styled.textarea`
  flex: 1;
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  resize: none;
  font-size: 14px;
  line-height: 1.5;
  min-height: 20px;
  max-height: 100px;
  background: #f8f9fa;
  
  &:focus {
    outline: none;
    border-color: #fee500;
    background: white;
  }
`;

const SendButton = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 20px;
  background: #fee500;
  color: #000;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  
  &:hover {
    background: #fada0a;
  }
  
  &:disabled {
    background: #f1f3f5;
    color: #adb5bd;
    cursor: not-allowed;
  }
`;

const TranslatedMessage = styled.div`
  margin-top: 4px;
  padding-top: 4px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
  color: #666;
  font-style: italic;  // 원문/번역본 구분을 위한 스타일 추가
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  max-width: 80%;
  ${props => props.isMine && `
    flex-direction: row-reverse;
  `}
`;

const TranslateHint = styled.span`
  font-size: 11px;
  color: #999;
  margin-left: 4px;
`;

const ChatRoom = ({ roomId, chatInfo, onClose, currentUser, onDragStart, onDrag }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [roomInfo, setRoomInfo] = useState(null);
  const messagesEndRef = useRef(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [size, setSize] = useState({ width: 350, height: 500 });
  const [resizing, setResizing] = useState(false);
  const chatRef = useRef(null);
  const [toggledMessages, setToggledMessages] = useState(new Set());
  const initialScrollDone = useRef(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const loadInitialData = async () => {
    try {
      const roomResponse = await makeApiRequest(`/api/chat/rooms/${roomId}`);
      if (roomResponse.data.success) {
        setRoomInfo({
          ...roomResponse.data.data,
          userName: chatInfo?.patientName || roomResponse.data.data.userName,
          userLanguage: chatInfo?.patientLanguage || roomResponse.data.data.userLanguage
        });
      }

      const messagesResponse = await makeApiRequest(`/api/chat/messages?roomId=${roomId}`);
      if (messagesResponse.data.success) {
        setMessages(messagesResponse.data.data);
        if (!initialScrollDone.current) {
          setTimeout(() => {
            scrollToBottom();
            initialScrollDone.current = true;
          }, 100);
        }
      }
    } catch (error) {
      toast.error('데이터 로드에 실패했습니다.');
    }
  };

  useEffect(() => {
    if (roomId) {
      loadInitialData();
      const interval = setInterval(loadMessages, 5000);
      return () => {
        clearInterval(interval);
        initialScrollDone.current = false;
      };
    }
  }, [roomId, chatInfo]);

  useEffect(() => {
    console.log('ChatRoom currentUser:', currentUser);
  }, [currentUser]);

  const loadMessages = async () => {
    try {
      const response = await makeApiRequest(`/api/chat/messages?roomId=${roomId}`);
      if (response.data.success) {
        setMessages(response.data.data);
      }
    } catch (error) {
      console.error('메시지 로드 실패:', error);
    }
  };

  const handleSend = async () => {
    if (!newMessage.trim() || !currentUser) return;
    
    try {
      setLoading(true);
      const messageData = {
        roomId,
        message: newMessage,
        messageType: 'text',
        senderId: currentUser.userId
      };

      const response = await makeApiRequest('/api/chat/messages', {
        method: 'POST',
        data: messageData
      });

      if (response.data.success) {
        setNewMessage('');
        await loadMessages();
        scrollToBottom();
      } else {
        toast.error('메시지 전송에 실패했습니다.');
      }
    } catch (error) {
      toast.error('메시지 전송에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleResizeStart = (e, direction) => {
    e.preventDefault();
    e.stopPropagation();
    
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = chatRef.current.offsetWidth;
    const startHeight = chatRef.current.offsetHeight;
    const startPosition = chatRef.current.getBoundingClientRect();
    
    const handleMouseMove = (moveEvent) => {
      let newWidth = startWidth;
      let newHeight = startHeight;
      let deltaX = moveEvent.clientX - startX;
      let deltaY = moveEvent.clientY - startY;

      switch (direction) {
        case 'right':
          newWidth = startWidth + deltaX;
          break;
        case 'bottom':
          newHeight = startHeight + deltaY;
          break;
        case 'left':
          newWidth = startWidth - deltaX;
          break;
        case 'top':
          newHeight = startHeight - deltaY;
          break;
        case 'top-left':
          newWidth = startWidth - deltaX;
          newHeight = startHeight - deltaY;
          break;
        case 'top-right':
          newWidth = startWidth + deltaX;
          newHeight = startHeight - deltaY;
          break;
        case 'bottom-left':
          newWidth = startWidth - deltaX;
          newHeight = startHeight + deltaY;
          break;
        case 'bottom-right':
          newWidth = startWidth + deltaX;
          newHeight = startHeight + deltaY;
          break;
      }

      // 최소/최대 크기 제한
      newWidth = Math.max(280, Math.min(800, newWidth));
      newHeight = Math.max(300, Math.min(window.innerHeight * 0.9, newHeight));

      setSize({ width: newWidth, height: newHeight });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const getLanguageText = (code) => {
    const languageMap = {
      'ar': '아랍어',
      'de': '독일어',
      'en': '영어',
      'es': '스페인어',
      'fr': '프랑스어',
      'he': '히브리어',
      'hi': '힌디어',
      'it': '이탈리아어',
      'ja': '일본어',
      'ko': '한국어',
      'nl': '네덜란드어',
      'pt': '포르투갈어',
      'ru': '러시아어',
      'zh': '중국어'
    };
    return languageMap[code] || code;
  };

  // 메시지 토글 핸들러
  const handleMessageClick = (messageId) => {
    setToggledMessages(prev => {
      const newToggled = new Set(prev);
      if (newToggled.has(messageId)) {
        newToggled.delete(messageId);
      } else {
        newToggled.add(messageId);
      }
      return newToggled;
    });
  };

  // 메시지 내용 표시 함수 수정
  const renderMessageContent = (message, isMine, isToggled) => {
    let translatedText = null;
    const userLanguage = currentUser?.preferred_language || 'ko';
    
    if (message.translated_content) {
      try {
        const translations = typeof message.translated_content === 'string' 
          ? JSON.parse(message.translated_content) 
          : message.translated_content;
        
        // 내가 보낸 메시지인 경우, 다른 언어로의 번역본을 보여줌
        if (isMine) {
          const availableTranslations = Object.entries(translations)
            .filter(([lang]) => lang !== 'ko');
          if (availableTranslations.length > 0) {
            translatedText = availableTranslations[0][1];
          }
        } else {
          // 상대방이 보낸 메시지는 내 언어로 된 번역을 보여줌
          translatedText = translations[userLanguage];
        }
      } catch (e) {
        console.error('번역 데이터 파싱 실패:', e);
      }
    }

    // 내가 보낸 메시지
    if (isMine) {
      return (
        <div>
          {isToggled && translatedText ? translatedText : message.message}
        </div>
      );
    } 
    // 상대방이 보낸 메시지
    else {
      const showTranslation = message.original_language !== userLanguage;
      return (
        <div>
          {isToggled ? message.message : (showTranslation && translatedText ? translatedText : message.message)}
        </div>
      );
    }
  };

  return (
    <ChatContainer 
      ref={chatRef}
      isMinimized={isMinimized}
      width={size.width}
      height={size.height}
    >
      <Header
        draggable="true"
        onDragStart={onDragStart}
        onDrag={onDrag}
      >
        <div className="header-left">
          <Title>
            {chatInfo?.patientName ? `${chatInfo.patientName}님과의 대화` : (roomInfo?.title || '채팅')}
          </Title>
          <div className="user-info">
            <span>{chatInfo?.patientName || roomInfo?.userName || '알 수 없음'}</span>
            {(chatInfo?.patientLanguage || roomInfo?.userLanguage) !== 'ko' && (
              <span className="language-info">
                {getLanguageText(chatInfo?.patientLanguage || roomInfo?.userLanguage)}
              </span>
            )}
          </div>
        </div>
        <div>
          <MinimizeButton onClick={() => setIsMinimized(!isMinimized)}>
            {isMinimized ? '□' : '−'}
          </MinimizeButton>
          <CloseButton onClick={onClose}>×</CloseButton>
        </div>
      </Header>
      
      {!isMinimized && (
        <>
          <div className="resize-handle top" onMouseDown={e => handleResizeStart(e, 'top')} />
          <div className="resize-handle right" onMouseDown={e => handleResizeStart(e, 'right')} />
          <div className="resize-handle bottom" onMouseDown={e => handleResizeStart(e, 'bottom')} />
          <div className="resize-handle left" onMouseDown={e => handleResizeStart(e, 'left')} />
          <div className="resize-handle top-left" onMouseDown={e => handleResizeStart(e, 'top-left')} />
          <div className="resize-handle top-right" onMouseDown={e => handleResizeStart(e, 'top-right')} />
          <div className="resize-handle bottom-left" onMouseDown={e => handleResizeStart(e, 'bottom-left')} />
          <div className="resize-handle bottom-right" onMouseDown={e => handleResizeStart(e, 'bottom-right')} />
          <MessagesContainer>
            {messages.map((message, index) => {
              const isMine = message.sender_id === currentUser?.userId;
              const isToggled = toggledMessages.has(message.message_id);
              
              return (
                <MessageGroup key={message.message_id} isMine={isMine}>
                  {!isMine && (
                    <SenderName>
                      {message.sender_name || '알 수 없음'}
                      {message.sender_language && message.sender_language !== 'ko' && 
                        ` (${getLanguageText(message.sender_language)})`}
                    </SenderName>
                  )}
                  <MessageContainer isMine={isMine}>
                    <MessageBubble 
                      isMine={isMine}
                      isToggled={isToggled}
                      onClick={() => handleMessageClick(message.message_id)}
                    >
                      {renderMessageContent(message, isMine, isToggled)}
                    </MessageBubble>
                    <MessageInfo isMine={isMine}>
                      <MessageTime>
                        {new Date(message.created_at).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </MessageTime>
                    </MessageInfo>
                  </MessageContainer>
                </MessageGroup>
              );
            })}
            <div ref={messagesEndRef} />
          </MessagesContainer>

          <InputContainer>
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="메시지를 입력하세요..."
              disabled={loading || !currentUser}
            />
            <SendButton 
              type="button"
              onClick={handleSend}
              disabled={loading || !newMessage.trim() || !currentUser}
            >
              ↑
            </SendButton>
          </InputContainer>
        </>
      )}
    </ChatContainer>
  );
};

export default ChatRoom; 