const config = {
  development: {
    API_URL: 'http://localhost:3000'
  },
  production: {
    API_URL: 'https://api.production.com'
  },
  test: {
    API_URL: 'http://mock-api.test'
  }
};

const environment = process.env.NODE_ENV || 'development';
console.log('Current Environment:', environment);

export const API_URL = process.env.REACT_APP_API_URL || config[environment].API_URL;
export const API_BASE_URL = `${API_URL}/api`;

console.log('API URL:', API_URL);
console.log('API Base URL:', API_BASE_URL); 