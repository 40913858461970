import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  color: #1a1a1a;
`;

const TreatmentList = styled.div`
  margin-top: 20px;
`;

const TreatmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const TreatmentForm = styled.form`
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}
  
  ${props => props.danger && `
    background: #dc3545;
    color: white;
    &:hover { background: #c82333; }
  `}
`;

const TreatmentModal = ({ department, onClose }) => {
  const [treatments, setTreatments] = useState([]);
  const [newTreatment, setNewTreatment] = useState('');
  const [editingTreatment, setEditingTreatment] = useState(null);

  useEffect(() => {
    loadTreatments();
  }, [department.department_id]);

  const loadTreatments = async () => {
    try {
      const response = await makeApiRequest(`/api/departments/${department.department_id}/treatments`);
      if (response.data.success) {
        setTreatments(response.data.data);
      }
    } catch (error) {
      toast.error('진료 항목을 불러오는데 실패했습니다.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingTreatment) {
        await makeApiRequest(`/api/departments/${department.department_id}/treatments/${editingTreatment.id}`, {
          method: 'PUT',
          data: { name: newTreatment }
        });
        toast.success('진료 항목이 수정되었습니다.');
      } else {
        await makeApiRequest(`/api/departments/${department.department_id}/treatments`, {
          method: 'POST',
          data: { name: newTreatment }
        });
        toast.success('진료 항목이 추가되었습니다.');
      }
      setNewTreatment('');
      setEditingTreatment(null);
      loadTreatments();
    } catch (error) {
      toast.error('진료 항목 저장에 실패했습니다.');
    }
  };

  const handleDelete = async (treatmentId) => {
    if (window.confirm('이 진료 항목을 삭제하시겠습니까?')) {
      try {
        await makeApiRequest(`/api/departments/${department.department_id}/treatments/${treatmentId}`, {
          method: 'DELETE'
        });
        toast.success('진료 항목이 삭제되었습니다.');
        loadTreatments();
      } catch (error) {
        toast.error('진료 항목 삭제에 실패했습니다.');
      }
    }
  };

  return (
    <Modal>
      <ModalContent>
        <Header>
          <Title>{department.department_name} - 진료 항목 관리</Title>
          <Button onClick={onClose}>닫기</Button>
        </Header>

        <TreatmentForm onSubmit={handleSubmit}>
          <Input
            type="text"
            value={newTreatment}
            onChange={(e) => setNewTreatment(e.target.value)}
            placeholder="새 진료 항목 입력"
            required
          />
          <Button type="submit" primary>
            {editingTreatment ? '수정' : '추가'}
          </Button>
        </TreatmentForm>

        <TreatmentList>
          {treatments.map(treatment => (
            <TreatmentItem key={treatment.id}>
              <span>{treatment.name}</span>
              <div>
                <Button
                  onClick={() => {
                    setEditingTreatment(treatment);
                    setNewTreatment(treatment.name);
                  }}
                >
                  수정
                </Button>
                <Button
                  danger
                  onClick={() => handleDelete(treatment.id)}
                >
                  삭제
                </Button>
              </div>
            </TreatmentItem>
          ))}
        </TreatmentList>
      </ModalContent>
    </Modal>
  );
};

export default TreatmentModal; 