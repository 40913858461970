import React from 'react';
import styled from 'styled-components';
import { logout } from '../../services/authService';

const LogoutButton = () => {
  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('로그아웃 실패:', error);
      // 에러가 발생해도 로컬 스토리지는 클리어
      localStorage.clear();
      window.location.href = '/';
    }
  };

  return (
    <StyledButton onClick={handleLogout}>
      로그아웃
    </StyledButton>
  );
};

const StyledButton = styled.button`
  padding: 8px 16px;
  background-color: #ff3b30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 20px;
  right: 20px;
  
  &:hover {
    background-color: #ff2419;
  }
`;

export default LogoutButton; 