import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import HospitalPage from './pages/HospitalPage';
import CompanyPage from './pages/CompanyPage';
import FeaturesPage from './pages/FeaturesPage';
import ReviewsPage from './pages/ReviewsPage';
import NoticePage from './pages/NoticePage';
import DownloadPage from './pages/DownloadPage';
import ApiDocPage from './pages/ApiDocPage';
import Dashboard from './dashboard';
import image12 from './assets/images/image 12.png';
import image9 from './assets/images/image 9.png';
import image13 from './assets/images/image 13.png';
import rectangle41 from './assets/images/Rectangle-41.png';
import group9 from './assets/images/Group 9.png';
import image2 from './assets/images/image-2.png';
import image3 from './assets/images/image-3.png';

const GlobalContainer = styled.div`
  font-family: "SF Pro KR", "SF Pro Text", "SF Pro Icons", -apple-system, BlinkMacSystemFont, "Noto Sans KR", "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
`;

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.section`
  height: 100vh;
  background-image: url(${rectangle41});
  background-size: cover;
  background-position: center;
  color: #1d1d1f;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 44px;
`;

const Title = styled.h1`
  font-size: 56px;
  line-height: 1.07143;
  font-weight: 600;
  letter-spacing: -.005em;
  margin-bottom: 8px;
  
  @media (max-width: 1068px) {
    font-size: 48px;
  }
  
  @media (max-width: 734px) {
    font-size: 40px;
  }
`;

const SubTitle = styled.h2`
  font-size: 28px;
  line-height: 1.10722;
  font-weight: 400;
  letter-spacing: .004em;
  margin-bottom: 20px;
  
  @media (max-width: 1068px) {
    font-size: 24px;
  }
`;

const DownloadButton = styled.button`
  background: #0071e3;
  color: #fff;
  font-size: 17px;
  line-height: 1.17648;
  font-weight: 400;
  letter-spacing: -.022em;
  min-width: 28px;
  padding: 12px 22px;
  border-radius: 980px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #0077ED;
  }
`;

const InfoSection = styled.section`
  padding: 100px 0;
  max-width: 980px;
  margin: 0 auto;
  
  @media (max-width: 1068px) {
    padding: 80px 40px;
  }
  
  @media (max-width: 734px) {
    padding: 60px 20px;
  }
`;

const InfoCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  gap: 60px;
  
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  img {
    width: 48%;
    height: auto;
    border-radius: 18px;
  }
  
  @media (max-width: 734px) {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 60px;
    
    &:nth-child(even) {
      flex-direction: column;
    }
    
    img {
      width: 100%;
    }
  }
`;

const InfoContent = styled.div`
  flex: 1;
`;

const InfoTitle = styled.h3`
  font-size: 28px;
  line-height: 1.14286;
  font-weight: 600;
  letter-spacing: .007em;
  color: #1d1d1f;
  margin-bottom: 16px;
  
  @media (max-width: 734px) {
    font-size: 24px;
  }
`;

const InfoText = styled.p`
  font-size: 17px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -.022em;
  color: #86868b;
`;

const ContentSection = styled.section`
  background: #fafafa;
  padding: 100px 0;
  text-align: center;
  
  @media (max-width: 734px) {
    padding: 60px 0;
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;
  
  @media (max-width: 834px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 734px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

const FeatureCard = styled.div`
  background: #fff;
  border-radius: 18px;
  padding: 30px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.02);
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.12);
  }
  
  img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 12px;
    color: #1d1d1f;
  }
  
  p {
    font-size: 14px;
    line-height: 1.42859;
    font-weight: 400;
    letter-spacing: -.016em;
    color: #86868b;
  }
`;

const App = () => {
  return (
    <GlobalContainer>
      <MainContainer>
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection>
                <Title>다양한 언어, 하나의 소통</Title>
                <SubTitle>INTER MEDIC</SubTitle>
                <DownloadButton>DOWNLOAD</DownloadButton>
              </HeroSection>
              
              <InfoSection>
                <InfoCard>
                 <img src={image2} alt="다국적 환자의 기술" />
                  <InfoContent>
                    <InfoTitle>다국적 환자를 위한 기술</InfoTitle>
                    <InfoText>
                      INTERMEDIC의 첨단 번역 시스템으로 의료진과 환자 간의 원활한 소통 지원
                    </InfoText>
                  </InfoContent>
                </InfoCard>

                <InfoCard>
                 <img src={image3}  alt="언어 장벽 없는 진료" />
                  <InfoContent>
                    <InfoTitle>언어 장벽 없는 진료, 기술로 완성하다</InfoTitle>
                    <InfoText>
                      1:1 실시간 채팅을 통한 각 환자에게 맞춤형 의료 서비스를 제공
                    </InfoText>
                  </InfoContent>
                </InfoCard>

                <InfoCard>
                   <img src={group9} alt="최첨단 기술" />
                  <InfoContent>
                    <InfoTitle>최첨단 기술로 언어의 경계를 넘어, 글로벌 의료의 선두에 서다</InfoTitle>
                    <InfoText>
                      다양한 언어의 환자 관리 시스템을 통해 언어 장벽 없는 진료 환경을 제공하며, 글로벌 시대에 걸맞는 의료 프로그램으로 발전
                    </InfoText>
                  </InfoContent>
                </InfoCard>
              </InfoSection>


              <ContentSection>
                <FeatureGrid>
                  <FeatureCard>
                    <img src={image12} alt="자동 번역" />
                    <h3>AUTOMATIC TRANSLATION</h3>
                    <p>실시간 1:1 채팅 자동 번역시스템 제공</p>
                  </FeatureCard>
                  <FeatureCard>
                    <img src={image9} alt="환자 관리" />
                    <h3>MULTINATIONAL PATIENT MANAGEMENT</h3>
                    <p>다국적 환자 관리시스템 제공</p>
                  </FeatureCard>
                  <FeatureCard>
                    <img src={image13} alt="AI 통역" />
                    <h3>AI INTERPRETATION</h3>
                    <p>인공지능을 통한 외국인 환자 대상 통역 시스템</p>
                  </FeatureCard>
                </FeatureGrid>
              </ContentSection>

            </>
          } />
          <Route path="/hospital" element={<HospitalPage />} />
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/reviews" element={<ReviewsPage />} />
          <Route path="/notice" element={<NoticePage />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/api_doc" element={<ApiDocPage />} />
        </Routes>
        <Footer />
      </MainContainer>
    </GlobalContainer>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
