import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FormContainer = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #1a1a1a;
  margin-bottom: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #4a4a4a;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const TextArea = styled.textarea`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-height: 100px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  
  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}
  
  ${props => props.secondary && `
    background: #e9ecef;
    color: #495057;
    &:hover { background: #dee2e6; }
  `}
`;

const TreatmentForm = ({ onClose, onSubmit, initialData, departmentId }) => {
  const [formData, setFormData] = useState({
    treatment_name: '',
    description: ''
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        treatment_name: initialData.treatment_name || '',
        description: initialData.description || ''
      });
    }
  }, [initialData]);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      department_id: departmentId
    });
  };

  return (
    <Modal>
      <FormContainer>
        <Title>{initialData ? '진료 항목 수정' : '새 진료 항목 추가'}</Title>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>진료 항목명</Label>
            <Input
              type="text"
              value={formData.treatment_name}
              onChange={(e) => handleChange('treatment_name', e.target.value)}
              required
              placeholder="진료 항목명을 입력하세요"
            />
          </FormGroup>

          <FormGroup>
            <Label>설명</Label>
            <TextArea
              value={formData.description}
              onChange={(e) => handleChange('description', e.target.value)}
              placeholder="진료 항목에 대한 설명을 입력하세요"
            />
          </FormGroup>

          <ButtonGroup>
            <Button type="button" secondary onClick={onClose}>
              취소
            </Button>
            <Button type="submit" primary>
              {initialData ? '수정' : '추가'}
            </Button>
          </ButtonGroup>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default TreatmentForm; 