import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1d1d1f;
  margin: 0 0 24px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #1d1d1f;
  font-weight: 500;
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  max-width: 400px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const Select = styled.select`
  padding: 12px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  max-width: 400px;
  
  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

const Button = styled.button`
  padding: 12px 24px;
  background: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  width: fit-content;
  
  &:hover {
    background: #0066CC;
  }
`;

const GeneralSettings = () => {
  const [settings, setSettings] = useState({
    hospitalName: '메디컬 유니버스 병원',
    address: '서울시 강남구 테헤란로 123',
    phone: '02-1234-5678',
    email: 'contact@medicaluniverse.com',
    timezone: 'Asia/Seoul',
    language: 'ko'
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Settings updated:', settings);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Container>
      <Title>일반 설정</Title>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>병원명</Label>
          <Input
            type="text"
            name="hospitalName"
            value={settings.hospitalName}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>주소</Label>
          <Input
            type="text"
            name="address"
            value={settings.address}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>연락처</Label>
          <Input
            type="tel"
            name="phone"
            value={settings.phone}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>이메일</Label>
          <Input
            type="email"
            name="email"
            value={settings.email}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>시간대</Label>
          <Select
            name="timezone"
            value={settings.timezone}
            onChange={handleChange}
          >
            <option value="Asia/Seoul">한국 표준시 (KST)</option>
            <option value="UTC">세계 표준시 (UTC)</option>
            <option value="America/New_York">미국 동부시 (EST)</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>기본 언어</Label>
          <Select
            name="language"
            value={settings.language}
            onChange={handleChange}
          >
            <option value="ko">한국어</option>
            <option value="en">English</option>
            <option value="zh">中文</option>
            <option value="ja">日本語</option>
          </Select>
        </FormGroup>

        <Button type="submit">저장</Button>
      </Form>
    </Container>
  );
};

export default GeneralSettings; 