import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/images/logo.png';
import LoginModal from './auth/LoginModal';

const HeaderContainer = styled.header`
  background-color: rgba(251, 251, 253, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 44px;
  border-bottom: 1px solid #e1e1e1;
  min-width: 320px;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

const HeaderWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 22px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 833px) {
    padding: 0 16px;
  }
`;

const Logo = styled(Link)`
  img {
    height: 24px;
    width: auto;
    opacity: 0.8;
    transition: opacity 0.3s;
    
    &:hover {
      opacity: 1;
    }
  }
`;

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  gap: 28px;
  
  @media (max-width: 833px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -.01em;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  padding: 0;
  transition: color 0.3s;
  
  &:hover {
    color: rgb(0, 102, 204);
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  
  @media (max-width: 833px) {
    display: block;
  }
`;

const LanguageButton = styled.button`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -.01em;
  color: rgba(0, 0, 0, 0.8);
  background: none;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  
  &:hover {
    color: rgb(0, 102, 204);
  }
  
  &::after {
    content: "▾";
    font-size: 14px;
    margin-left: 4px;
  }
`;

const LanguageDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  min-width: 120px;
  opacity: ${props => props.$isOpen ? 1 : 0};
  visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
  transform: translateY(${props => props.$isOpen ? '0' : '-10px'});
  transition: all 0.2s ease;
`;

const LanguageOption = styled.button`
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  text-align: left;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgb(0, 102, 204);
  }
`;

const NavContainer = styled.div`
  display: ${props => props.$isOpen ? 'block' : 'none'};
`;

const LoginButton = styled.button`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -.01em;
  line-height: 1;
  color: rgb(0, 102, 204);
  background: none;
  border: 1px solid rgb(0, 102, 204);
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    background-color: rgb(0, 102, 204);
    color: white;
  }
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const MobileMenu = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: ${props => props.$isOpen ? 'block' : 'none'};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
`;

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('KOR');
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [targetPath, setTargetPath] = useState('');
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const languages = [
    { code: 'KOR', name: '한국어' },
    { code: 'ENG', name: 'English' },
    { code: 'JPN', name: '日本語' },
    { code: 'CHN', name: '中文' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleProtectedRoute = (path) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setTargetPath(path);
      setShowLoginModal(true);
      return false;
    }
    return true;
  };

  const handleLoginSuccess = (data) => {
    setShowLoginModal(false);
    if (targetPath) {
      navigate(targetPath);
      setTargetPath('');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsLoggedIn(false);
  };

  return (
    <HeaderContainer style={{
      boxShadow: isScrolled ? '0 1px 3px rgba(0, 0, 0, 0.1)' : 'none'
    }}>
      <HeaderWrapper>
        <Logo to="/">
          <img src={logo} alt="Medical Universe" />
        </Logo>
        
        <Navigation>
          <NavLink to="/">홈</NavLink>
          <NavLink to="/hospital">병원소개</NavLink>
          <NavLink to="/company">회사소개</NavLink>
          <NavLink to="/features">기능소개</NavLink>
          <NavLink to="/reviews">고객후기</NavLink>
          <NavLink to="/notice">공지사항</NavLink>
          <NavLink to="/download">다운로드</NavLink>
          {isLoggedIn && (
            <>
              <NavLink 
                to="/dashboard" 
                onClick={(e) => {
                  e.preventDefault();
                  if (handleProtectedRoute('/dashboard')) {
                    navigate('/dashboard');
                  }
                }}
              >
                대시보드
              </NavLink>
              <NavLink 
                to="/api_doc"
                onClick={(e) => {
                  e.preventDefault();
                  if (handleProtectedRoute('/api_doc')) {
                    navigate('/api_doc');
                  }
                }}
              >
                API 문서
              </NavLink>
            </>
          )}
        </Navigation>

        <UserSection>
          <div style={{ position: 'relative' }}>
            <LanguageButton onClick={() => setIsLanguageOpen(!isLanguageOpen)}>
              <span>{currentLanguage}</span>
            </LanguageButton>
            <LanguageDropdown $isOpen={isLanguageOpen}>
              {languages.map((lang) => (
                <LanguageOption
                  key={lang.code}
                  onClick={() => {
                    setCurrentLanguage(lang.code);
                    setIsLanguageOpen(false);
                  }}
                >
                  {lang.name}
                </LanguageOption>
              ))}
            </LanguageDropdown>
          </div>

          {isLoggedIn ? (
            <LoginButton onClick={handleLogout}>로그아웃</LoginButton>
          ) : (
            <LoginButton onClick={() => setShowLoginModal(true)}>로그인</LoginButton>
          )}
        </UserSection>

        <MobileMenuButton aria-label="Menu" onClick={() => setIsOpen(!isOpen)}>
          <svg width="18" height="18" viewBox="0 0 18 18">
            <path d="M0 3h18v2H0zm0 5h18v2H0zm0 5h18v2H0z" fill="currentColor"/>
          </svg>
        </MobileMenuButton>

        {showLoginModal && (
          <LoginModal
            onClose={() => {
              setShowLoginModal(false);
              setTargetPath('');
            }}
            onLoginSuccess={(data) => {
              handleLoginSuccess(data);
              setIsLoggedIn(true);
            }}
          />
        )}
      </HeaderWrapper>
      
      <NavContainer $isOpen={isOpen}>
        <Navigation>
          <NavLink to="/">홈</NavLink>
          <NavLink to="/hospital">병원소개</NavLink>
          <NavLink to="/company">회사소개</NavLink>
          <NavLink to="/features">기능소개</NavLink>
          <NavLink to="/reviews">고객후기</NavLink>
          <NavLink to="/notice">공지사항</NavLink>
          <NavLink to="/download">다운로드</NavLink>
          {isLoggedIn ? (
            <>
              <NavLink to="/dashboard">대시보드</NavLink>
              <NavLink to="/api_doc">API 문서</NavLink>
              <LoginButton onClick={handleLogout}>로그아웃</LoginButton>
            </>
          ) : (
            <LoginButton onClick={() => setShowLoginModal(true)}>로그인</LoginButton>
          )}
        </Navigation>
      </NavContainer>
    </HeaderContainer>
  );
};

export default Header; 