import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1d1d1f;
  margin: 0 0 24px 0;
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UserCard = styled.div`
  padding: 16px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #666;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Name = styled.div`
  font-weight: 500;
`;

const Role = styled.div`
  font-size: 14px;
  color: #666;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  
  ${props => props.primary ? `
    background: #007AFF;
    color: white;
    border: none;
    
    &:hover {
      background: #0066CC;
    }
  ` : `
    background: white;
    color: #1d1d1f;
    border: 1px solid #e1e1e1;
    
    &:hover {
      background: #f5f5f7;
    }
  `}
`;

const AddButton = styled(Button)`
  margin-bottom: 24px;
`;

const UserSettings = () => {
  const [users] = useState([
    {
      id: 1,
      name: '김관리',
      email: 'admin@medicaluniverse.com',
      role: '관리자',
      department: '원무과'
    },
    {
      id: 2,
      name: '이의사',
      email: 'doctor.lee@medicaluniverse.com',
      role: '의사',
      department: '내과'
    },
    {
      id: 3,
      name: '박간호',
      email: 'nurse.park@medicaluniverse.com',
      role: '간호사',
      department: '내과'
    }
  ]);

  return (
    <Container>
      <Title>사용자 관리</Title>
      <AddButton primary>+ 새 사용자 추가</AddButton>
      
      <UserList>
        {users.map(user => (
          <UserCard key={user.id}>
            <UserInfo>
              <Avatar>{user.name[0]}</Avatar>
              <Details>
                <Name>{user.name}</Name>
                <Role>{user.role} · {user.department}</Role>
              </Details>
            </UserInfo>
            <Actions>
              <Button>수정</Button>
              <Button>권한</Button>
              <Button>삭제</Button>
            </Actions>
          </UserCard>
        ))}
      </UserList>
    </Container>
  );
};

export default UserSettings; 