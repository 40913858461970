import React, { useState } from 'react';
import styled from 'styled-components';
import Overview from '../components/api-docs/sections/Overview';
import ApiTable from '../components/api-docs/common/ApiTable';
import Authentication from '../components/api-docs/sections/Authentication';
import Database from '../components/api-docs/sections/Database';
import WebHooks from '../components/api-docs/sections/WebHooks';
import Examples from '../components/api-docs/sections/Examples';

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 60px);
  background: #f5f5f7;
  margin-top: 44px;
`;

const Sidebar = styled.div`
  width: 250px;
  background: white;
  padding: 24px;
  border-right: 1px solid #e1e1e1;
  position: sticky;
  top: 44px;
  height: calc(100vh - 44px);
  overflow-y: auto;
`;

const Content = styled.div`
  flex: 1;
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: white;
  border-bottom: 1px solid #e1e1e1;
`;

const LogoutButton = styled.button`
  padding: 8px 16px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background: #c82333;
  }
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  margin-bottom: 8px;
`;

const MenuLink = styled.a`
  display: block;
  padding: 8px 12px;
  color: ${props => props.$active ? '#007AFF' : '#1d1d1f'};
  background: ${props => props.$active ? '#f0f7ff' : 'transparent'};
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    background: #f5f5f7;
  }
`;

const ApiDocPage = () => {
  const [activeSection, setActiveSection] = useState('overview');

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'overview':
        return <Overview />;
      case 'authentication':
        return <Authentication />;
      case 'database':
        return <Database />;
      case 'api-reference':
        return <ApiTable />;
      case 'webhooks':
        return <WebHooks />;
      case 'examples':
        return <Examples />;
      default:
        return <Overview />;
    }
  };

  const menuItems = [
    { id: 'overview', label: '개요' },
    { id: 'authentication', label: '인증' },
    { id: 'database', label: '데이터베이스 구조' },
    { id: 'api-reference', label: 'API 레퍼런스' },
    { id: 'webhooks', label: '웹훅' },
    { id: 'examples', label: '예제' }
  ];

  return (
    <Container>
      <Sidebar>
        <TopBar>
          <h2>API 문서</h2>
          <LogoutButton onClick={handleLogout}>
            로그아웃
          </LogoutButton>
        </TopBar>
        <MenuList>
          {menuItems.map(item => (
            <MenuItem key={item.id}>
              <MenuLink
                $active={activeSection === item.id}
                onClick={() => setActiveSection(item.id)}
              >
                {item.label}
              </MenuLink>
            </MenuItem>
          ))}
        </MenuList>
      </Sidebar>
      <Content>
        {renderContent()}
      </Content>
    </Container>
  );
};

export default ApiDocPage;
