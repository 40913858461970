import React, { useState } from 'react';
import styled from 'styled-components';
import EventForm from './EventForm';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay } from 'date-fns';
import { ko } from 'date-fns/locale';

const Container = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  color: #1d1d1f;
  margin: 0;
`;

const Button = styled.button`
  padding: 12px 24px;
  background: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background: #0066CC;
  }
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background: #e1e1e1;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  overflow: hidden;
`;

const DayHeader = styled.div`
  background: #f5f7f9;
  padding: 12px;
  text-align: center;
  font-weight: 600;
  color: #1d1d1f;
`;

const DayCell = styled.div`
  background: white;
  min-height: 120px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  ${props => props.isToday && `
    background: #f0f7ff;
  `}
  
  ${props => props.isOtherMonth && `
    background: #fafafa;
    color: #86868b;
  `}
  
  ${props => props.isSelected && `
    background: #e3f2fd;
    border: 2px solid #007AFF;
  `}
  
  &:hover {
    background: ${props => props.isOtherMonth ? '#f5f5f5' : '#f0f7ff'};
  }
`;

const DateNumber = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  color: ${props => props.isToday ? '#007AFF' : 'inherit'};
  font-weight: ${props => props.isToday ? '600' : 'normal'};
`;

const EventItem = styled.div`
  background: ${props => props.type === 'appointment' ? '#e3f2fd' : '#e8f5e9'};
  color: ${props => props.type === 'appointment' ? '#1976d2' : '#388e3c'};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 4px;
  cursor: pointer;
  
  &:hover {
    filter: brightness(0.95);
  }
`;

const NavigationHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 16px;
`;

const StatusTabs = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 20px;
  width: 100%;
`;

const StatusTab = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  background: ${props => props.active ? '#007AFF' : '#f5f5f7'};
  color: ${props => props.active ? 'white' : '#1d1d1f'};
  transition: all 0.2s;

  &:hover {
    background: ${props => props.active ? '#0066CC' : '#e5e5e7'};
  }
`;

const MonthNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

const NavButton = styled.button`
  background: none;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  color: #1d1d1f;
  
  &:hover {
    background: #f5f5f7;
  }
`;

const MonthYearDisplay = styled.h3`
  margin: 0;
  min-width: 200px;
  text-align: center;
  font-size: 20px;
`;

const TodayButton = styled.button`
  padding: 8px 16px;
  background: #f5f5f7;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background: #e5e5e7;
  }
`;

const BottomSection = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: center;
`;

const ViewScheduleButton = styled.button`
  padding: 12px 24px;
  background: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    background: #0066CC;
  }
`;

const ScheduleView = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
`;

const ScheduleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const BackButton = styled.button`
  padding: 8px 16px;
  background: none;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    background: #f5f5f7;
  }
`;

const ScheduleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ScheduleItem = styled.div`
  padding: 16px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  background: ${props => {
    switch(props.type) {
      case '문의': return '#fff3e0';
      case '예약': return '#e3f2fd';
      case '외래': return '#e8f5e9';
      case '입원': return '#f3e5f5';
      case '귀가': return '#fbe9e7';
      default: return '#ffffff';
    }
  }};
`;

const ScheduleTime = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
`;

const ScheduleTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const ScheduleDetails = styled.div`
  font-size: 14px;
  color: #666;
`;

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showEventForm, setShowEventForm] = useState(false);
  const [activeStatus, setActiveStatus] = useState('문의');
  const [selectedDate, setSelectedDate] = useState(null);
  const [showSchedule, setShowSchedule] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  
  // 확장된 목업 데이터
  const mockEvents = [
    {
      id: 1,
      title: 'John Smith - 내과 진료',
      type: '외래',
      date: '2025-01-16',
      time: '09:30',
      language: 'English',
      status: '예약'
    },
    {
      id: 2,
      title: '통역사 미팅',
      type: '문의',
      date: '2025-01-16',
      time: '11:00',
      language: 'Korean'
    },
    {
      id: 3,
      title: 'Maria Garcia - 정형외과',
      type: '외래',
      date: '2025-01-16',
      time: '14:30',
      language: 'Spanish',
      status: '예약'
    },
    {
      id: 4,
      title: 'Li Wei - 입원 수속',
      type: '입원',
      date: '2025-01-17',
      time: '10:00',
      language: 'Chinese'
    },
    {
      id: 5,
      title: 'Tanaka - 퇴원 상담',
      type: '귀가',
      date: '2025-01-17',
      time: '15:00',
      language: 'Japanese'
    }
  ];

  // 날짜 선택 핸들러
  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  // 일정보기 버튼 핸들러
  const handleViewSchedule = () => {
    if (selectedDate) {
      const dateEvents = mockEvents.filter(event => 
        format(new Date(event.date), 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')
      );
      setSelectedEvents(dateEvents);
      setShowSchedule(true);
    }
  };

  // 필터링된 이벤트 가져오기
  const getFilteredEvents = (date) => {
    return mockEvents.filter(event => 
      format(new Date(event.date), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd') &&
      (activeStatus === '전체' || event.type === activeStatus)
    );
  };

  // 달력 데이터 생성
  const getDaysInMonth = (date) => {
    const start = startOfMonth(date);
    const end = endOfMonth(date);
    const days = eachDayOfInterval({ start, end });
    
    // 첫 주의 시작 요일까지 이전 달의 날짜를 추가
    const firstDayOfMonth = start.getDay();
    const prevMonthDays = Array(firstDayOfMonth).fill(null).map((_, index) => {
      return new Date(start.getFullYear(), start.getMonth(), -index);
    }).reverse();
    
    // 마지막 주의 남은 요일을 다음 달의 날짜로 채움
    const lastDayOfMonth = end.getDay();
    const nextMonthDays = Array(6 - lastDayOfMonth).fill(null).map((_, index) => {
      return new Date(end.getFullYear(), end.getMonth() + 1, index + 1);
    });
    
    return [...prevMonthDays, ...days, ...nextMonthDays];
  };

  const handlePrevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const handleToday = () => {
    setCurrentDate(new Date());
  };

  const weekDays = ['일', '월', '화', '수', '목', '금', '토'];
  const daysInMonth = getDaysInMonth(currentDate);

  const statusTypes = ['문의', '예약', '외래', '입원', '귀가'];

  // 뒤로가기 핸들러
  const handleBack = () => {
    setShowSchedule(false);
    setSelectedEvents([]);
  };

  if (showSchedule) {
    return (
      <ScheduleView>
        <ScheduleHeader>
          <BackButton onClick={handleBack}>
            ← 달력으로 돌아가기
          </BackButton>
          <h2>{format(selectedDate, 'yyyy년 M월 d일', { locale: ko })} 일정</h2>
        </ScheduleHeader>

        <ScheduleList>
          {selectedEvents.length > 0 ? (
            selectedEvents
              .sort((a, b) => a.time.localeCompare(b.time))
              .map(event => (
                <ScheduleItem key={event.id} type={event.type}>
                  <ScheduleTime>{event.time}</ScheduleTime>
                  <ScheduleTitle>{event.title}</ScheduleTitle>
                  <ScheduleDetails>
                    <div>유형: {event.type}</div>
                    {event.language && <div>언어: {event.language}</div>}
                    {event.status && <div>상태: {event.status}</div>}
                  </ScheduleDetails>
                </ScheduleItem>
              ))
          ) : (
            <div style={{ textAlign: 'center', color: '#666' }}>
              예약된 일정이 없습니다.
            </div>
          )}
        </ScheduleList>
      </ScheduleView>
    );
  }

  return (
    <Container>
      <Header>
        <Title>일정 관리</Title>
        <Button onClick={() => setShowEventForm(true)}>
          + 새 일정 등록
        </Button>
      </Header>

      <NavigationHeader>
        <StatusTabs>
          {statusTypes.map(status => (
            <StatusTab
              key={status}
              active={activeStatus === status}
              onClick={() => setActiveStatus(status)}
            >
              {status}
            </StatusTab>
          ))}
        </StatusTabs>

        <MonthNavigation>
          <NavButton onClick={handlePrevMonth}>&lt; 이전달</NavButton>
          <MonthYearDisplay>
            {format(currentDate, 'yyyy년 M월', { locale: ko })}
          </MonthYearDisplay>
          <NavButton onClick={handleNextMonth}>다음달 &gt;</NavButton>
          <TodayButton onClick={handleToday}>오늘</TodayButton>
        </MonthNavigation>
      </NavigationHeader>

      <CalendarGrid>
        {weekDays.map(day => (
          <DayHeader key={day}>{day}</DayHeader>
        ))}
        
        {daysInMonth.map((date, index) => {
          const isToday = isSameDay(date, new Date());
          const isCurrentMonth = isSameMonth(date, currentDate);
          const isSelected = selectedDate && isSameDay(date, selectedDate);
          
          return (
            <DayCell 
              key={index}
              isToday={isToday}
              isOtherMonth={!isCurrentMonth}
              isSelected={isSelected}
              onClick={() => handleDateClick(date)}
            >
              <DateNumber isToday={isToday}>
                {format(date, 'd')}
              </DateNumber>
              {getFilteredEvents(date).map(event => (
                <EventItem 
                  key={event.id} 
                  type={event.type}
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log('Event clicked:', event);
                  }}
                >
                  {event.title} ({event.time})
                </EventItem>
              ))}
            </DayCell>
          );
        })}
      </CalendarGrid>

      <BottomSection>
        <ViewScheduleButton 
          onClick={handleViewSchedule}
          disabled={!selectedDate}
        >
          <span>일정 보기</span>
          {selectedDate && (
            <span>({format(selectedDate, 'yyyy년 M월 d일', { locale: ko })})</span>
          )}
        </ViewScheduleButton>
      </BottomSection>

      {showEventForm && (
        <EventForm 
          onClose={() => setShowEventForm(false)}
          onSubmit={(data) => {
            console.log('New event data:', data);
            setShowEventForm(false);
          }}
          selectedDate={currentDate}
        />
      )}
    </Container>
  );
};

export default Calendar; 