import axios from 'axios';
import { API_URL } from '../config/config';

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/login`, { 
      email, 
      password 
    }, {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    });

    const { data } = response;

    if (!data.success) {
      throw new Error(data.message);
    }

    // 로그인 성공 시 필요한 정보 저장
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('userRole', data.user.roleId);
    localStorage.setItem('lastLogin', new Date().toISOString());
    
    return data;
  } catch (error) {
    console.error('로그인 오류 발생:', error.response?.data?.message || error.message);
    throw new Error(error.response?.data?.message || '로그인 중 오류가 발생했습니다.');
  }
};

export const checkAuth = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) return null;

    const response = await axios.get(`${API_URL}/api/auth/check`, {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (response.data.success) {
      return response.data.data;
    }
    return null;
  } catch (error) {
    console.error('인증 확인 실패:', error);
    return null;
  }
};

export const logout = async () => {
  try {
    const token = localStorage.getItem('token');
    if (token) {
      await axios.post(`${API_URL}/api/auth/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
    }
  } catch (error) {
    console.error('로그아웃 요청 실패:', error);
  } finally {
    // 로컬 스토리지 정리
    localStorage.clear();
    window.location.href = '/';
  }
};

export const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
};

export const isAuthenticated = () => {
  return localStorage.getItem('isAuthenticated') === 'true';
};

export const getUser = () => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};

export const getUserRole = () => {
  return localStorage.getItem('userRole');
};

export const hasAdminAccess = () => {
  const roleId = parseInt(localStorage.getItem('userRole'));
  return roleId === 1 || roleId === 2;
};
  