import React from 'react';
import styled from 'styled-components';

const AlertList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Alert = styled.div`
  padding: 16px;
  background: ${props => {
    switch (props.severity) {
      case 'error': return '#ffebee';
      case 'warning': return '#fff3e0';
      case 'info': return '#e3f2fd';
      default: return '#f5f7f9';
    }
  }};
  border-radius: 8px;
  border-left: 4px solid ${props => {
    switch (props.severity) {
      case 'error': return '#f44336';
      case 'warning': return '#ff9800';
      case 'info': return '#2196f3';
      default: return '#9e9e9e';
    }
  }};
`;

const AlertHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const AlertTitle = styled.div`
  font-weight: 500;
  color: #1d1d1f;
`;

const AlertTime = styled.div`
  font-size: 12px;
  color: #86868b;
`;

const AlertMessage = styled.div`
  font-size: 14px;
  color: #666;
`;

const SystemAlerts = () => {
  const alerts = [
    {
      id: 1,
      title: '서버 CPU 사용량 경고',
      message: 'LLaMA 서버의 CPU 사용량이 75%를 초과했습니다.',
      severity: 'warning',
      time: '5분 전'
    },
    {
      id: 2,
      title: '새로운 보안 업데이트',
      message: '시스템 보안 업데이트가 있습니다. 설치를 진행해주세요.',
      severity: 'info',
      time: '30분 전'
    },
    {
      id: 3,
      title: '데이터베이스 백업 완료',
      message: '일일 데이터베이스 백업이 성공적으로 완료되었습니다.',
      severity: 'success',
      time: '1시간 전'
    }
  ];

  return (
    <AlertList>
      {alerts.map(alert => (
        <Alert key={alert.id} severity={alert.severity}>
          <AlertHeader>
            <AlertTitle>{alert.title}</AlertTitle>
            <AlertTime>{alert.time}</AlertTime>
          </AlertHeader>
          <AlertMessage>{alert.message}</AlertMessage>
        </Alert>
      ))}
    </AlertList>
  );
};

export default SystemAlerts; 