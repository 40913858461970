import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { makeApiRequest } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';

const Container = styled.div`
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const BackButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: #f8f9fa;
  color: #495057;
  cursor: pointer;
  
  &:hover {
    background: #e9ecef;
  }
`;

const PatientInfo = styled.div`
  margin-bottom: 24px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
`;

const FlowStep = styled.div`
  margin-bottom: 32px;
`;

const StepTitle = styled.h3`
  font-size: 18px;
  color: #1a1a1a;
  margin-bottom: 16px;
`;

const DepartmentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
`;

const DepartmentCard = styled.button`
  padding: 16px;
  border: 1px solid ${props => props.selected ? '#007AFF' : '#dee2e6'};
  border-radius: 8px;
  background: ${props => props.selected ? '#E3F2FD' : 'white'};
  cursor: pointer;
  text-align: left;
  transition: all 0.2s;

  &:hover {
    border-color: #007AFF;
    background: ${props => props.selected ? '#E3F2FD' : '#f8f9fa'};
  }
`;

const TreatmentList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
`;

const TreatmentCard = styled(DepartmentCard)``;

const ConfirmButton = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  background: #007AFF;
  color: white;
  font-size: 16px;
  cursor: pointer;
  
  &:hover {
    background: #0056b3;
  }
  
  &:disabled {
    background: #e9ecef;
    cursor: not-allowed;
  }
`;

// 새로운 styled components 추가
const FloorSection = styled.div`
  margin-bottom: 24px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const FloorHeader = styled.div`
  padding: 16px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  
  h3 {
    margin: 0;
    font-size: 18px;
    color: #1a1a1a;
    font-weight: 600;
  }
`;

const DepartmentSection = styled.div`
  padding: 16px;
  position: relative;
`;

// 소속 의사 관련 styled components 추가
const DoctorCount = styled.div`
  font-size: 13px;
  color: #666;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  position: relative;
  
  &:hover {
    color: #007AFF;
  }
  
  i {
    font-size: 12px;
  }
`;

const DoctorPopover = styled.div`
  position: absolute;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 16px;
  min-width: 240px;
  z-index: 1100;
  top: 100%;
  left: 0;
  margin-top: 8px;
  
  max-height: 300px;
  overflow-y: auto;
  
  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 20px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
  }
`;

const DoctorList = styled.div`
  margin-top: 12px;
`;

const DoctorItem = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
`;

const DoctorName = styled.div`
  font-weight: 500;
  color: #333;
`;

const DoctorSpecialty = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 4px;
`;

// 새로운 styled components 추가
const TreatmentSequence = styled.div`
  margin: 24px 0;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
`;

const SequenceTitle = styled.h3`
  font-size: 18px;
  color: #1a1a1a;
  margin-bottom: 16px;
`;

const SequenceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SequenceItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  position: relative;

  &:not(:last-child)::after {
    content: '↓';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    color: #007AFF;
    font-size: 16px;
  }
`;

const SequenceNumber = styled.div`
  width: 24px;
  height: 24px;
  background: #007AFF;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 14px;
`;

const SequenceInfo = styled.div`
  flex: 1;
`;

const DepartmentName = styled.div`
  font-weight: 500;
  color: #1a1a1a;
`;

const TreatmentName = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 4px;
`;

// Button styled component 추가
const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  ${props => props.primary && `
    background: #007AFF;
    color: white;
    &:hover { background: #0056b3; }
  `}

  ${props => props.secondary && `
    background: #e9ecef;
    color: #4a4a4a;
    &:hover { background: #dee2e6; }
  `}
`;

// 새로운 styled components 추가
const FlowContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const MainContent = styled.div`
  flex: 1;
  min-width: 0;
`;

const SidePanel = styled.div`
  width: 320px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: fit-content;
  position: sticky;
  top: 24px;
`;

const SequencePanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SequenceCard = styled.div`
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 16px;
  position: relative;

  &:not(:last-child)::after {
    content: '↓';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    color: #007AFF;
    font-size: 16px;
  }
`;

const SequenceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  padding: 4px;
  font-size: 18px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const EmptySequence = styled.div`
  text-align: center;
  padding: 24px;
  color: #6c757d;
  border: 2px dashed #dee2e6;
  border-radius: 8px;
`;

const DateTimeSection = styled.div`
  margin-bottom: 24px;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 24px;
`;

const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-top: 16px;
`;

const TimeButton = styled.button`
  padding: 8px;
  border: 1px solid ${props => props.selected ? '#007AFF' : '#dee2e6'};
  border-radius: 4px;
  background: ${props => props.selected ? '#007AFF' : 'white'};
  color: ${props => props.selected ? 'white' : '#333'};
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;

  &:hover {
    border-color: #007AFF;
    color: ${props => props.selected ? 'white' : '#007AFF'};
  }

  &:disabled {
    background: #f8f9fa;
    border-color: #dee2e6;
    color: #adb5bd;
    cursor: not-allowed;
  }
`;

const DateTimeInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 16px;

  &:focus {
    outline: none;
    border-color: #007AFF;
  }
`;

// DatePickerWrapper 대신 새로운 컴포넌트
const DateTimeWrapper = styled.div`
  margin-bottom: 16px;
`;

const PatientTreatmentFlow = ({ patient, onBack, onComplete }) => {
  const [loading, setLoading] = useState(true);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [existingAppointment, setExistingAppointment] = useState(null);
  const [departmentDoctors, setDepartmentDoctors] = useState({});
  const [selectedDoctorsPopover, setSelectedDoctorsPopover] = useState(null);
  const popoverRef = useRef(null);
  const [treatmentSequence, setTreatmentSequence] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  // 층수 배열 추가
  const floors = ['11', '10', '9', '8', '7', '6', '5', '4', '3', '2', '1', 'B1'];

  useEffect(() => {
    loadInitialData();
  }, [patient]);

  // 키보드와 마우스 뒤로가기 이벤트 핸들러 추가
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Backspace' || e.key === 'Escape') {
        onBack();
      }
    };

    const handleMouseBack = (e) => {
      // 마우스 뒤로가기 버튼 (일반적으로 버튼 3 또는 4)
      if (e.buttons === 8 || e.button === 3 || e.button === 4) {
        onBack();
      }
    };

    // 이벤트 리스너 등록
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mouseup', handleMouseBack);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mouseup', handleMouseBack);
    };
  }, [onBack]);

  const loadInitialData = async () => {
    try {
      setLoading(true);
      
      // 기존 예약 정보 조회
      const appointmentResponse = await makeApiRequest('/api/appointments', {
        params: {
          userId: patient.user_id,
          status: 'scheduled,confirmed'
        }
      });

      if (appointmentResponse.data.success && appointmentResponse.data.data.length > 0) {
        const latestAppointment = appointmentResponse.data.data[0];
        setExistingAppointment(latestAppointment);
        setSelectedDepartment(latestAppointment.department_id);
      }

      // 진료과 목록 조회
      const deptResponse = await makeApiRequest('/api/departments');
      if (deptResponse.data.success) {
        setDepartments(deptResponse.data.data);
      }

      // 전체 진료 항목 조회 - 엔드포인트 수정
      const treatmentsResponse = await makeApiRequest('/api/treatments');
      if (treatmentsResponse.data.success) {
        setTreatments(treatmentsResponse.data.data);
      }
    } catch (error) {
      console.error('데이터 로드 실패:', error);
      toast.error('데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 선택된 진료과의 진료 항목 필터링
  const getSelectedDepartmentTreatments = () => {
    if (!selectedDepartment) return [];
    return treatments.filter(treatment => treatment.department_id === selectedDepartment);
  };

  // 층별 진료과 필터링 함수
  const getDepartmentsByFloor = (floor) => {
    return departments.filter(dept => dept.location === floor);
  };

  // 진료과 선택 핸들러 수정
  const handleDepartmentSelect = (dept) => {
    setSelectedDepartment(dept.department_id);
    setSelectedTreatment(null);
  };

  // 진료 항목 선택 핸들러 수정
  const handleTreatmentSelect = (treatment) => {
    setSelectedTreatment(treatment.treatment_name);
    
    // 선택된 진료과와 진료 항목을 시퀀스에 추가
    const selectedDept = departments.find(d => d.department_id === selectedDepartment);
    
    setTreatmentSequence(prev => [
      ...prev,
      {
        department_id: selectedDepartment,
        department_name: selectedDept?.department_name || '',
        treatment_name: treatment.treatment_name,
        sequence_number: prev.length + 1
      }
    ]);

    // 선택 초기화
    setSelectedDepartment(null);
    setSelectedTreatment(null);
  };

  // 진료 순서 제거 핸들러
  const handleRemoveSequence = (index) => {
    setTreatmentSequence(prev => {
      const newSequence = prev.filter((_, i) => i !== index);
      // 순서 번호 재할당
      return newSequence.map((item, i) => ({
        ...item,
        sequence_number: i + 1
      }));
    });
  };

  // 시간대 생성 (08:30부터 17:30까지 15분 간격)
  const generateTimeSlots = () => {
    const slots = [];
    const start = 8 * 60 + 30; // 08:30
    const end = 17 * 60 + 30;  // 17:30
    const interval = 15;

    for (let mins = start; mins <= end; mins += interval) {
      const hours = Math.floor(mins / 60);
      const minutes = mins % 60;
      slots.push(
        `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
      );
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  // 날짜 선택 핸들러
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  // 시간 선택 핸들러
  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  // 오늘 날짜를 YYYY-MM-DD 형식으로 반환하는 함수
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // 확인 버튼 핸들러 수정
  const handleConfirm = async () => {
    if (!selectedDate || !selectedTime) {
      toast.error('날짜와 시간을 선택해주세요.');
      return;
    }
    
    try {
      setLoading(true);

      // 진료 순서 데이터 생성
      const treatmentData = {
        user_id: patient.user_id,
        treatment_sequence: treatmentSequence.map(seq => ({
          department_id: seq.department_id,
          treatment_name: seq.treatment_name,
          sequence_number: seq.sequence_number
        })),
        appointment_datetime: `${selectedDate} ${selectedTime}:00`,
        status: 'waiting'
      };

      // appointment_id가 있는 경우에만 포함
      if (existingAppointment?.appointment_id) {
        treatmentData.appointment_id = existingAppointment.appointment_id;
      }

      // API 호출하여 진료 순서 저장
      const response = await makeApiRequest('/api/treatments/sequence', {
        method: 'POST',
        data: treatmentData
      });

      if (response.data.success) {
        // 환자 상태 업데이트 - 일반 업데이트 엔드포인트 사용
        await makeApiRequest(`/api/users/${patient.user_id}`, {
          method: 'PUT',
          data: { 
            patient_status: 'outpatient'
          }
        });

        toast.success('진료 순서가 등록되었습니다.');
        onComplete();
      }
    } catch (error) {
      console.error('진료 순서 등록 실패:', error);
      toast.error(error.response?.data?.message || '진료 순서 등록에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 의사 정보 로드 함수 수정
  const loadDepartmentDoctors = async (departmentId) => {
    try {
      // API 엔드포인트 경로 수정
      const response = await makeApiRequest(`/api/departments/${departmentId}/doctors`);
      console.log('의사 목록 응답:', response); // 디버깅용 로그
      if (response.data.success) {
        setDepartmentDoctors(prev => ({
          ...prev,
          [departmentId]: response.data.data
        }));
      }
    } catch (error) {
      console.error('의사 목록 로드 실패:', error);
    }
  };

  // 팝오버 위치 계산 및 표시 함수 수정
  const handleDoctorClick = async (event, dept) => {
    event.preventDefault();
    event.stopPropagation();
    
    // 현재 선택된 부서와 같은 부서를 클릭한 경우 팝오버 닫기
    if (selectedDoctorsPopover?.departmentId === dept.department_id) {
      setSelectedDoctorsPopover(null);
      return;
    }

    try {
      // 의사 정보가 없는 경우에만 로드
      if (!departmentDoctors[dept.department_id]) {
        await loadDepartmentDoctors(dept.department_id);
      }

      setSelectedDoctorsPopover({
        departmentId: dept.department_id,
        departmentName: dept.department_name
      });
    } catch (error) {
      console.error('의사 정보 로드 실패:', error);
      toast.error('의사 정보를 불러오는데 실패했습니다.');
    }
  };

  // 팝오버 외부 클릭 감지
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setSelectedDoctorsPopover(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // DepartmentCard 내부 렌더링 수정
  const renderDepartmentCard = (dept) => (
    <DepartmentCard
      key={dept.department_id}
      selected={selectedDepartment === dept.department_id}
      onClick={() => handleDepartmentSelect(dept)}
    >
      <h4>{dept.department_name}</h4>
      <p>{dept.department_type}</p>
      <DoctorCount>
        <div onClick={(e) => handleDoctorClick(e, dept)}>
          소속 의사 {dept.doctor_count || 0}명
          {(dept.doctor_count > 0) && <i className="fas fa-chevron-down" />}
        </div>
        {selectedDoctorsPopover?.departmentId === dept.department_id && (
          <DoctorPopover ref={popoverRef}>
            <h4>{selectedDoctorsPopover.departmentName} 소속 의사</h4>
            <DoctorList>
              {departmentDoctors[dept.department_id]?.length > 0 ? (
                departmentDoctors[dept.department_id].map(doctor => (
                  <DoctorItem key={doctor.doctor_id}>
                    <DoctorName>{doctor.name}</DoctorName>
                    <DoctorSpecialty>{doctor.specialty || '전공 미지정'}</DoctorSpecialty>
                  </DoctorItem>
                ))
              ) : (
                <DoctorItem>
                  <DoctorName>등록된 의사가 없습니다.</DoctorName>
                </DoctorItem>
              )}
            </DoctorList>
          </DoctorPopover>
        )}
      </DoctorCount>
    </DepartmentCard>
  );

  if (loading) {
    return <div>로딩 중...</div>;
  }

  const selectedDepartmentTreatments = getSelectedDepartmentTreatments();

  return (
    <Container>
      <Header>
        <BackButton onClick={onBack}>← 돌아가기</BackButton>
      </Header>

      <PatientInfo>
        <h3>{patient.name}</h3>
        <p>환자번호: {patient.hospital_patient_no}</p>
        {existingAppointment && (
          <p>예약 정보: {existingAppointment.department_name} ({existingAppointment.formatted_date})</p>
        )}
      </PatientInfo>

      <FlowContainer>
        <MainContent>
          <FlowStep>
            <StepTitle>1. 진료과 선택</StepTitle>
            {floors.map(floor => {
              const floorDepartments = getDepartmentsByFloor(floor);
              if (floorDepartments.length === 0) return null;

              return (
                <FloorSection key={floor}>
                  <FloorHeader>
                    <h3>{floor === 'B1' ? '지하 1층' : `${floor}층`}</h3>
                  </FloorHeader>
                  <DepartmentSection>
                    <DepartmentGrid>
                      {floorDepartments.map(renderDepartmentCard)}
                    </DepartmentGrid>
                  </DepartmentSection>
                </FloorSection>
              );
            })}
          </FlowStep>

          {selectedDepartment && (
            <FlowStep>
              <StepTitle>2. 진료 항목 선택</StepTitle>
              <TreatmentList>
                {selectedDepartmentTreatments.map(treatment => (
                  <TreatmentCard
                    key={treatment.treatment_id}
                    selected={selectedTreatment === treatment.treatment_name}
                    onClick={() => handleTreatmentSelect(treatment)}
                  >
                    <h4>{treatment.treatment_name}</h4>
                    <p>{treatment.description}</p>
                  </TreatmentCard>
                ))}
              </TreatmentList>
            </FlowStep>
          )}
        </MainContent>

        <SidePanel>
          <DateTimeSection>
            <h3 style={{ marginBottom: '12px' }}>예약 일시</h3>
            <DateTimeWrapper>
              <label htmlFor="appointment-date">날짜 선택</label>
              <DateTimeInput
                type="date"
                id="appointment-date"
                value={selectedDate}
                onChange={handleDateChange}
                min={getTodayDate()}
              />
            </DateTimeWrapper>

            <DateTimeWrapper>
              <label htmlFor="appointment-time">시간 선택</label>
              <DateTimeInput
                type="time"
                id="appointment-time"
                value={selectedTime}
                onChange={handleTimeChange}
                min="08:30"
                max="17:30"
                step="900" // 15분 간격
              />
            </DateTimeWrapper>
          </DateTimeSection>

          <h3 style={{ marginBottom: '16px' }}>진료 순서</h3>
          {treatmentSequence.length > 0 ? (
            <SequencePanel>
              {treatmentSequence.map((seq, index) => (
                <SequenceCard key={index}>
                  <SequenceHeader>
                    <SequenceNumber>{seq.sequence_number}</SequenceNumber>
                    <RemoveButton onClick={() => handleRemoveSequence(index)}>×</RemoveButton>
                  </SequenceHeader>
                  <div>
                    <strong>{seq.department_name}</strong>
                    <div style={{ fontSize: '14px', color: '#666', marginTop: '4px' }}>
                      {seq.treatment_name}
                    </div>
                  </div>
                </SequenceCard>
              ))}
            </SequencePanel>
          ) : (
            <EmptySequence>
              진료과와 진료 항목을 선택하여<br />
              진료 순서를 구성해주세요.
            </EmptySequence>
          )}

          <ConfirmButton
            disabled={treatmentSequence.length === 0}
            onClick={handleConfirm}
            style={{ width: '100%', marginTop: '20px' }}
          >
            진료 순서 등록 완료
          </ConfirmButton>
        </SidePanel>
      </FlowContainer>
    </Container>
  );
};

export default PatientTreatmentFlow; 