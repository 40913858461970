import React, { useState } from 'react';
import styled from 'styled-components';
import { login } from '../../services/authService';

const LoginModal = ({ onClose, onLoginSuccess }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await login(formData.email, formData.password);

      if (response.success) {
        if (response.user.roleId !== 1 && response.user.roleId !== 2) {
          setError('접근 권한이 없는 계정입니다.');
          return;
        }

        onLoginSuccess(response.user);
        onClose();
      }
    } catch (err) {
      setError(err.message || '이메일 또는 비밀번호가 올바르지 않습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <h2>로그인</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Label>이메일</Label>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={isLoading}
            />
          </InputGroup>
          <InputGroup>
            <Label>비밀번호</Label>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              disabled={isLoading}
            />
          </InputGroup>
          <ButtonGroup>
            <Button type="button" onClick={onClose} disabled={isLoading}>
              취소
            </Button>
            <Button type="submit" $primary disabled={isLoading}>
              {isLoading ? '로그인 중...' : '로그인'}
            </Button>
          </ButtonGroup>
        </Form>
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding-top: 220px;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  max-width: 400px;
  width: 90%;
  position: relative;
  top: -5%;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 833px) {
    width: 85%;
    margin: 0 20px;
    padding: 1.5rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 14px;
  color: #4a4a4a;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  background: ${props => props.$primary ? '#007AFF' : '#E5E5E5'};
  color: ${props => props.$primary ? 'white' : 'black'};
  opacity: ${props => props.disabled ? 0.7 : 1};
`;

const ErrorMessage = styled.div`
  color: #ff3b30;
  font-size: 14px;
  margin-bottom: 1rem;
`;

export default LoginModal; 