import React from 'react';
import styled from 'styled-components';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title as ChartTitle, 
  Tooltip, 
  Legend,
  ArcElement,
  PointElement,
  LineElement
} from 'chart.js';
import { Bar, Pie, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

const Container = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 24px;
`;

const PageTitle = styled.h2`
  font-size: 24px;
  color: #1d1d1f;
  margin: 0 0 24px 0;
`;

const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;

const ChartCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  min-height: 300px;
`;

const ChartCardTitle = styled.h3`
  font-size: 18px;
  color: #1d1d1f;
  margin: 0 0 16px 0;
`;

const Charts = () => {
  // 월간 통계 데이터
  const monthlyData = {
    labels: ['1월', '2월', '3월', '4월', '5월', '6월'],
    datasets: [
      {
        label: '외국인 환자 수',
        data: [250, 280, 324, 290, 310, 330],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 1,
      },
    ],
  };

  // 언어별 분포 데이터
  const languageData = {
    labels: ['English', 'Chinese', 'Japanese', 'Others'],
    datasets: [
      {
        data: [45, 30, 15, 10],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // 진료과별 통계 데이터
  const departmentData = {
    labels: ['내과', '정형외과', '치과', '기타'],
    datasets: [
      {
        label: '환자 비율',
        data: [35, 25, 20, 20],
        backgroundColor: [
          'rgba(255, 159, 64, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)',
          'rgba(201, 203, 207, 0.5)',
        ],
        borderColor: [
          'rgb(255, 159, 64)',
          'rgb(75, 192, 192)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // 만족도 추이 데이터
  const satisfactionData = {
    labels: ['1월', '2월', '3월', '4월', '5월', '6월'],
    datasets: [
      {
        label: '만족도',
        data: [85, 88, 87, 90, 89, 92],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <Container>
      <PageTitle>통계 분석</PageTitle>
      <ChartGrid>
        <ChartCard>
          <ChartCardTitle>월간 외국인 환자 현황</ChartCardTitle>
          <div style={{ height: '250px' }}>
            <Bar data={monthlyData} options={options} />
          </div>
        </ChartCard>

        <ChartCard>
          <ChartCardTitle>언어별 분포</ChartCardTitle>
          <div style={{ height: '250px' }}>
            <Pie data={languageData} options={options} />
          </div>
        </ChartCard>

        <ChartCard>
          <ChartCardTitle>진료과별 통계</ChartCardTitle>
          <div style={{ height: '250px' }}>
            <Bar data={departmentData} options={options} />
          </div>
        </ChartCard>

        <ChartCard>
          <ChartCardTitle>환자 만족도 추이</ChartCardTitle>
          <div style={{ height: '250px' }}>
            <Line data={satisfactionData} options={options} />
          </div>
        </ChartCard>
      </ChartGrid>
    </Container>
  );
};

export default Charts; 