import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
const BACKUP_API_URL = process.env.REACT_APP_BACKUP_API_URL || 'http://localhost:3002/api';

const makeApiRequest = async (url, options = {}) => {
  try {
    console.log('[API Request]', options.method || 'GET', url, options);
    const response = await axios({
      url: `${API_BASE_URL}${url}`,
      ...options,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        ...options.headers,
      },
    });
    console.log('[API Response]', url, response);
    
    // 이전 코드와의 호환성을 위해 전체 response 객체 반환
    return {
      status: response.status,
      data: response.data,
      headers: response.headers
    };
  } catch (error) {
    console.error('[API Error]', url, error);
    throw error;
  }
};

const checkAuthToken = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  try {
    const response = await makeApiRequest('/auth/verify', {
      method: 'GET'
    });
    return response.data.success;
  } catch (error) {
    console.error('인증 체크 실패:', error);
    return false;
  }
};

export { makeApiRequest, checkAuthToken }; 