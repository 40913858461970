import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  background: #fff;
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 120px 0 60px;
  background: linear-gradient(180deg, #fbfbfd 0%, #fff 100%);
`;

const GradientText = styled.span`
  background: linear-gradient(90deg, #06c 0%, #2388ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const HeroTitle = styled.h1`
  font-size: 56px;
  line-height: 1.07143;
  font-weight: 600;
  letter-spacing: -.005em;
  color: #1d1d1f;
  margin-bottom: 20px;
`;

const HeroSubtitle = styled.p`
  font-size: 28px;
  line-height: 1.10722;
  font-weight: 400;
  letter-spacing: .004em;
  color: #86868b;
  margin: 0 auto;
  max-width: 600px;
`;

const FeatureSection = styled.section`
  padding: 100px 0;
  background: ${props => props.dark ? '#000' : '#fff'};
  color: ${props => props.dark ? '#f5f5f7' : '#1d1d1f'};
`;

const ContentWrapper = styled.div`
  max-width: 980px;
  margin: 0 auto;
  padding: 0 20px;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin: 60px 0;
  
  @media (max-width: 834px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background: ${props => props.$bgColor || '#fff'};
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  color: ${props => props.$accentColor};
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${props => props.$accentColor || '#06c'};
    opacity: 0.8;
  }
  
  &:hover {
    transform: translateY(-6px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
  }
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  line-height: 1.1666666667;
  font-weight: 600;
  letter-spacing: .009em;
  margin-bottom: 16px;
  color: ${props => props.color || '#1d1d1f'};
`;

const FeatureDescription = styled.p`
  font-size: 17px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -.022em;
  color: #86868b;
  margin-bottom: 20px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  
  li {
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 12px;
    padding-left: 24px;
    position: relative;
    color: ${props => props.dark ? '#f5f5f7' : '#1d1d1f'};
    
    &:before {
      content: "•";
      color: #06c;
      position: absolute;
      left: 0;
    }
  }
`;

const FeaturesPage = () => {
  const features = [
    {
      title: "실시간 의료 통역",
      description: "AI 기반 실시간 통역으로 원활한 의사소통을 지원합니다.",
      bgColor: '#f5f5f7',
      accentColor: '#06c',
      titleColor: '#1d1d1f',
      items: [
        "12개 언어 실시간 음성 통역",
        "의료 전문 용어 자동 변환",
        "문맥 기반 정확한 통역",
        "감정 분석을 통한 공감 통역",
        "응급 상황 우선 처리 시스템"
      ]
    },
    {
      title: "의료 문서 OCR",
      description: "모든 의료 문서를 즉시 인식하고 번역합니다.",
      bgColor: '#f5f5f7',
      accentColor: '#2388ff',
      titleColor: '#1d1d1f',
      items: [
        "처방전 실시간 번역",
        "의무기록 다국어 변환",
        "의료 영상 리포트 번역",
        "보험 서류 자동 처리",
        "개인정보 보호 암호화"
      ]
    },
    {
      title: "AI 진단 보조",
      description: "의료 영상 분석으로 정확한 진단을 지원합니다.",
      bgColor: '#f5f5f7',
      accentColor: '#30b0c7',
      titleColor: '#1d1d1f',
      items: [
        "X-ray 이미지 분석",
        "MRI 데이터 해석",
        "CT 스캔 3D 모델링",
        "병변 자동 감지",
        "진단 보조 리포트"
      ]
    },
    {
      title: "스마트 예약 관리",
      description: "AI가 최적의 진료 일정을 관리합니다.",
      bgColor: '#f5f5f7',
      accentColor: '#5856d6',
      titleColor: '#1d1d1f',
      items: [
        "다국어 예약 시스템",
        "우선순위 자동 배정",
        "대기 시간 실시간 알림",
        "진료과 추천 시스템",
        "모바일 체크인"
      ]
    },
    {
      title: "의료 데이터 분석",
      description: "빅데이터 분석으로 개인화된 의료 서비스를 제공합니다.",
      bgColor: '#f5f5f7',
      accentColor: '#af52de',
      titleColor: '#1d1d1f',
      items: [
        "환자 이력 패턴 분석",
        "맞춤형 치료 추천",
        "약물 상호작용 체크",
        "부작용 예측 시스템",
        "치료 결과 예측"
      ]
    },
    {
      title: "글로벌 의료 네트워크",
      description: "전 세계 의료 기관과의 협력을 지원합니다.",
      bgColor: '#f5f5f7',
      accentColor: '#147ce5',
      titleColor: '#1d1d1f',
      items: [
        "해외 의료기관 연계",
        "원격 진료 지원",
        "의료 관광 패키지",
        "국제 의료 인증",
        "글로벌 데이터 공유"
      ]
    }
  ];

  return (
    <PageContainer>
      <HeroSection>
        <ContentWrapper>
          <HeroTitle>
            <GradientText>AI 의료 서비스</GradientText>
          </HeroTitle>
          <HeroSubtitle>
            최첨단 AI 기술로 더 나은 의료 서비스를 제공합니다
          </HeroSubtitle>
        </ContentWrapper>
      </HeroSection>

      <FeatureSection>
        <ContentWrapper>
          <FeatureGrid>
            {features.map((feature, index) => (
              <FeatureCard 
                key={index}
                $bgColor={feature.bgColor}
                $accentColor={feature.accentColor}
              >
                <FeatureTitle color={feature.titleColor}>
                  {feature.title}
                </FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
                <FeatureList>
                  {feature.items.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </FeatureList>
              </FeatureCard>
            ))}
          </FeatureGrid>
        </ContentWrapper>
      </FeatureSection>
    </PageContainer>
  );
};

export default FeaturesPage; 